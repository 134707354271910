<template>
<div class="data-trend-wrap">
    <div class="title">
        <span>数据趋势</span>
        <div>
            阵地类型： 
            <el-select
                v-model="type"
                placeholder="请选择"
                size="small"
                style="width: 100px"
                @change="getList"
                >
                <el-option
                    v-for="item in zhendiOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
            </el-select>
            &nbsp;&nbsp;&nbsp;领域：
            <el-select
                v-model="area"
                placeholder="请选择"
                size="small"
                style="width: 150px"
                @change="getList"
                >
                <el-option
                    v-for="item in areaOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
            </el-select>
        </div>
    </div>
    <div class="tab-switch">
        <div class="ts-item" :class="{active: current==0}" @click="show(0)">
            <div class="text">
                <img src="../../../assets/icons/stat-fans.png" /> 粉丝数
            </div>
            <div class="number">{{total.fansNum}}</div>
        </div>
        <div class="ts-item" :class="{active: current==1}" @click="show(1)">
            <div class="text">
                <img src="../../../assets/icons/stat-view.png" /> 浏览量
            </div>
            <div class="number">{{total.viewNum}}</div>
        </div>
        <div class="ts-item" :class="{active: current==2}" @click="show(2)">
            <div class="text">
                <img src="../../../assets/icons/stat-create.png" /> 创作数
            </div>
            <div class="number">{{total.articleNum}}</div>
        </div>
        <div class="ts-item" :class="{active: current==3}" @click="show(3)">
            <div class="text">
                <img src="../../../assets/icons/stat-zan.png" /> 获赞数
            </div>
            <div class="number">{{total.likeNum}}</div>
        </div>
        <div class="ts-item" :class="{active: current==4}" @click="show(4)">
            <div class="text">
                <img src="../../../assets/icons/stat-comment.png" /> 评论数
            </div>
            <div class="number">{{total.commentNum}}</div>
        </div>
    </div>
    <div class="center-part">
        <div class="center-title">{{titleMap[current]}}</div>
        <div class="select-wrap">
            <div class="center-switch">
                <div class="switch-item" :class="{'active': dimension===0}" @click="dimensionChange(0)">今日</div>
                <div class="switch-item" :class="{'active': dimension===1}" @click="dimensionChange(1)">本周</div>
                <div class="switch-item" :class="{'active': dimension===2}" @click="dimensionChange(2)">本月</div>
                <div class="switch-item" :class="{'active': dimension===3}" @click="dimensionChange(3)">全年</div>
            </div>
            <el-date-picker
                v-model="timeArr"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :disabled-date="disabledDate"
                size="large"
                format="YYYY/MM/DD"
                value-format="YYYY-MM-DD"
                style="width: 240px;margin-left: 8px;"
                @change="timeSelectChange"
            />
            <!-- <el-date-picker
                v-model="month"
                type="month"
                placeholder="请选择月份"
                size="large"
                format="YYYY-MM"
                value-format="YYYY-MM"
                style="width: 140px;margin-left: 8px;"
                @change="monthSelectChange"
            /> -->
        </div>
    </div>
    <div class="chart-wrap">
        <LineDiagram :seriesData="lineData" :xAxisData="lineXAxisData"></LineDiagram>
    </div>
</div>
</template>

<script scope setup>
import { ref, reactive, watch } from 'vue'
import { POSITIONTYPES } from '../.././warningReview/options.js'
import LineDiagram from '@/components/charts/LineDiagram.vue'
import { areaList, trend } from '@/api/home.js'

const props = defineProps({
    reload: {
        type: Boolean
    }
})

const zhendiOptions = [{
    label: '全部',
    value: ''
},...POSITIONTYPES]
const type = ref('')
const area = ref('')
const dimension = ref(0)
//领域数据
const areaOptions = ref([])
const titleMap = {
    '0': '粉丝数',
    '1':'浏览量',
    '2':'创作数',
    '3':'获赞数',
    '4':'评论数'
}
const current = ref(0)
const timeArr = ref([])
// 暂时不用
const month = ref('')

const lineData = ref([])
const lineXAxisData = ref([])
const total = reactive({
    articleNum: 0,
    commentNum: 0,
    fansNum: 0,
    likeNum: 0,
    viewNum: 0,
    data: []
})

const arr_Day = ['周日','周一','周二','周三','周四','周五','周六']

function dateTrans(str, type){
    if(!str) {
        return ''
    }
    const d = new Date(str)
    return `${d.getMonth()+1}-${d.getDate()} ${type=='weekday'?arr_Day[d.getDay()]:''}`
}
const disabledDate = (time)=>{
        let twoYearsAgo = new Date();
        twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2)
        return time.getTime()<twoYearsAgo || time.getTime()>Date.now()
}

console.log('lineData:', lineData.value)
const getAreaList = ()=>{
    areaList().then(res=>{
        areaOptions.value = [{value: '', label: '全部'}]
        for(let k in res.data) {
            areaOptions.value.push({value: k, label: res.data[k]})
        }
        areaOptions.value.push({value: -1, label: '未关联'})
    })
}
getAreaList()
const getList = ()=>{
    console.log('===========:', timeArr.value[0], timeArr.value[1])
    let params = {}
    if(dimension.value !== '') {
        params.dimension = dimension.value
    } else {
        params.startTime= timeArr.value[0],
        params.endTime= timeArr.value[1]
        // params.month = month.value
    }
    if(type.value!==''){
        params.type = type.value
    }
    if(area.value!==''){
        params.fieldId = area.value
    }
    lineData.value = []
    lineXAxisData.value = []
    trend(params)
        .then(res=>{
            const {articleNum, commentNum, fansNum, likeNum,viewNum, recordDataTrendVos} = res.data
            
            const last = recordDataTrendVos.length?recordDataTrendVos[recordDataTrendVos.length-1]:{}
            total.articleNum = last.articleNum
            total.commentNum = last.commentNum
            total.fansNum = last.fansNum
            total.likeNum = last.likeNum
            total.viewNum = last.viewNum

            //自定义时间处理
            // 30天内，以天为维度，6-19
            // 30天-1年，按周为维度 23周，24周
            // 1年纪以上，以月为维度
            if(dimension.value === '') {
                const timeDif = new Date(timeArr.value[1]).getTime() - new Date(timeArr.value[0]).getTime()

                recordDataTrendVos.forEach(item=>{
                    if(timeDif <= 30*24*3600000) {
                        item.countTime = dateTrans(item.countTime)
                    }else if(timeDif <= 365*24*3600000) {
                        item.countTime = item.countTime + '周'
                    }
                })        
            }



            total.data = recordDataTrendVos
            dataAbstract()
        }).catch(err=>{
            console.log('获取数据趋势数据报错：', err)
        })
}

getList()

const timeSelectChange = ()=>{
    if(!timeArr.value ||timeArr.value.length == 0) {
        return
    }
    dimension.value = ''
    getList()
}

const monthSelectChange = () => {
    console.log('month:', month.value)
    if(!month.value) {
        return
    }
    dimension.value = ''
    getList()
}

const show = type => {
    current.value = type
    // 曲线数据
    dataAbstract()
}

const dimensionChange = type =>{
    timeArr.value = []
    // month.value = ''
    dimension.value = type
    getList()
}



const dataAbstract = ()=>{
    let arr = []
    switch (current.value) {
        case 0:
            arr = total.data.map(item=>({value:item.fansNum, label: item.countTime }))
            break;
        case 1:
            arr = total.data.map(item=>({value:item.viewNum, label: item.countTime }))
            break;
        case 2:
            arr = total.data.map(item=>({value:item.articleNum, label: item.countTime }))
            break;
        case 3:
            arr = total.data.map(item=>({value:item.likeNum, label: item.countTime }))
            break;
        case 4:
            arr = total.data.map(item=>({value:item.commentNum, label: item.countTime }))
            break;
        default:
            break;
    }
    lineData.value = [{
        data: arr,
        tooltipData: total.data.map(item=>item.countTime),
        type: 'line',
        smooth: true
    }]
    lineXAxisData.value = total.data.map(item=>{
        if(dimension.value === 0) {
            return item.countTime + ':00'
        }else if(dimension.value == 1 || dimension.value == 2) {
            return dateTrans(item.countTime, 'weekday')
        }else if(dimension.value == 3) {
            return item.countTime.slice(0,7)
        }else {
            return item.countTime
        }
    })
}

watch(()=>props.reload, val=>{
    if(val) {
        getList()
    }
})

</script>

<style lang="less" scoped>
.data-trend-wrap{
    margin-top: 16px;
    width: 1248px;
    height: 617px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    .title{
        padding: 0 16px;
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #262626;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        border-bottom: 1px solid #EBEBEB;
    }
    .tab-switch{
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .ts-item{
        padding: 16px;
        width: 230px;
        height: 90px;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid rgba(0,0,0,0.08);
        cursor: pointer;
        img{
            width: 24px;
            height: 24px;
            vertical-align: middle;
        }
        .text{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #9A9A9A;
        }
        .number{
            padding-left: 28px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 24px;
            color: #262626;
        }
    }
    .ts-item.active{
        border-bottom: 4px solid #0073E5;
    }
    .center-part{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        height: 56px;
        box-sizing: border-box;
        .center-title{
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 16px;
            color: rgba(0,0,0,0.85);
        }
        .center-switch{
            padding: 3px;
            background: #F5F5F5;
            border-radius: 2px 2px 2px 2px;
            display: flex;
            flex-wrap: nowrap;
        }
        .switch-item{
            padding: 0 12px;
            line-height: 32px;
            cursor: pointer;
        }
        .switch-item.active{
            height: 32px;
            font-size: 14px;
            color: #0073E5;
            background: #fff;
        }
        .select-wrap{
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
    .chart-wrap{
        width: 100%;
        height: 360px;
    }
}
</style>