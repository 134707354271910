// 管理主体枚举
export function management (key) {
  switch (key) {
    case 2:
      return "战略运营"
    case 3:
      return "战略发展"
    case 4:
      return "品牌增值"
    case 5:
      return "薪酬委"
    case 6:
      return "审计与风控委"
    case 7:
      return "监察委"
    case 8:
      return "商业法律"
    case 9:
      return "党群"
    case 10:
      return "财资生态圈"
    case 11:
      return "集团IT平台"
    case 12:
      return "总裁办"
    case 13:
      return "商务"
  }
}

export function department (key) { 
    switch (key) {
    case 1:
      return "海尔集团党委宣传部"
    case 2:
      return "集团平台党委宣传部"
    case 3:
      return "智家定制党委宣传部"
    case 4:
      return "万链共享党委宣传部"
    case 5:
      return "新事业生态圈党委宣传部"
    case 6:
      return "日日顺供应链党委宣传部"
    case 7:
      return "君一控股党委宣传部"
    case 8:
      return "智慧家居党委宣传部"
    case 9:
      return "海尔园区服务及新能源科技平台党委宣传部"
    case 10:
      return "文化产业党支部"
    case 11:
      return "产业发展党支部"
  }
}

export function GetDateTime() {
 
        var getDate = new Date;
 
        let dateYear = getDate.getFullYear();             //获取年 
 
        let dateMonth = getDate.getMonth() + 1;               //获取月  
 
        let dateDate = getDate.getDate();                 //获取当日
 
        let dateHours = getDate.getHours();               //获取小时
 
        let dateMinutes = getDate.getMinutes();           //获取分钟
 
        let dateSeconds = getDate.getSeconds();           //获取秒
 
        dateMonth = dateMonth < 10 ? "0" + dateMonth : dateMonth;
 
        dateDate = dateDate < 10 ? "0" + dateDate : dateDate;
 
        dateHours = dateHours < 10 ? "0" + dateHours : dateHours;
 
        dateMinutes = dateMinutes < 10 ? "0" + dateMinutes : dateMinutes;
 
        dateSeconds = dateSeconds < 10 ? "0" + dateSeconds : dateSeconds;
 
        return dateYear + "-" + dateMonth + "-" + dateDate + " " + dateHours + ":" + dateMinutes + ":" + dateSeconds;
 
    }