import enIcon from '@/assets/img/en.png'
import zhIcon from '@/assets/img/zh.png'
const optionsList = [
  {
    "value": 1,
    "text": "集团平台",
    "children": [
      { "value": 10001, "text": "战略运营" },
      { "value": 10002, "text": "战略发展" },
      { "value": 10003, "text": "品牌增值" },
      { "value": 10004, "text": "薪酬委" },
      { "value": 10005, "text": "审计与风控委" },
      { "value": 10006, "text": "监察委" },
      { "value": 10007, "text": "商业法律" },
      { "value": 10008, "text": "党群" },
      { "value": 10009, "text": "财资生态圈" },
      { "value": 10020, "text": "集团IT平台" },
      { "value": 10021, "text": "总裁办" },
      { "value": 10022, "text": "商务" }
    ]
  },
  {
    "value": 2,
    "text": "智家",
    "children": [
      { "value": 20001, "text": "食联网-制冷" },
      { "value": 20002, "text": "衣联网" },
      { "value": 20003, "text": "空气网" },
      { "value": 20004, "text": "水联网" },
      { "value": 20005, "text": "食联网-厨电" },
      { "value": 20006, "text": "生活家电" },
      { "value": 20007, "text": "全屋智能" },
      { "value": 20008, "text": "视听网" },
      { "value": 20009, "text": "雷神" },
      { "value": 20010, "text": "适老产业" },
      { "value": 20011, "text": "国内" },
      { "value": 20012, "text": "海外" },
      { "value": 20013, "text": "超前研发" },
      { "value": 20014, "text": "创新设计" },
      { "value": 20015, "text": "智能制造" },
      { "value": 20016, "text": "模块商" },
      { "value": 20017, "text": "质量" },
      { "value": 20018, "text": "再循环产业" },
      { "value": 20019, "text": "口碑" }
    ]
  },
  {
    "value": 3,
    "text": "新事业生态圈",
    "children": [
      { "value": 30001, "text": "卡奥斯" },
      { "value": 30002, "text": "海创汇" }
    ]
  },
  {
    "value": 4,
    "text": "万链共享",
    "children": [
      { "value": 40001, "text": "海尔生物医疗" },
      { "value": 40002,"text":"盈康医疗"},
      { "value": 40003, "text": "玛西普" },
      { "value": 40004, "text": "海尔国际细胞库" },
      { "value": 40005, "text": "万链金融" },
      { "value": 40006, "text": "万链数科" },
      { "value": 40007, "text": "海尔资本" },
      { "value": 40008, "text": "海创母基金" }
    ]
  },
  {
    "value": 5,
    "text": "日日顺供应链",
    "children": [
      { "value": 50001, "text": "战略" },
      { "value": 50002,"text":"内控"},
      { "value": 50003, "text": "三自" }
    ]
  },
  {
    "value": 6,
    "text": "产城融合",
    "children": [
      { "value": 60001, "text": "君一控股" },
      { "value": 60002,"text":"海纳云"}
    ]
  },
  {
    "value": 7,
    "text": "智慧家居",
    "children": [
      { "value": 70001, "text": "企业业务平台" },
      { "value": 70002,"text":"消费者业务平台"},
      { "value": 70003, "text": "产业生态平台" },
      { "value": 70004, "text": "家具数字定制平台" }
    ]
  },
  {
    "value": 8,
    "text": "园区服务和新能源科技",
    "children": [
      { "value": 80001, "text": "安保" },
      { "value": 80002,"text":"行政服务"},
      { "value": 80003, "text": "光伏" },
      { "value": 80004, "text": "商旅" }
    ]
  },
  {
    "value": 9,
    "text": "文化产业",
    "children": [
      { "value": 90001, "text": "集团党委宣传部" },
      { "value": 90002,"text":"海尔模式研究院"},
      { "value": 90003, "text": "海尔文化" }
    ]
  },
  {
    "value": 10,
    "text": "产业物联",
    "children": [
      { "value": 100001, "text": "区域平台" },
      { "value": 100002,"text":"工程平台"},
      { "value": 100003, "text": "技术平台" },
      { "value": 100004, "text": "巡检平台" },
      { "value": 100005, "text": "成本平台" },
      { "value": 100006, "text": "运营平台" },
      { "value": 100007, "text": "三自平台" }
    ]
  }
]

const languages = [
  { 
    value: 0, 
    label: "中文", 
    simple: 'zh' , 
    disabled: true, 
    nameMaxlength: 20, 
    descMaxlength: 100,
    placeholder: '请输入', 
    descPlaceholder: '请输入中文简介',
    icon: zhIcon 
  },
  { 
    value: 1, 
    label: "英文" , 
    simple: 'en', 
    nameMaxlength: 40, 
    descMaxlength: 200,
    placeholder: 'Enter',
    descPlaceholder: 'Enter English introduction',
    icon: enIcon
  },
]
export {
  languages,
  optionsList
}