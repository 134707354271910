import {ElTable,ElTabs,ElPagination,ElButton,ElTimeline,ElDialog,ElUpload,ElTooltip} from 'element-plus'
import 'element-plus/dist/index.css'
const element = {
  install: function (Vue) {
    Vue.use(ElTable)
    Vue.use(ElTabs)
    Vue.use(ElPagination)
    Vue.use(ElButton)
    Vue.use(ElTimeline)
    Vue.use(ElDialog)
      Vue.use(ElUpload)
      Vue.use(ElTooltip)
  }
}

export default element