<template>
  <div class="main">
    <div class="mybeian">
      <div class="title">
        <!-- <img class="fanhuiimg" @click="fanhui" src="../../assets/icons/pcindex/b3b4.png" /> -->
        <span class="span-t1">管理员配置</span>
      </div>
      <div class="nrlist">
        <el-form :model="form" @submit.native.prevent>
          <el-row>
            <el-form-item label="">
              <el-input class="span-search" v-model="form.name" placeholder="搜索管理员名称" clearable
                @keyup.enter.native="enterClick" @clear="getList()" />
            </el-form-item>
            <el-button type="primary" @click="addUser" class="add_btn add_user"><el-icon>
                <Plus />
              </el-icon> 新增管理员
            </el-button>
          </el-row>
        </el-form>
        <el-table :data="records" border max-height="530" v-loading="loading">
          <el-table-column prop="" label="管理员" width="200">
            <template v-slot:default="scope">
              <el-avatar :size="30" :src="scope.row.users[0].headImg" />
              <span class="users">{{ scope.row.users[0].name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="管理员权限" :show-overflow-tooltip="true">
            <template v-slot:default="scope">
              <span v-if="scope.row.deletePermission == 1" class="power">删除期刊</span>
              <span v-if="scope.row.recommendPermission == 1" class="power">期刊推荐 </span>
              <span v-if="scope.row.approvalPermission == 1" class="power">内刊审核 </span>
              <span v-if="scope.row.modifyPermission === 1" class="power">内刊库管理</span>
              <span v-if="scope.row.modifyPermission == 0 && scope.row.viewXuehaiPermission == 1"
              class="power">学海可见</span>
              <span v-if="scope.row.datacenterPermission == 1" class="power">数据中心 </span>
              <span v-if="scope.row.alertPermission === 1" class="power">预警审核</span>
              <span v-if="scope.row.alertNotifyPermission === 1" class="power">审核通知</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template v-slot:default="scope">
              <span class="button" type="primary" text @click="edit(scope.row)">编辑</span>
              <span class="button" type="primary" @click="del(scope.row)" text>删除</span>
            </template>
          </el-table-column>
          <template #empty>
            <div class="flex items-center justify-center h-100%">
              <el-empty :image="emptyPng" description="未搜索到管理员～" />
            </div>
          </template>
        </el-table>
      </div>
      <div class="page-wrapper">
        <el-pagination :current-page="pagination.current" :page-size="pagination.size"
          layout="total, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange"
          :background="isBackground" />
      </div>

      <el-dialog class="dialog" v-model="dialogVisible" width="480px" @close="cancel" :title="dialogTitle">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" label-position="right">
          <el-form-item label="管理人" prop="users" class="form-item">
            <div class="selecper">
              <SelectPer :list="ruleForm.users" :type="type" @addPer="selectAdd" @del-event="selectDelete"
                :maxCount="20"> </SelectPer>
            </div>
          </el-form-item>
          <el-form-item label="配置权限" prop="checked">
            <el-checkbox-group v-model="ruleForm.checked" @change="handleCheckedChange">
              <el-checkbox label="modifyPermission" @change="handleCheckAllChange"> 内刊库管理所有期刊</el-checkbox>
              <el-checkbox-group v-model="ruleForm.visible">
                <!-- <el-checkbox label="viewLibraryPermission" :disabled="disabled" class="ml20"> 期刊库可见所有期刊</el-checkbox> -->
                <el-checkbox label="viewXuehaiPermission" :disabled="disabled" class="ml20">学海可见所有期刊 </el-checkbox>
              </el-checkbox-group>
              <el-checkbox label="deletePermission">删除期刊</el-checkbox>
              <el-checkbox label="recommendPermission">期刊文章推荐到首页</el-checkbox>
              <el-checkbox label="approvalPermission">内刊审核</el-checkbox>
              <el-checkbox label="datacenterPermission">数据中心</el-checkbox>
              <el-checkbox label="alertPermission">预警审核</el-checkbox>
              <el-checkbox label="alertNotifyPermission">审核通知</el-checkbox>        
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancel" class="cancel_btn">取消</el-button>
            <el-button type="primary" @click="cinfrim" class="add_btn"> 确认 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { list, addAdmin, editAdmin, deleteAdmin } from "@/api/administratorConfiguration";
import emptyPng from "@/assets/img/empty_user2x.png";
import SelectPer from "@/components/SelectPer.vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { mergeArray } from "@/utils/Ihaier";
import { isIHaier } from "@/utils/platform";
export default {
  components: {
    SelectPer,
  },
  data() {
    const validateManager = (rule, value, callback) => {
      if (this.ruleForm.users.length < 1) {
        callback(new Error("必填项未选择"));
      } else {
        callback();
      }
      callback();
    };
    const validateChecked = (rule, value, callback) => {
      if (this.ruleForm.visible.length > 0 || this.ruleForm.checked.length > 0) {
        callback();
      } else {
        callback(new Error("必填项未选择"));
      }
      callback();
    };
    return {
      form: {
        name: "",
      },
      records: [],
      pagination: {
        current: 1,
        size: 10,
      },
      total: 0,
      isBackground: true,
      loading: false,
      emptyPng,
      dialogVisible: false,
      ruleForm: {
        users: [],
        checked: [],
        visible: [],
      },
      rules: {
        users: [
          { required: true, message: "必填项未选择" },
          { validator: validateManager, trigger: "blur" },
        ],
        checked: { required: true, validator: validateChecked, trigger: "change" },
      },
      disabled: false,
      dialogTitle: "新增管理员",
      type: 1,
      id: null,
    };
  },
  created() {
    this.getList();
  },
  watch: {
    "ruleForm.users": {
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.$nextTick(() => {
            this.$refs.ruleForm.clearValidate("users");
          });
        }
      },
      immediate: true,
      deep: true, // 可以深度检测到 person 对象的属性值的变化
    },
    "ruleForm.visible": {
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.$refs.ruleForm.clearValidate("checked");
        }
      },
    },
    "ruleForm.checked": {
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.$refs.ruleForm.clearValidate("checked");
          this.disabled = newVal.includes("modifyPermission") == -1 ? true : false;
        } else {
          this.disabled = false;
        }
      },
    },
  },
  methods: {
    dayjs,
    fanhui() {
      this.$router.go(-1);
    },
    getList() {
      this.loading = true;
      let params = {
        ...this.pagination,
        ...this.form,
      };
      list(params).then((res) => {
        this.records = res.data.records;
        this.total = res.data.total;
        this.loading = false;
      });
    },
    handleCurrentChange(val) {
      this.pagination.current = val;
      this.getList();
    },
    enterClick() {
      this.pagination.current = 1;
      this.getList();
    },
    addUser() {
      this.dialogTitle = "新增管理员";
      this.type = 1;
      this.ruleForm.users = [];
      this.dialogVisible = true;
    },
    selectDelete(data) {
      this.ruleForm.users = data;
    },
    selectAdd(data) {
      this.ruleForm.users = data;
      if (isIHaier) {
        const newList = data.map((item) => {
          return {
            headImg: item.avatarUrls[0],
            id: '',
            name: item.name,
            openId: item.openId,
            username: item.name
          };
        });
        this.ruleForm.users = newList
      } else {
        this.ruleForm.manager = data;
      }
    },
    handleCheckAllChange(val) {
      this.ruleForm.visible = val ? ["viewLibraryPermission", "viewXuehaiPermission"] : [];
    },
    handleCheckedChange(val) {
      this.ruleForm.checked = val;
      this.disabled = this.ruleForm.checked.includes("modifyPermission");
    },
    edit(row) {
      this.id = row.id;
      this.dialogTitle = "编辑管理员";
      this.type = 2;
      this.disabled = row.modifyPermission == 1 ? true : false;
      this.dialogVisible = true;
      this.ruleForm.users = row.users;
      row.recommendPermission == 1 ? this.ruleForm.checked.push("recommendPermission") : this.ruleForm.checked;
      row.deletePermission == 1 ? this.ruleForm.checked.push("deletePermission") : this.ruleForm.checked;
      row.modifyPermission == 1 ? this.ruleForm.checked.push("modifyPermission") : this.ruleForm.checked;
      row.viewLibraryPermission == 1 ? this.ruleForm.visible.push("viewLibraryPermission") : this.ruleForm.visible;
      row.viewXuehaiPermission == 1 ? this.ruleForm.visible.push("viewXuehaiPermission") : this.ruleForm.visible;
      row.approvalPermission == 1 ? this.ruleForm.checked.push("approvalPermission") : this.ruleForm.checked;
      row.datacenterPermission == 1 ? this.ruleForm.checked.push("datacenterPermission") : this.ruleForm.checked
      row.alertPermission == 1 ? this.ruleForm.checked.push("alertPermission") : this.ruleForm.checked
      row.alertNotifyPermission == 1 ? this.ruleForm.checked.push("alertNotifyPermission") : this.ruleForm.checked
    },
    del(row) {
      ElMessageBox.confirm("是否确认删除该用户管理员身份", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteAdmin({ id: row.id }).then(() => {
            ElMessage.success("删除成功");
            this.getList();
          });
        })
        .catch(() => {
          console.log("已取消删除");
        });
    },
    cinfrim() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let arr = this.ruleForm.checked;
          let list = this.ruleForm.visible;
          let data = {
            deletePermission: arr.includes("deletePermission") ? 1 : 0,
            recommendPermission: arr.includes("recommendPermission") ? 1 : 0,
            modifyPermission: arr.includes("modifyPermission") ? 1 : 0,
            viewLibraryPermission: list.includes("viewLibraryPermission") ? 1 : 0,
            viewXuehaiPermission: list.includes("viewXuehaiPermission") ? 1 : 0,
            approvalPermission: arr.includes("approvalPermission") ? 1 : 0,
            alertPermission: arr.includes('alertPermission') ? 1 : 0,
            alertNotifyPermission: arr.includes('alertNotifyPermission') ? 1: 0,
            datacenterPermission: arr.includes("datacenterPermission") ? 1 : 0,
            users: this.ruleForm.users,
            id: this.dialogTitle == "新增管理员" ? null : this.id,
          };
          if (this.dialogTitle == "新增管理员") {
            addAdmin(data).then(() => {
              this.dialogVisible = false;
              this.reset();
              this.getList();
            });
          } else if (this.dialogTitle == "编辑管理员") {
            editAdmin(data).then(() => {
              this.dialogVisible = false;
              this.reset();
              this.getList();
            });
          }
        }
      });
    },
    cancel() {
      this.reset();
      this.dialogVisible = false;
    },
    reset() {
      this.ruleForm.user = [];
      this.$refs.ruleForm.resetFields();
      this.ruleForm.visible = [];
      this.ruleForm.checked = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  margin: 0 auto;
  overflow: hidden;
}

.mybeian {
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  // margin-top: 16px;
  padding-top: 24px;
  margin-bottom: 23px;

  .title {
    height: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .fanhuiimg {
      height: 16px;
      width: 16px;
      margin-left: 16px;
      cursor: pointer;
    }

    .span-t1 {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2f3238;
      line-height: 22px;
      margin-left: 48px;
    }
  }

  .nrlist {
    width: 1162px;
    margin-left: 46px;

    .el-row {
      display: flex;
      justify-content: space-between;
    }

    .el-avatar {
      vertical-align: middle;
    }

    .users {
      line-height: 30px;
      margin-left: 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.9);
    }

    .power {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.9);
      margin-right: 15px;
    }
  }

  .add_btn {
    background: #286cfb;
  }

  .cancel_btn {
    color: rgba(0, 0, 0, 0.4);
  }

  .add_user {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;

    .el-icon {
      margin-right: 5px;
    }
  }

  .add_btn:hover {
    opacity: 0.9;
  }

  .selecper {
    margin-top: 6px;
  }

  .page-wrapper {
    height: 80px;
    background: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 38px;
  }

  .el-checkbox {
    width: 100%;
  }

  .ml20 {
    margin-left: 20px;
  }

  .button {
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #286cfb;
    margin-right: 10px;
  }

  .button:hover {
    color: #286cfb;
  }

  ::v-deep .el-form-item__label {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
  }

  ::v-deep .el-checkbox__label {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
  }

  .form-item {
    margin-bottom: 16px
  }
}
</style>
<style>
.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #606266 !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #286cfb !important;
  border-color: #286cfb !important;
}

.dialog .el-dialog__body {
  padding: 0 20px !important;
}

.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #fff !important;
}

.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #93b5fd !important;
  border-color: #93b5fd !important;
}
</style>
