import store from "@/store";
import {
  getTokenByIhaier,
  getFeishuSignPackage,
  getXuehaiFeishuToken,
  getXuehaiFeishuSignPackage,
} from "../api/ihaier";
import axios from "axios";

const configUserCenterProd = {
  appId: "cli_a3c7b57abb62d00e",
  clientId: "K4686d7d7777a5e10",
  ssoUrl: "https://iama.haier.net", // 账号中心统一登录页
  tokenUrl: "https://xue.hilook.com.cn/h5/v1/login/ihaier", // 集成了账号中心提供的后端服务的地址
};

const configUserCenterDev = {
  appId: "cli_a3c4870b3039500e",
  clientId: "K9507ed050a3284aa",
  ssoUrl: "https://iam-test.haier.net", // 账号中心统一登录页
  // tokenUrl: '/live-web/auth/token' // 集成了账号中心提供的后端服务的地址
  tokenUrl: "https://haixueh5-test.lanbenzi.cn:8443/h5/v1/login/ihaier", // 集成了账号中心提供的后端服务的地址
  //tokenUrl:'/h5/v1/login/ihaier'
};

const configUserCenterProdApp = {
  clientId: "K9ec9074ef1aca14f",
  ssoUrl: "https://iama.haier.net",
  appId: "cli_a3644ece09fcd013",
  tokenUrl: "/api/iHaier/getTokenAndUserInfoBackend", // 集成了账号中心提供的后端服务的地址
};

const configUserCenterDevApp = {
  clientId: "Kaf70070628203af6",
  ssoUrl: "https://iam-test.haier.net",
  appId: "cli_a3632823605c5013",
  tokenUrl: "/api/iHaier/getTokenAndUserInfoBackend", // 集成了账号中心提供的后端服务的地址
  //tokenUrl:'/h5/v1/login/ihaier'
};

function getUrlParam(param, urlsearch) {
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + param + "=" + "([^&;]+?)(&|#|;|$)").exec(
        urlsearch || location.search
      ) || [, ""])[1].replace(/\+/g, "%20")
    ) || null
  );
}
const DcmObj = {
  type: 0,
};

function initDcm() {
  let types = 0;
  let from = getUrlParam("from", window.location.href);
  if (from === "app") {
    types = 1;
    sessionStorage.setItem("from", "app");
  } else {
    let from = sessionStorage.getItem("from");
    if (from == null || from != "app") {
      types = 0;
    } else {
      types = 1;
    }
  }
  DcmObj.type = types;
}

const configOptions = (types) => {
  console.log(window, "window");
  const origin = window.location.origin.includes("test"); // 目前higroup-ihaier.hilook.com.cn为正式环境
  // if (!origin) {
  //     return {
  //         // 正式环境
  //         clientId: 'K9ec9074ef1aca14f',
  //         ssoUrl: 'https://iama.haier.net',
  //         appId: 'cli_a3644ece09fcd013',
  //         tokenUrl: '/api/iHaier/getTokenAndUserInfoBackend'
  //     };
  // }
  // return {
  //     // 测试环境
  //     clientId: 'Kaf70070628203af6',
  //     ssoUrl: 'https://iam-test.haier.net',
  //     appId: 'cli_a3632823605c5013',
  //     tokenUrl: '/api/iHaier/getTokenAndUserInfoBackend'
  //     // "tokenUrl": "https://group.hilook.com.cn/api/user/ihaier/login"
  // };

  if (types == 0) {
    if (!origin) {
      return configUserCenterProd;
    }
    return configUserCenterDev;
  } else {
    if (!origin) {
      return configUserCenterProdApp;
    }
    return configUserCenterDevApp;
  }
};
export function getToken() {
  return new Promise((resolve, reject) => {
    // if (platform.isIHaier()) {
    init();
    iHaierLogin().then((res) => {
      console.log("token没过期重新请求", res);
      resolve(true);
    });
  });
}

const init = () => {
  let types = 0;
  let from = getUrlParam("from", window.location.href);
  if (from === "app") {
    types = 1;
    sessionStorage.setItem("from", "app");
  } else {
    let from = sessionStorage.getItem("from");
    if (from == null || from != "app") {
      types = 0;
    } else {
      types = 1;
    }
  }
  DcmObj.type = types;

  const config = configOptions(types);
  console.log("DCM", config);
  console.log("window", window);
  console.log("window.location.origin", window.location.href);

  console.log("Ihaier调用参数", config, window.h5sdk, window.tt);
  console.log("__USERCENTER__1", window.__USERCENTER__);
  window.__USERCENTER__.configUserCenter({
    ...config,
    onTokenChange: (token) => {
      // console.log("token进来了");
      // if (token) {
      //   console.log("token", token);
      // }
    },
    onUserInfoChange: (userInfo) => {
      // console.log("userInfo进来了");
      if (userInfo) {
        // console.log("userInfo", userInfo);
        window.localStorage.setItem(
          "userInfoForChoose",
          JSON.stringify(userInfo)
        );
      }
    },
  });
};

//     // iHaier web登录
export function iHaierLogin() {
  // console.log("iHaierLogin222");
  return new Promise((resolve, reject) => {
    console.log(window.__USERCENTER__.login(), "iHaierLogin222");
    window.__USERCENTER__
      .login()
      .then(async (res) => {
        console.log("__USERCENTER__", res);
        // #ifdef H5
        initDcm();
        if (res.success) {
          if (DcmObj.type == 1) {
            localStorage.setItem("token", res.token);
            sessionStorage.setItem("token", res.token);

            store.commit("setlogin", true);
            getFeishuSignPackages({
              url: encodeURIComponent(window.location.href.split("#")[0]),
            }).then((res) => {
              console.log(res, "我是鉴权成功");
            });
            resolve(res);
          } else {
            const ress = await getXuehaiFeishuToken({
              xuehaiToken: res.token,
            });
            store.commit("setlogin", true);
            console.log("dddddddddddsss", ress.data.token);
            localStorage.setItem("token", ress.data.token);
            sessionStorage.setItem("token", ress.data.token);
            // console.log("dddddddddddeeee", sessionStorage.getItem("token"));
            resolve(ress);
            getFeishuSignPackages({
              url: encodeURIComponent(window.location.href.split("#")[0]),
            }).then((res) => {
              console.log(res, "我是鉴权成功");
            });
          }
        } else {
          resolve(res);
        }
        // #endif
      })
      .catch((err) => {
        console.log(err, "err");

        reject(err);
      });
  });
}

//     // 打开新页面
export function dcmOpenNewContainer(options) {
  // // iHaier中打开
  console.log("从iHaier中打开新页面", options);
  let links = encodeURIComponent(options.url);
  const origin = window.location.origin.includes("test"); // 目前higroup-ihaier.hilook.com.cn为正式环境
  if (origin) {
    if (
      options.url.indexOf("xuehai-pc") != -1 ||
      options.url.indexOf("xuechuang") != -1
    ) {
      window.open(
        `https://applink.feishu.cn/client/web_app/open?appId=cli_a35213b261b89013&lk_target_url=${links}`
      );
      console.log(
        "this is ",
        `https://applink.feishu.cn/client/web_app/open?appId=cli_a35213b261b89013&lk_target_url=${links}`
      );
    } else {
      console.log(
        `this is:https://applink.feishu.cn/client/web_app/open?appId=cli_a3c4870b3039500e&lk_target_url=${links}`
      );
      window.open(
        `https://applink.feishu.cn/client/web_app/open?appId=cli_a3c4870b3039500e&lk_target_url=${links}`
      );
    }
  } else {
    if (
      options.url.indexOf("xuehai-pc") != -1 ||
      options.url.indexOf("xuechuang") != -1
    ) {
      window.open(
        `https://applink.feishu.cn/client/web_app/open?appId=cli_a36f65c23db9900b&lk_target_url=${links}`
      );
    } else {
      window.open(
        `https://applink.feishu.cn/client/web_app/open?appId=cli_a3c7b57abb62d00e&lk_target_url=${links}`
      );
    }
  }

  // window.h5sdk.ready(function () {
  //     window.tt.openSchema({
  //         schema: options.url,
  //         external: false,
  //         success(res) {
  //             console.log('打开新页面成功', res);
  //         },
  //         fail(res) {
  //             console.log('打开新页面失败', `${JSON.stringify(res)}`);
  //         }
  //     });
  // });
}
//     // 飞书鉴权
export const getFeishuSignPackages = async (urlData) => {
  console.log("getFeishuSignPackages", urlData);
  initDcm();
  try {
    let res;
    if (DcmObj.type == 1) {
      res = await getFeishuSignPackage(urlData);
    } else {
      res = await getXuehaiFeishuSignPackage(urlData);
    }
    // debugger;
    return new Promise((resolve, reject) => {
      console.log("飞书鉴权参数", res.data);
      window.h5sdk.config({
        appId: res.data.appId,
        timestamp: res.data.timestamp,
        nonceStr: res.data.nonceStr,
        signature: res.data.signature,
        jsApiList: ["user_profile"],
        // 鉴权成功回调
        onSuccess: (res) => {
          window.h5sdk.ready(() => {
            try {
              window.tt.setNavigationBar({
                // left: {
                // 	items: []
                // },
                right: {
                  items: [],
                },
                success: (res) => {
                  console.log("setNavigationBar success");
                },
                fail: (err) => {
                  console.log("setNavigationBar fail：", JSON.stringify(err));
                },
              });
            } catch (e) {
              // TODO handle the exception
              console.log(111, e);
            }
          });
          console.log(`飞书鉴权成功config success: ${JSON.stringify(res)}`);
        },
        // 鉴权失败回调
        onFail: (err) => {
          reject(err);
          throw `飞书鉴权失败1config failed: ${JSON.stringify(err)}`;
        },
      });
    });
  } catch (error) {
    console.log("飞书鉴权失败2", error);
    return false;
  }
};
export const mergeArray = (arr1, arr2, key) => {
  console.log("noopenId");
  const list = arr1.concat(arr2);
  const noopenId = list.filter((item) => {
    return !item[key];
  });
  const hasopenId = list.filter((item) => {
    return item[key];
  });
  let map = new Map();
  for (let item of hasopenId) {
    if (!map.has(item[key])) {
      map.set(item[key], item);
    }
  }

  return [...noopenId, ...map.values()];
};
