<template>
  <div class="main">
    <div class="mybeian">
      <div class="title">
        <!-- <img class="fanhuiimg" @click="fanhui" src="../../assets/icons/pcindex/b3b4.png" /> -->
        <span class="span-t1">内刊审核</span>
      </div>
      <div class="nrlist">
        <el-form :model="form">
          <el-row>
            <el-form-item label="">
              <el-input v-model="form.name" placeholder="请输入期刊名称" style="width: 240px" />
            </el-form-item>
            <el-form-item label="审核状态:" style="margin-left: 16px">
              <el-select v-model="form.status" style="width: 240px">
                <el-option :value="3" label="全部"></el-option>
                <el-option :value="0" label="审核中"></el-option>
                <el-option :value="1" label="审核通过"></el-option>
                <el-option :value="2" label="审核驳回"></el-option>
              </el-select>
            </el-form-item>
            <el-button type="primary" @click="search" class="search">查询</el-button>
          </el-row>
        </el-form>
        <el-table :data="records" border :row-style="{ height: '52px' }" :cell-style="{ padding: '0px' }" v-loading="loading">
          <el-table-column prop="name" label="期刊名称" />
          <el-table-column prop="status" label="审核状态" width="100" :show-overflow-tooltip="true">
            <template #default="scope">
              <span v-if="scope.row.status == 0" style="font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #fb9c00">审核中</span>
              <span v-if="scope.row.status == 1" style="font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #21c05e">审核通过</span>
              <span v-if="scope.row.status == 2" style="font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #ff4224">审核驳回</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="主办单位" width="150" :show-overflow-tooltip="true">
            <template #default="scope">
              <span>{{ findManagement(scope.row.management) }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="recorderName" label="创建人" width="120">
            <template #default="scope">
              <span>{{
                scope.row.userForJournal == 1 && scope.row.hasPassed == 0
                  ? `${scope.row.recorderName}`
                  : "-"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="recorderName" label="修改人" width="120">
            <template #default="scope">
              <span>{{
                (scope.row.userForJournal == 2 && scope.row.hasPassed == 1) ||
                (scope.row.userForJournal == 1 && scope.row.hasPassed == 1)
                  ? `${scope.row.recorderName}`
                  : "-"
              }}</span>
            </template>
          </el-table-column> -->
          <el-table-column prop="recorderName" label="提交人" width="80" />
          <el-table-column prop="manager" label="管理人" width="240" :show-overflow-tooltip="true" />
          <el-table-column prop="recordTime" label="备案时间" width="120" :show-overflow-tooltip="true">
            <template #default="scope">
              <span>{{ dayjs(scope.row.recordTime).format("YYYY-MM-DD") }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="90">
            <template v-slot:default="scope">
              <span class="btn" v-if="scope.row.status == 0" type="primary" text @click="lookUp(scope.row)">审批</span>
              <span class="btn" v-if="scope.row.status !== 0" type="primary" text @click="lookUp(scope.row)">查看</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page-wrapper">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20, 50, 100]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { list } from "@/api/createJournal";
import { optionsList } from "../approveJournal/components/data";
export default {
  data() {
    return {
      form: {
        name: null,
        stauts: 3,
      },
      currentPage: 1,
      size: 20,
      total: 0,
      records: [],
      optionsList: optionsList,
      // parent: {},
      // children: {},
      loading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    dayjs,
    fanhui() {
      this.$router.go(-1);
    },
    getList() {
      this.loading = true;
      let params = {
        current: this.currentPage,
        size: this.size,
        name: this.form.name,
        status: this.form.status === 3 ? null : this.form.status,
      };
      list(params).then((res) => {
        this.records = res.data.records;

        this.total = res.data.total;
        this.loading = false;
      });
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
    lookUp(row) {
      this.$router.push({
        path: "/approveJournal",
        query: { id: row.id, type: 1 },
      });
    },
    findOptionsText(val) {
      let parent, children;
      this.optionsList.forEach((item) => {
        item.children.forEach((ele) => {
          if (ele.value == val) {
            parent = item;
            children = ele;
          }
        });
      });
      return `${parent.text}/${children.text}`;
    },
    findManagement(val) {
      if (val.children && val.children.length > 0) {
        let str = val.label;
        val.children.forEach((item, index) => {
          val.label == item.label ? (str = item.label) : (str += `/${item.label}`);
          if (item.children && item.children.length > 0) {
            this.findManagement(item.children);
          }
        });
        return str;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  // margin: 0 auto;
  padding-right: 16px;
  height: 100%;
  overflow: hidden;
}

// @media screen and (min-width: 820px) {
//   .main {
//     width: 928px;
//   }
// }

// @media screen and (min-width: 960px) {
//   .main {
//     width: 928px;
//   }
// }

// @media screen and (min-width: 1200px) {
//   .main {
//     width: 928px;
//   }
// }

.mybeian {
  // width: 1248px;
  height: 100%;
  background: #ffffff;
  border-radius: 10px;
  // margin-top: 16px;
  padding: 24px 0;
  margin-bottom: 23px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .title {
    padding-left: 46px;
    height: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    flex-shrink: 0;
    .fanhuiimg {
      height: 16px;
      width: 16px;
      margin-left: 16px;
      cursor: pointer;
    }

    .span-t1 {
      width: 64px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2f3238;
      line-height: 22px;
      // margin-left: 10px;
    }
  }

  .cate {
    // width: 928px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .button {
      margin-right: 20px;
      width: 102px;
      height: 32px;
      background: #286cfb;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.9);
      line-height: 22px;
    }
    .cate-i {
      .cate-span {
        color: #286cfb;
        width: 56px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        margin-right: 684px;
        line-height: 22px;
      }

      .cate-icon {
        margin-right: 3px;
        width: 16px;
        width: 16px;
      }
    }
  }

  .nrlist {
    width: 100%;
    padding-left: 46px;
    padding-right: 46px;
    height: calc(100% - 92px);
    box-sizing: border-box;
    .tabs {
      .tab {
        margin-top: 15px;
        margin-bottom: 5px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 20px;

        ::v-deep .el-tabs__item {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          line-height: 20px;
        }
      }
    }

    ::v-deep .el-tabs__item.is-active {
      color: #286cfb !important;
    }
    ::v-deep .el-tabs__active-bar {
      background-color: #286cfb;
    }
    ::v-deep .el-table{
      height: calc(100% - 52px);
    }
  }
  .page-wrapper {
    padding-right: 46px;
    flex-shrink: 0;
    height: 60px;
    background: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  ::v-deep .el-form-item__label {
    height: 32px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 32px;
  }
  .search {
    width: 60px;
    height: 32px;
    background: #286cfb;
    border-radius: 4px;
    margin-left: 16px;
    border: none;
  }
  .btn {
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #286cfb;
  }
}
//鼠标经过下拉框
::v-deep.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: rgba(40, 108, 251, 0.1);
  border-radius: 3px;
  font-weight: 400;
  color: #286cfb;
}
</style>
