import {Form,Radio,Button, Avatar,Input,Tooltip,Drawer,Modal,Select,DatePicker,TimePicker,Upload,Timeline,InputNumber  } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

const ant = {
  install: function (Vue) {
    Vue.use(Button)
    Vue.use(Avatar)
    // 引入textArea竟然是直接引入input 
    Vue.use(Input)
    Vue.use(Radio)
    Vue.use(Tooltip)
    Vue.use(Drawer)
    Vue.use(Modal)
    Vue.use(Select)
    Vue.use(DatePicker)
    Vue.use(TimePicker)
    Vue.use(Upload)
    Vue.use(Timeline)
      Vue.use(Form)
      Vue.use(InputNumber)

  }
}

export default ant