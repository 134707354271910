import myAxios from './request'
 
 
// //通过iHaier用户token获取海尔人token
// export function getTokenByIhaier(data) {
//     return myAxios({
//       url: '/iHaier/login',
//       method: 'get',
//       params: data,
//     })
// }
 // 飞书鉴权
    export function getFeishuSignPackage(data) {
        return myAxios({
          url: '/iHaier/signatureBackend',
          method: 'get',
          params: data,
        })
    }
    export function getXuehaiFeishuSignPackage(data){
      return myAxios({
        //signatureForXuehai
        //signatureBackend
        url: '/iHaier/signatureForXuehai',
        method: 'get',
        params: data,
      }) 
    }
    export function getXuehaiFeishuToken(data){
      return myAxios({
        url: '/user/loginXuehaiBackend',
        method: 'get',
        params: data,
    }) 
  }

  export function getXuehaiJournal(id){
    return myAxios({
      url: `/journalLibrary/${id}`,
      method: 'get',
      params: data,
  }) 
  }



     // 获取部门名称
     export function getDepartName(data) {
      return myAxios({
        url: '/iHaier/queryDepartName',
        method: 'post',
         data,
      })
  }
    