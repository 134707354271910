import OSS from "ali-oss";
import axios from "axios";
export const bucketName = "managedigital-record-test-dev";

function guid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
export default function upLoad(_name, file, filelist) {
  const token = sessionStorage.getItem("token");
  const a = _name.split(".");
  const b = guid();
  const name = b + "." + a[1];
  return axios
    .request({
      baseURL: "/api",
      timeout: 20000,
      headers: {
        Authorization: "Bearer " + token,
      },
      url: "/user/getStsToken",
      method: "GET",
    })
    .then((response) => {
      const resp = response.data.data;
      console.log(resp.securityToken);
      const client = new OSS({
        region: "cn_beijing",
        accessKeyId: resp.accessKeyId,
        accessKeySecret: resp.accessKeySecret,
        stsToken: resp.securityToken,
        secure: true,
        endpoint: "oss-cn-beijing.aliyuncs.com",
        bucket: window.origin.includes("-test")
          ? "managedigital-record-test"
          : "managedigital-record",
      });
      return client
        .multipartUpload(name, file, {
          // progress: (p, cpt, res) => {
          //     console.log('上传进度', p, cpt, res)
          //     if (filelist) {
          //         filelist.forEach(ele => {
          //             if (cpt && res) {
          //                 if (ele.uid === cpt.file.uid) {
          //                     ele.url = res.requestUrls[0].split("?")[0];
          //                     ele.progress = p
          //                 }
          //             } else {
          //                 ele.progress = p
          //             }
          //         });
          //     }
          // }
        })
        .then((res) => {
          console.log("url1", res);
          return res.res.requestUrls[0];
        });
    })
    .catch((res) => console.log("res", res));
}
