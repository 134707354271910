import { createRouter, createWebHistory } from "vue-router";
import yishi from "@/views/index";
import journalsindex from "@/views/manager/journalsindex";
import JournalVerifyH5 from "@/views/JournalVerifyH5/index"; // 移动端期刊审核
import approveJournal from "../views/approveJournal/index"; // 审批期刊
import JournalLibrary from "../views/JournalLibrary/index"; // 期刊库
import AdministratorConfiguration from "../views/administratorConfiguration/index"; // 管理员配置
import createJournal from "../views/createJournal/index"; // 修改期刊
import historicalJournals from "../views/historicalJournals/index"; // 历史期刊
import historyJournalVerifyH5 from "../views/historyJournalVerifyH5/index"; // H5历史期刊
import JournalList from "../views/JournalList/index"; // 期刊榜单
import RankDetail from "../views/JournalList/rankDetail"; // 期刊榜单详情
import EchartsTest from "@/views/EchartsTest.vue";
import Cmckindex from "@/views/manager/cmckindex";
import WarningReview from "@/views/warningReview/index.vue";
import DataCenter from "@/views/dataCenter/index.vue";
const routes = [
  {
    path: "/",
    component: yishi,
    // redirect: "/datacenter",
    // },
    children: [
      {
        path: "/WarningReview",
        name: "WarningReview",
        component: WarningReview,
      },
      {
        path: "/datacenter",
        name: "DataCenter",
        component: DataCenter,
      },
      {
        path: "/JournalLibrary",
        name: "JournalLibrary",
        component: JournalLibrary,
      },
      {
        path: "/administratorConfiguration",
        name: "administratorConfiguration",
        component: AdministratorConfiguration,
      },
      {
        path: "/journalsindex",
        name: "/journalsindex",
        component: journalsindex,
      },
      {
        path: "/approveJournal",
        name: "/approveJournal",
        component: approveJournal,
      },
    ],
  },
  {
    path: "/cmckindex",
    name: "/cmckindex",
    component: Cmckindex,
  },

  {
    path: "/JournalVerifyH5/:id",
    name: "/JournalVerifyH5/:id",
    component: JournalVerifyH5,
  },

  {
    path: "/createJournal",
    name: "createJournal",
    component: createJournal,
  },
  {
    path: "/historyJournalVerifyH5/:id",
    name: "historyJournalVerifyH5/:id",
    component: historyJournalVerifyH5,
  },
  {
    path: "/historicalJournals",
    name: "historicalJournals",
    component: historicalJournals,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/journalList",
    name: "journalList",
    component: JournalList,
  },
  {
    path: "/rankdetail",
    name: "rankDetail",
    component: RankDetail,
  },
  {
    path: "/EchartsTest",
    name: "EchartsTest",
    component: EchartsTest,
  },
];
const router = createRouter({
  history: createWebHistory(),
  base: "/yishi",
  routes,
});

router.beforeEach((to, from, next) => {
  console.log("to", to);
  next();
});

export default router;
