<template>
<div>
    <h1>echarts test</h1>
    <div style="width: 400px;height: 400px;border:1px solid #000;">
        <PieDiagram :data="pieData" @check="onCheck"></PieDiagram>
    </div>
    <div style="width: 400px;height: 400px;border:1px solid #000;">
        <LineDiagram :seriesData="lineData" :xAxisData="lineXAxisData" title="近一周发文数"></LineDiagram>
    </div>
</div>
</template>

<script setup scope>
import { ref } from 'vue'
import PieDiagram from '@/components/charts/PieDiagram.vue'
import LineDiagram from '@/components/charts/LineDiagram.vue'

const pieData = ref([
])
const lineData = ref([])
const lineXAxisData = ref([])
pieData.value = [{ value: 1048, name: 'Search Engine' },
    { value: 735, name: 'Direct' },
    { value: 580, name: 'Email' },
    { value: 484, name: 'Union Ads' },
    { value: 300, name: 'Video Ads' }]

const onCheck = data => {
    console.log('check：', data)
}

lineData.value = [{
    // data: [820, 932, 901, 934, 1290, 1330, 1320],
    data: [
        {value: 820,
         label: '1-Mon'},
         {value: 932,
         label: '2-Mon'},
         {value: 901,
         label: '3-Mon'},
         {value: 934,
         label: '4-Mon'},
         {value: 1290,
         label: '5-Mon'},
         {value: 1330,
         label: '6-Mon'},
         {value: 1320,
         label: '7-Mon'}
    ],
    tooltipData: ['1-Mon', '2-Tue', '3-Wed', '4-Thu', '5-Fri', '6-Sat', '7-Sun'],
    type: 'line',
    smooth: true
}]

lineXAxisData.value = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
</script>

<style lang="less" scoped>

</style>