import myAxios from "./request";
// 预警审核列表
export function warningList(params) {
  return myAxios({
    url: "/wordAlert/list",
    method: "get",
    params,
  });
}

//审核
export function warningAudit(params) {
  return myAxios({
    url: "/wordAlert/audit",
    method: "get",
    params,
  });
}

//审核详情
export function warningDetail(params) {
  return myAxios({
    url: "/wordAlert/detail",
    method: "get",
    params,
  });
}
