<template>
  <div class="main" v-if="false">
    <div class="tongyong">
      <div class="title">
        <div class="tongyong-icon"></div>
        <div class="tongyong-ziti">通用功能</div>
      </div>
      <div class="label">
        <div
          class="label-item"
          style="margin-left: 21px"
          @click="zdBeian"
          :class="{ mouseover: active == 1 }"
          @mouseover="Mouseover(1)"
          @mouseleave="Mouseleave()"
        >
          <img class="item-img" src="../../assets/icons/pcindex/13@2x.png" />
          <div class="spans">
            <span class="span-t1">阵地备案</span>
            <span class="span-t2">各类宣传思想文化阵地备案</span>
          </div>
        </div>
        <div
          class="label-item"
          @click="nrBeian"
          :class="{ mouseover: active == 2 }"
          @mouseover="Mouseover(2)"
          @mouseleave="Mouseleave()"
        >
          <img class="item-img" src="../../assets/icons/pcindex/14@2x.png" />
          <div class="spans">
            <span class="span-t1">内容备案</span>
            <span class="span-t2">各阵地内容备案</span>
          </div>
        </div>
        <div
          class="label-item"
          @click="myBeian"
          :class="{ mouseover: active == 3 }"
          @mouseover="Mouseover(3)"
          @mouseleave="Mouseleave()"
        >
          <img class="item-img" src="../../assets/icons/pcindex/15@2x.png" />
          <div class="spans">
            <span class="span-t1">我的备案</span>
            <span class="span-t2">查看我的内容备案</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
export default {
  data() {
    return {
      active: 0, // 悬停区域原始颜色
      background: "",
    };
  },
  setup() {
    const router = useRouter();
    const zdBeian = () => {
      router.push("zdBeian");
    };
    const myBeian = () => {
      router.push("myBeian");
    };
    const nrBeian = () => {
      router.push("nrBeian");
    };
    return {
      myBeian,
      zdBeian,
      nrBeian,
    };
  },
  methods: {
    // 鼠标"悬停"触发此方法
    Mouseover(num) {
      this.active = num; //
      this.background = "cursor:pointer;background-color:#F6F7FA"; // 进入 悬停区域,更改颜色
    },
    // 鼠标"离开"触发此方法
    Mouseleave() {
      this.active = ""; // 离开 悬停区域,文字颜色复原
    },
  },
};
</script>

<style lang="scss" scoped>
.mouseover {
  cursor: pointer;
  background-color: #f6f7fa;
}
@media screen and (min-width: 960px) {
  .main {
    width: 928px;
  }
}

@media screen and (min-width: 1200px) {
  .main {
    width: 928px;
  }
}
.main {
  margin: 0 auto;
}
.tongyong {
  height: 136px;
  background: #ffffff;
  border-radius: 10px;
  margin: 16px;
  .title {
    height: 22px;
    display: flex;
    align-items: center;
    padding-left: 21px;
    padding-top: 24px;
    padding-bottom: 8px;
    .tongyong-icon {
      width: 4px;
      height: 14px;
      background: #286cfb;
      border-radius: 3px;
      margin-left: 3px;
      margin-right: 9px;
    }

    .tongyong-ziti {
      width: 64px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2f3238;
      line-height: 22px;
    }
  }
  .label {
    width: 928px;
    height: 68px;
    display: flex;
    .label-item {
      margin-left: 0px;
      margin-top: 8px;
      width: 210px;
      height: 68px;
      border-radius: 8px;
      display: flex;

      .item-img {
        margin-left: 16px;
        margin-top: 12px;
        width: 44px;
        height: 44px;
      }
      .spans {
        margin-left: 14px;
        margin-top: 12px;
        width: 120px;
        height: 44px;

        .span-t1 {
          margin-top: 3px;
          width: 120px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2f3238;
          line-height: 20px;
          display: block;
        }
        .span-t2 {
          margin-top: 3px;
          width: 120px;
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
          display: block;
        }
      }
    }
  }
}
</style>
