import myAxios from "./request";
// 按分类统计备案总数
export function beianTotal(params) {
  return myAxios({
    url: "/record/statistics/totalRecord",
    method: "get",
    params,
  });
}
// 备案明细列表
export function beianDetail(params) {
  return myAxios({
    url: "/record/statistics/listRecord",
    method: "get",
    params,
  });
}
// 阵地影响力排行;
export function rankingRecord(params) {
  return myAxios({
    url: "/record/statistics/rankingRecord",
    method: "get",
    params,
  });
}

//爆款文章排行
export function rankingArticle(params) {
  return myAxios({
    url: "/record/statistics/rankingArticle",
    method: "get",
    params,
  });
}

// 错敏词预警统计;
export function warningWordsList(params) {
  return myAxios({
    url: "/record/statistics/warning",
    method: "get",
    params,
  });
}

// 今日内容;
export function todayContent(params) {
  return myAxios({
    url: "/record/statistics/todayContent",
    method: "get",
    params,
  });
}
// 数据趋势;
export function trend(params) {
  return myAxios({
    url: "/record/statistics/trend",
    method: "get",
    params,
  });
}
//领域列表获取
export function areaList(params) {
  return myAxios({
    url: "/record/statistics",
    method: "get",
    params,
  });
}
//最近一次更新的时间
export function lastUpdateTime(params) {
  return myAxios({
    url: "/record/statistics/lastUpdateTime",
    method: "get",
    params,
  });
}
