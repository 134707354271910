<template>
<div class="data-center-wrap">
    <div class="refresh-wrap">
        <span>数据更新时间：{{refreshTime}}</span>
        <div class="refresh-btn" @click="toReload">加载最新数据</div>
    </div>
    <!-- 备案、影响力、爆款文章 -->
    <div class="b-i-h">
        <BeiAn :reload="reload"></BeiAn>
        <InfluenceCom  :reload="reload"></InfluenceCom>
        <HotArticle :reload="reload"></HotArticle>
    </div>
    <!-- 错敏词预警 -->
    <WarningCom :reload="reload"></WarningCom>
    <!-- 数据趋势 -->
    <DataTrend :reload="reload"></DataTrend>
    <!-- 今日内容 -->
    <TodayItems :reload="reload"></TodayItems>
</div>
</template>

<script scope setup>
import {
  RefreshRight
} from '@element-plus/icons-vue'
import BeiAn from './components/BeiAn.vue'
import InfluenceCom from './components/InfluenceCom.vue'
import HotArticle from './components/HotArticle.vue'
import WarningCom from './components/WarningCom.vue'
import DataTrend from './components/DataTrend.vue'
import TodayItems from './components/TodayItems.vue'
import { ref, nextTick } from 'vue'
import { ElMessage } from "element-plus";
import { lastUpdateTime } from '@/api/home.js'

const reload = ref(false)
const refreshTime = ref('')
let refreshTimeStamp = 0

// const timeRefresh = ()=>{
//     const n = new Date()
//     refreshTimeStamp = n.getTime()
//     refreshTime.value = `${n.getFullYear()}年${n.getMonth()+1}月${n.getDate()}日 ${n.getHours()>9?n.getHours():'0'+n.getHours()}:${n.getMinutes()>9?n.getMinutes():'0'+n.getMinutes()}:${n.getSeconds()>9?n.getSeconds():'0'+n.getSeconds()}`
// }
// timeRefresh()

const getLastRefreshTime = ()=>{
    return new Promise((resolve, reject)=>{
        lastUpdateTime().then(res=>{
            resolve(res)
        }).catch(err=>{
            console.log('getLastRefreshTime 报错：', err)
            reject(err)
        })
    }) 
}
getLastRefreshTime()
    .then((res)=>{
        refreshTime.value = res.data
        refreshTimeStamp = new Date(res.data).getTime()
        ElMessage.success('数据加载成功')
    })
const toReload = ()=>{
    const d = new Date().getTime()

    console.log('==========:', d,refreshTimeStamp )
    if(d - refreshTimeStamp <30000){
        ElMessage.info('目前已经是最新数据，请10分钟后刷新')
        return
    }

    getLastRefreshTime()
    .then((res)=>{
        // ElMessage.info('数据加载成功')
        if(refreshTime.value === res.data) {
            const n = new Date().getTime()
            const left = 10 - Math.floor((n - refreshTimeStamp)/60000)
            ElMessage.info('目前已经是最新数据，请'+left+'分钟后刷新')
        }else{
            refreshTime.value = res.data
            refreshTimeStamp = new Date(res.data).getTime()
            reload.value = true
            // timeRefresh()
            nextTick(()=>{
                reload.value = false
            })
        }
    })

    
}

</script>

<style lang="less" scoped>
.data-center-wrap{
    margin: 0 auto;
    position: relative;
    width: 1279px;
    .refresh-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0,0,0,0.45);
        position: absolute;
        top: 0;
        right: 30px;
        height: 44px;
    }
    .refresh-btn{
        margin-left: 12px;
        width: 106px;
        height: 28px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #0073E5;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #0073E5;
        opacity: 0.8;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .b-i-h{
        padding-top: 44px;
        margin-bottom: 16px;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        >div{
            flex-shrink: 0;
            margin-right: 16px;
        }
        >div:last-child{
            margin-right: 0;
        }
    }
}
</style>