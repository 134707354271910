import myAxios from "./request";
// 查询排行榜
export function getRankList(params) {
  return myAxios({
    url: "/rankingList",
    method: "get",
    params,
  });
}
// 新增榜单
export function addRank(data) {
    return myAxios({
      url: "/rankingList",
      method: "post",
      data,
    });
  }
// 修改期刊
export function editRank(data) {
  return myAxios({
    url: "/rankingList",
    method: "put",
    data,
  });
}

//  通过主键查询单条期刊数据
export function query(id) {
  return myAxios({
    url: `/journalLibrary/${id}`,
    method: "get",
  });
}

//  删除期刊
export function deleteRank(params) {
  return myAxios({
    url: "/rankingList",
    method: "DELETE",
    params,
  });
}

// 获取上个版本期刊
export function changeRankStatus(params) {
  return myAxios({
    url: "/rankingList/changeStatus",
    method: "put",
    params,
  });
}
