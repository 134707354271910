<!-- 带间隙的饼图 -->
<template>
<div id="main_pie" ref="target"></div>
</template>

<script scope setup>
import { onMounted, watch, nextTick, ref } from 'vue'
import * as echarts from 'echarts/core';
import { TooltipComponent, LegendComponent, GraphicComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useElementVisibility } from '@vueuse/core'

echarts.use([
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
  GraphicComponent
]);

const props = defineProps({
    // 图标名称
    name: {
        type: String,
        default: ''
    },
    data: {
        type: Array,
        default: ()=>([])
    },
    // legend样式
    legendStyle: {
        type: Object,
        default: ()=>({})
    },
    type: {
        type: Number
    },
    showCheck: {
        type: Boolean
    }
})

const emits = defineEmits(['check'])

var chartDom = null
var myChart = null

const clickData = ref(null)
const itemClick = ()=>{
    let params = clickData.value
    emits('check', params)
}

function addListener (){
    myChart&&myChart.on('legendselectchanged', function (event) {
        // event.preventDefault()
        // console.log('event:', event)
        var isLegendClick = event.isDataSelect ? false : true; // 判断是否为图例点击
        if (isLegendClick) {
            // 自定义事件处理
            // console.log('图例被点击，事件数据：', event);
            // 在这里编写你的自定义逻辑
        }
        var isSelected = event.selected[event.name]; // 获取当前图例的选中状态
        // console.log(event.name + ' is ' + (isSelected ? 'selected' : 'unselected'));
        // 在这里编写你的逻辑代码
    })

    myChart&&myChart.on('legendclick', function (event) {
        // console.log('lick:', event)
    })
}

window.itemClick = itemClick

// 标记需要绘图
var needRender = false
const total = ref(0)
const render = ()=>{
    chartDom = document.getElementById('main_pie');
    // console.log('2333:', chartDom)
    if(!myChart){
        myChart = echarts.init(chartDom);
    }
    total.value = 0
    props.data.forEach(element => {
        total.value += element.value
    });
    // console.log('props.data.total:', total.value)
    // console.log(666, props.legendStyle, props.type)
    var option;

    option = {
        tooltip: {
            trigger: 'item',
            position: (point)=> ([point[0], point[1]]),
            formatter: function (params){
                clickData.value = params
                let s = `${params.name}    ${params.value}`
                if(props.showCheck) {
                    s += `<a onclick="itemClick()" style="margin-left: 10px;">查看 ></a>`
                }
                return s
            },
            enterable: true
        },
        legend: {
            width: props.legendStyle.width,
            height: 230,
            type: 'scroll',
            orient: 'vertical',
            top: 'center',
            right: props.legendStyle.right || 10,
            itemWidth: 6, // 图例标记的图形宽度，默认为14。
            itemHeight: 6, // 图例标记的图形高度，默认为14。
            pageIconSize: 8,
            icon: 'circle',
            overflow: 'truncate',
            textStyle: {
                width: props.legendStyle.width,
                fontSize: 14,
                overflow: 'truncate',
                align: 'left',
                rich: {
                    a: {
                        width: 80,
                        align: "left",
                        overflow: 'truncate',
                        fontSize: 16,
                        overflow: 'truncate',
                        // backgroundColor: '#f00'
                    },
                    b: {
                        width: 40,
                        align: "right",
                        fontSize: 16
                        // backgroundColor: '#0f0'
                    }
                }
            },
            formatter: function (name) {
                // console.log('name:', name)
                const item = props.data.find(item=>item.name == name)
                const s1 = name+'：'
                const s2 = item.value 
                // console.log(888, `{a|${s1}}{b|${s2}}`)
                if(props.type == 0) {
                    return `{a|${s1}}`
                }
                else if(props.type == 1) {
                    return `{a|${s1}}{b|${s2}}`
                }
            }
            // selectedMode:false 
        },
        color: ['#286CFB', '#21C05E', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        series: [
            {
            name: props.name,
            type: 'pie',
            radius: ['40%', '60%'],
            center: [80,'50%'],
            avoidLabelOverlap: false,
            padAngle: 0,
            itemStyle: {
                borderRadius: 3
            },
            label: {
                show: false,
                position: 'center'
            },
            emphasis: {
                label: {
                    show: false,
                    fontSize: 40,
                    fontWeight: 'bold'
                }
            },
            labelLine: {
                show: false
            },
            data: props.data
            }
        ],
        // 图形中心的数据
        graphic: [
            {
                type: 'group',
                left: 80,
                top:'center',
                bounding: 'raw',
                children: [
                    {
                        type: 'text',
                        style: {
                            text: `账号总数`,
                            textAlign: 'center',
                            textVerticalAlign: 'bottom',
                            fontSize: 10
                        }
                    },
                    {
                        type: 'text',
                        style: {
                            text: `${total.value}`,
                            textAlign: 'center',
                            textVerticalAlign: 'top',
                            fontSize: 26
                        }
                    }
                ]
            } 
        ]
    };

    option && myChart.setOption(option);
    // addListener()
}
//查看更多按钮
const target = ref(null)
const targetIsVisible = useElementVisibility(target)

watch(targetIsVisible , newVal =>{
	if(newVal && needRender){
		render()
	}
})

watch(()=>props.data, val=>{
    // console.log('data:', props.data)
    if(val&&val.length){
        if(target.value) {
            render()
        }else{
            needRender = true
        }
    } else{
        myChart&&myChart.clear()
    }
},{
    immediate: true
})

window.addEventListener('resize', function(){
    myChart&&myChart.resize()
})

</script>

<style lang="less" scoped>
#main_pie{
    width: 100%;
    height: 252px;
}
</style>