<template>
  <div class="main">
    <div class="content" v-loading="loading">
      <div class="title">
        <img class="fanhuiimg" @click="fanhui" src="../../assets/icons/pcindex/b3b4.png" />
        <span class="span-t1">修改期刊</span>
        <span class="person">（期刊创建业务联系人：任志杰 13808952100）</span>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="210px">
        <!-- 多语言 -->
        <el-form-item label="多语言" prop="language">
          <el-checkbox-group v-model="ruleForm.language">
            <el-checkbox v-for="item in languages" :key="item.value" :label="item.value" :disabled="item.disabled">{{item.label}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="期刊名称" prop="name">
          <el-input ref="name" v-model="ruleForm['name'+ '_' + languages[item].simple]" 
            v-for="(item, index) in ruleForm.language" 
            :key="item" :placeholder="languages[item].placeholder"
            style="margin: 0 0 10px 0;"
            :maxlength="languages[item].nameMaxlength" show-word-limit>
            <template #prefix>
              <img style="width: 16px;height: 16px;" :src="languages[item].icon" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="期刊定位" prop="position">
          <el-input ref="position" type="textarea" v-model="ruleForm.position" placeholder="请输入" maxlength="100"
            show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="期刊介绍" prop="description">
          <el-input ref="description" type="textarea" v-model="ruleForm['description'+ '_' + languages[item].simple]" 
            v-for="(item, index) in ruleForm.language" 
            :key="item" :placeholder="languages[item].descPlaceholder"
            style="margin: 0 0 10px 0;"
            :maxlength="languages[item].descMaxlength" show-word-limit>
            <template #prefix>
              <img style="width: 16px;height: 16px;" :src="languages[item].icon" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="封面图" prop="cover">
          <el-upload class="avatar-uploader" action="#" :auto-upload="false" :show-file-list="false"
            :before-upload="beforeAvatarUpload" :on-change="handleChange" :disabled="uploadBoolean">
            <img v-if="ruleForm.cover" :src="ruleForm.cover" class="avatar"
              style="object-fit: cover; width: 75px; height: 100px" />
            <el-icon v-else class="avatar-uploader-icon">
              <Plus />
            </el-icon>
            <div v-if="ruleForm.cover" class="el-upload-list__item-actions" @click="oploadImgDel">
              <div class="el-upload-div" @click="oploadImgDel">
                <span class="el-upload-span">点击更改</span>
              </div>
            </div>
            <template #tip>
              <div class="el-upload__tip" style="color: #999999; line-height: 17px; heigth: 17px">支持
                jpg/png/jpeg格式文件，大小不超过2M，封面尺寸推荐1160*1500px</div>
            </template>
          </el-upload>
        </el-form-item>
        <el-form-item label="主办单位" prop="management">
          <el-cascader ref="management" popper-class="pc-sel-area-cascader" :options="options"
            v-model="ruleForm.management" placeholder="请选择" @change="managementChange"> </el-cascader>
        </el-form-item>
        <el-form-item label="主管部门" prop="department">
          <el-select v-model="ruleForm.department" placeholder="请选择" @change="handleChangeDepart" ref="department">
            <el-option v-for="item in partyBranch" :key="`${item.value}_partyBranch`" :label="item.label"
              :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="管理人" prop="manager">
          <div class="selecper">
            <new-select-per title="选择管理人" v-model:chosen-list="ruleForm.manager" key="edit" :type="[1]"
              :max-person-num="20"></new-select-per>
          </div>
        </el-form-item>
        <el-form-item label="面向对象" prop="target.value">
          <el-select v-model="ruleForm.target.value" placeholder="请选择" @change="selectChange">
            <el-option v-for="item in faceObject" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="target.input" v-if="ruleForm.target.value || ruleForm.target.value == 0">
          <el-input v-model.trim="ruleForm.target.input" maxlength="20" placeholder="请输入面向对象的详细描述" show-word-limit />
        </el-form-item>
        <el-form-item label="可见范围(学海)" prop="range.value">
          <el-radio-group v-model="ruleForm.range.value">
            <el-radio :label="1">全部可见</el-radio>
            <el-radio :label="2">部分可见</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="" prop="range.input" v-if="ruleForm.range.value == 2">
          <!-- <SelectPerDepart :list="ruleForm.range.input" :type="1" :maxCount="500" @addPer="SelectAdd"
            @del-event="SelectDelete"></SelectPerDepart> -->
          <new-select-per title="选择可见范围" v-model:chosen-list="ruleForm.range.input" key="edit" :type="[1, 2, 3]"
            :max-person-num="200" :max-dept-num="20" :max-group-num="20"></new-select-per>
        </el-form-item>

        <el-form-item label="创刊日期" prop="startDate">
          <div @click="dateClick">
            <el-date-picker :editable="false" v-model="ruleForm.startDate" type="date" placeholder="选择日期"
              value-format="YYYY-MM-DD"> </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="出版周期" prop="publishTime.isRegular">
          <el-radio-group v-model="ruleForm.publishTime.isRegular">
            <el-radio :label="1">定期</el-radio>
            <el-radio :label="0">不定期</el-radio>
          </el-radio-group>

          <el-form-item label="" prop="publishTime.time" v-if="ruleForm.publishTime.isRegular == 1">
            <el-input-number class="w192" v-model="ruleForm.publishTime.time" controls-position="right" :precision="0"
              :min="1" :max="10"></el-input-number>

            <el-form-item label="" prop="publishTime.value">
              <el-select class="w192" v-model="ruleForm.publishTime.value" placeholder="请选择">
                <el-option v-for="item in cycleList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form-item>
        </el-form-item>
        <el-form-item label="印数" prop="print">
          <el-input class="remark" type="text" placeholder="请输入" v-model="ruleForm.print" maxlength="10"
            show-word-limit> </el-input>
        </el-form-item>
        <el-form-item label="所获荣誉" prop="honor">
          <el-input class="remark" type="textarea" placeholder="请输入" v-model="ruleForm.honor" maxlength="500"
            show-word-limit> </el-input>
        </el-form-item>
        <el-form-item label="备注" prop="note">
          <el-input class="remark" type="textarea" placeholder="请输入" v-model="ruleForm.note" maxlength="300"
            show-word-limit> </el-input>
        </el-form-item>
      </el-form>

      <div style="width: 100%; text-align: center">
        <el-button type="primary" @click="submit" class="btn">提交修改</el-button>
      </div>
    </div>

    <inform :visible="visible" :content="content" @dialogClose="dialogClose" />
  </div>
</template>

<script>
import { ElMessage, ElMessageBox } from "element-plus";
import inform from "./components/inform.vue";
import SelectPer from "@/components/SelectPer.vue";
import SelectPerDepart from "@/components/SelectPerDepart.vue";
import newSelectPer from "@/components/newSelectPer.vue";
import { submit, queryRecords, getDepartmentByManagement, getQueryManagementList, getQueryPartyBranchList, getQueryFaceObjectList, getQueryUserInfo } from "@/api/createJournal";
import { query } from "@/api/JournalLibrary";
import { edit } from "@/api/JournalLibrary";
import upload from "../../api/upload";
import { mergeArray } from "@/utils/Ihaier";
import { isIHaier } from "@/utils/platform";
import { languages } from '../approveJournal/components/data'
import { convertObject, convertArray } from '../approveJournal/components/api.js';

export default {
  components: {
    inform,
    SelectPer,
    SelectPerDepart,
    newSelectPer
  },
  data() {
    var validateCover = (rule, value, callback) => {
      if (this.ruleForm.cover == "") {
        callback(new Error("请上传一张图片"));
      } else {
        callback();
      }
    };
    var validateManager = (rule, value, callback) => {
      if (this.ruleForm.manager.length < 1) {
        callback(new Error("必填项未选择"));
      } else {
        callback();
      }
    };
    return {
      languages: languages,
      ruleForm: {
        language: [0],
        name: "",
        cover: "",
        position: "",
        description: "",
        management: "",
        department: "",
        target: { input: "", value: null },
        range: {
          value: 1,
          input: [],
        },
        startDate: "",
        publishTime: { time: 1, value: 3, isRegular: 1 },
        num: 1,
        note: "",
        honor: "",
        lastTime: "",
      },
      loading: false,
      rules: {
        name: [
          { 
            required: true, message: "必填项未填写", trigger: "blur",
            validator: (rule, value, callback) => {
              // 根据 ruleForm.language 中的值循环判断所有对应的字段
              let flag = true;
              this.ruleForm.language.forEach((index,item) => {
                if(languages[item]){
                  if (!this.ruleForm['name'+ '_' + languages[item].simple]) {
                    flag = false;
                  }
                }
              });
              if(!flag){
                callback(new Error("必填项未填写"));
              }else{
                callback();
              }
            },
          },
        ],
        position: [
          { required: true, message: "必填项未填写", trigger: "blur" },
          { max: 100, trigger: "blur" },
        ],
        description: [
          { 
            required: true, message: "必填项未填写", trigger: "blur",
            validator: (rule, value, callback) => {
              // 根据 ruleForm.language 中的值循环判断所有对应的字段
              let flag = true;
              this.ruleForm.language.forEach((index,item) => {
                if(languages[item]){
                  if (!this.ruleForm['description'+ '_' + languages[item].simple]) {
                    flag = false;
                  }
                }
              });
              if(!flag){
                callback(new Error("必填项未填写"));
              }else{
                callback();
              }
            },
          }
        ],
        cover: [
          { required: true, message: "必填项未选择", trigger: "change" },
          { validator: validateCover, trigger: "change" },
        ],
        management: [{ required: true, message: "必填项未选择", trigger: "change" }],
        department: [{ required: true, message: "必填项未选择", trigger: "change" }],
        manager: [
          { required: true, message: "必填项未选择" },
          { validator: validateManager, trigger: "blur" },
        ],
        "target.value": [{ required: true, message: "必填项未选择", trigger: "change" }],
        "target.input": [{ required: true, message: "必填项未填写", trigger: "blur" }],
        "range.value": [{ required: true, message: "必填项未选择", trigger: "change" }],
        "range.input": [{ required: true, message: "必填项未选择", trigger: "change" }],
        startDate: [{ required: true, message: "必填项未选择", trigger: "change" }],
        "publishTime.isRegular": [{ required: true, message: "必填项未选择", trigger: "change" }],
        "publishTime.value": [{ required: true, message: "必填项未选择", trigger: "change" }],
        "publishTime.time": [{ required: true, message: "必填项未选择", trigger: "change" }],
      },
      options: [],
      partyBranch: [],
      faceObject: [],
      disabled: false,
      visible: {
        dialogVisible: false,
      },
      cycleList: [
        { value: 1, label: "年/期" },
        { value: 2, label: "月/期" },
        { value: 3, label: "周/期" },
        { value: 4, label: "天/期" },
        // { value: 0, label: "不定期" },
      ],
      value: "",
      chainLeader: null,
      type: 1,
      id: 0,
      content: "",
      term: 0,
      uploadBoolean: false,
      beforeEditLanguageList: []
    };
  },
  watch: {
    "ruleForm.manager": {
      handler(newVal, oldVal) {
        if (newVal && newVal.length > 0) {
          this.$refs["ruleForm"].clearValidate("manager");
        }
      },
      immediate: true,
      deep: true, // 可以深度检测到 person 对象的属性值的变化
    },
    "ruleForm.range.input": {
      handler(newVal, oldVal) {
        if (newVal && newVal.length > 0) {
          this.$refs["ruleForm"].clearValidate("range.input");
        }
      },
      immediate: true,
      deep: true, // 可以深度检测到 person 对象的属性值的变化
    },
    "ruleForm.cover": {
      handler(newVal, oldVal) {
        if (newVal && newVal.length > 0) {
          this.$refs["ruleForm"].clearValidate("cover");
        }
      },
      immediate: true,
      deep: true, // 可以深度检测到 person 对象的属性值的变化
    },
  },
  created() {
    this.type = this.$route.query.type;
    this.id = this.$route.query.id;
    if (this.type || this.type == "") {
      this.getQueryRecords();
    }
    this.getQueryManagementList();
    this.getQueryPartyBranchList();
    this.getQueryFaceObjectList();
    // this.getQueryUserInfo();
  },
  methods: {
    // 主管单位列表
    getQueryManagementList() {
      this.loading = true;
      getQueryManagementList().then((res) => {
        this.options = res.data;
        this.loading = false;
      });
    },
    // 主管部门列表
    getQueryPartyBranchList() {
      this.loading = true;
      getQueryPartyBranchList().then((res) => {
        this.partyBranch = res.data;
        this.loading = false;
      });
    },
    // 面象对象列表
    getQueryFaceObjectList() {
      this.loading = true;
      getQueryFaceObjectList().then((res) => {
        this.faceObject = res.data;
        this.loading = false;
      });
    },
    // 获取当前用户信息
    // getQueryUserInfo() {
    //   this.loading = true;
    //   getQueryUserInfo().then((res) => {
    //     console.log(res);
    //     this.ruleForm.manager = [res.data];
    //     this.loading = false;
    //   });
    // },
    fanhui() {
      this.$router.go(-1);
    },
    handleChangeDepart(val) {
      // this.$refs.department.blur();
      console.log(val, "change====");
    },
    beforeAvatarUpload(rawFile) {
      const imgTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!imgTypes.includes(rawFile.type)) {
        ElMessage.error("上传图片需要为jpg,jpeg,png格式!");
        return false;
      } else if (rawFile.size / 1024 / 1024 > 2) {
        ElMessage.error("图片大小不能超过2MB!");
        return false;
      }
      return true;
    },
    handleChange(file) {
      let r = this.beforeAvatarUpload(file.raw);
      if (!r) {
        return;
      }
      upload(file.name, file.raw).then((res) => {
        this.ruleForm.cover = res.split("?")[0];
      });
    },
    // 获取期刊详情
    getQueryRecords() {
      this.loading = true;
      ////debugger;
      query(this.id).then((res) => {
        this.ruleForm = res.data;
        let lang = []
        if(res.data.i18n){
          lang = convertObject(res.data.i18n)
          lang.forEach((item,index)=>{
            this.ruleForm['name_' + item.language] = item.name;
            this.ruleForm['description_' + item.language] = item.description;
          })
          this.beforeEditLanguageList = JSON.parse(JSON.stringify(lang))
        }else{
          this.ruleForm['name_' + this.languages[0].simple] = res.data.name;
          this.ruleForm['description_' + this.languages[0].simple] = res.data.description;
          this.beforeEditLanguageList = [{
            language: this.languages[0].value,
            name: res.data.name,
            description: res.data.description
          }]

        }

        const langValue = []

        this.languages.map(item => {
          if(lang.find(it => it.language == item.simple && it.status !== 0)){
            langValue.push(item.value)
          }
        })
        
        this.ruleForm.language = langValue.length ? langValue : [0];

        this.ruleForm.target.value = res.data.target.selectList.value;
        this.ruleForm.management = this.findRuleManagement(res.data.management);
        this.ruleForm.department = res.data.department.value;
        this.ruleForm.manager = res.data.manager.map(item => ({
          ...item,
          type: 1
        }))
        this.loading = false;
      });
    },
    findManagement(val) {
      if (val.children && val.children.length > 0) {
        let str = val.label;
        val.children.forEach((item, index) => {
          val.label == item.label ? (str = item.label) : (str += `/${item.label}`);
          if (item.children && item.children.length > 0) {
            this.findManagement(item.children);
          }
        });
        return str;
      }
    },
    findRuleManagement(val) {
      if (val.children && val.children.length > 0) {
        val.children.forEach((item, index) => {
          this.num = item.value;
          if (item.children && item.children.length > 0) {
            this.findRuleManagement(item.children);
          }
        });
        return this.num;
      }
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = {
            ...this.ruleForm,
            name: this.ruleForm['name_' + this.languages[0].simple],
            description: this.ruleForm['description_' + this.languages[0].simple],
            management: this.ruleForm.management.length ? this.ruleForm.management[this.ruleForm.management.length - 1] : this.ruleForm.management,
            i18n: []

          };

          let i18nParams = this.ruleForm.language.map(item => {
            return {
              language: this.languages[item].simple,
              name: this.ruleForm['name_' + this.languages[item].simple],
              description: this.ruleForm['description_' + this.languages[item].simple],
              status: 1
            }
          })
          
          i18nParams = i18nParams.concat(this.beforeEditLanguageList.filter(item => !i18nParams.find(it => it.language == item.language)).map(item => ({
            language: item.language,
            name: item.name,
            description: item.description,
            status: 0
          })))
          
          data.i18n = convertArray(i18nParams)
          

          edit(data).then((res) => {

            if (res.data2.code !== 200) {
              if (res.data2.code !== 202) { //添加的人没权限不弹窗
                this.content = res.data2.msg;
                this.visible.dialogVisible = true;
              }

            } else {//修改成功
              this.$router.push("/JournalLibrary");
            }
          });
        }
      });
    },
    dialogClose() {
      this.visible.dialogVisible = false;
      this.$router.push({ path: "/JournalLibrary" });
    },
    dateClick() {
      // 失焦事件
      this.$refs.name.blur();
      this.$refs.position.blur();
      this.$refs.description.blur();
    },
    selectChange(val) {
      this.ruleForm.target.input = "";
    },
    oploadImgDel() {
      this.ruleForm.cover = "";
      let _this = this;
      // _this.uploadBoolean = true;
      setTimeout(function () {
        _this.uploadBoolean = false;
      }, 500);
    },
    managementChange(val) {
      this.getManagement();
    },
    getManagement() {
      let params = {
        management: this.ruleForm.management.length ? this.ruleForm.management[this.ruleForm.management.length - 1] : this.ruleForm.management,
      };
      getDepartmentByManagement(params).then((res) => {
        this.ruleForm.department = res.data.value;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/cascader.scss";

.el-input,
.el-textarea {
  width: 400px;
  border-radius: 3px;
  border-color: #dcdcdc;
}

.el-input {
  height: 32px;
}

::v-deep .el-date-editor.el-input {
  height: 32px;
  width: 400px;
}

::v-deep .el-form-item__label {
  padding: 0 24px 0 0;
}

::v-deep .el-textarea .el-textarea__inner {
  resize: none;
  height: 76px;
}

::v-deep .el-cascader .el-input {
  height: 32px;
  width: 400px;
  border-radius: 3px;
  border-color: #dcdcdc;
}

::v-deep .el-select .el-input__wrapper {
  height: 32px;
  width: 400px;
  border-radius: 3px;
  border-color: #dcdcdc;
}

.main {
  margin: 0 auto;
  overflow: hidden;
  // width: 960px;
  background: #f8f8fa;
  padding: 16px 116px 0;
}

.ml210 {
  margin-left: 210px;
}

.selecper {
  margin-top: 7px;
}

@media screen and (min-width: 960px) {
  .main {
    // width: 960px;
  }
}

@media screen and (min-width: 1200px) {
  .main {
    // width: 960px;
  }
}

.content {
  width: 728px;
  background: #ffffff;
  border-radius: 10px;
  padding: 24px 20px 24px 16px;
  margin: 0 auto;

  .title {
    height: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .fanhuiimg {
      height: 16px;
      width: 16px;
      // border: 1px dashed rgba(0, 0, 0, 0.3);
      cursor: pointer;
    }

    .span-t1 {
      width: 64px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2f3238;
      line-height: 22px;
      margin-left: 10px;
    }

    .person {
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #999999;
      line-height: 17px;
    }
  }

  .w192 {
    width: 192px;
    margin-right: 16px;
  }

  .btn {
    width: 88px;
    height: 32px;
    background: #286cfb;
    border-radius: 4px;
    margin: 22px auto 0;
    border: none;
  }

  ::v-deep .remark .el-textarea__inner {
    height: 120px;
  }

  ::v-deep .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  ::v-deep .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  ::v-deep .avatar-uploader-icon {
    font-size: 14px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  ::v-deep .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
}

// ::v-deep.el-radio__input.is-checked .el-radio__inner::after {
//   transform: translate(-50%, -50%) scale(1);
//   background-color: #409eff;
// }
::v-deep.el-input-number .el-input__inner {
  text-align: left;
}

//鼠标经过下拉框
::v-deep.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: rgba(40, 108, 251, 0.1);
  border-radius: 3px;
  font-weight: 400;
  color: #286cfb;
}

.el-upload-list__item-actions:hover {
  opacity: 1;
}

.el-upload-list__item-actions {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
  text-align: center;
  color: #fff;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
}

.el-upload-div {

  // width: 54px;
  // height: 18px;
  // background: rgba(255, 255, 255, 0.2);
  // border: 1px solid rgba(255, 255, 255, 0.1);
  // backdrop-filter: blur(2px);
  // margin: 35px auto 0;
  .el-upload-span {
    display: inline-block;
    width: 54px;
    height: 18px;
    line-height: 18px;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(2px);
    margin: 35px auto 0;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    font-size: 10px;
    cursor: pointer;
  }
}
</style>
<style>
.el-cascader-node:not(.is-disabled):focus,
.el-cascader-node:not(.is-disabled):hover {
  background: #186cea !important;
  background: rgba(40, 108, 251, 0.1) !important;
  border-radius: 3px !important;
  font-weight: 400 !important;
  color: #286cfb !important;
}

.el-cascader-node.in-active-path,
.el-cascader-node.is-active,
.el-cascader-node.is-selectable.in-checked-path {
  color: #286cfb !important;
  font-weight: 400 !important;
}
</style>
