<template>
  <div
    v-if="list.length > 0"
    class="wraper"
    style="width: 100%; height: 100%; overflow: hidden"
  >
    <!-- <p class="select-box-title" v-show="type !== 0">
      已选择{{ list.length }}人<span class="delall" @click="del(-1)"
        >一键清除</span
      >
    </p> -->
    <div class="avator-box" v-for="(item, index) in list" :key="index">
      <img v-if="item.headImg" :src="item.headImg" alt="" />
      <el-avatar v-else> {{ item.username.slice(-1) }} </el-avatar>
      <p class="name">{{ item.name ? item.name : item.orgName }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.select-box-title {
  font-size: 14px;
  color: #60626c;
  line-height: 20px;
  padding-bottom: 24px;
}
.delall {
  width: 48px;
  height: 17px;
  font-size: 12px;
  color: #286cfb;
  line-height: 17px;
  margin-left: 16px;
  cursor: pointer;
}
.avator-box {
  float: left;
  width: 14%;
  position: relative;
  margin-bottom: 10px;
  margin-right: 2.6%;
  cursor: pointer;
  ::v-deep(.el-avatar) {
    margin: 0 auto;
    display: block;
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin: 0 auto;
    display: block;
  }
  .del {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.name {
  // width: 36px;
  height: 17px;
  font-size: 12px;
  color: #1f2227;
  line-height: 17px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  margin-top: 5px;
}
.wraper {
  padding: 20px 16px 0;
}
</style>
