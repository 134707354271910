<template>
  <div class="main">
    <div class="guanli" v-loading="loading">
      <HelpCenter />
      <div class="title">
        <div class="guanli-icon"></div>
        <div class="guanli-ziti">最近使用</div>
      </div>
      <div class="label">
        <div class="label-item" style="margin-left: 21px" @click="journalsindex" v-if="approvalJournal === 1">
          <img class="item-img" src="../../assets/icons/pcindex/33@2x.png" />
          <div class="spans">
            <span class="span-t1">期刊审核</span>
            <span class="span-t2">审核已提交的期刊创建 及修改申请</span>
          </div>
        </div>

        <div class="label-item" style="margin-left: 21px" @click="goJournalLibrary" v-if="journalLibrary == 1">
          <img class="item-img" src="../../assets/icons/pcindex/Journal2x.png" />
          <div class="spans">
            <span class="span-t1">期刊库</span>
            <span class="span-t2">对平台所有期刊进行管理</span>
          </div>
        </div>
        <!-- <div class="label-item" style="margin-left: 21px" @click="goJournalList" v-if="journalLibrary === 1">
          <img class="item-img" src="../../assets/icons/pcindex/list@2x.png" />
          <div class="spans">
            <span class="span-t1">期刊榜单</span>
            <span class="span-t2">期刊榜单生成及管理</span>
          </div>
        </div> -->
      </div>
      <div class="title" v-if="globalAdmin === 1">
        <div class="guanli-icon"></div>
        <div class="guanli-ziti">系统管理</div>
      </div>
      <div class="label">

        <div class="label-item" style="margin-left: 21px" @click="administrator" v-if="globalAdmin === 1">
          <img class="item-img" src="../../assets/icons/pcindex/user2x.png" />
          <div class="spans">
            <span class="span-t1">管理员配置</span>
            <span class="span-t2">可分配管理员权限，对设置的阵地进行管理</span>
          </div>
        </div>
      </div>
      <el-button type="primary" @click="toTest">echarts 测试入口</el-button>
      <el-button type="primary" @click="toWarning">预警审核 测试入口</el-button>

    </div>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import { ref } from "vue";
import { queryUserPermission } from "@/api/administratorConfiguration";
import { ElMessage } from "element-plus";
import HelpCenter from '@/components/HelpCenter.vue';
export default {
  components: { HelpCenter },
  data() {
    return {
      active: 0, // 悬停区域原始颜色
      background: "",
    };
  },
  setup() {
    const router = useRouter();
    const zdspIndex = () => {
      router.push("zdspIndex");
    };
    const bakindex = () => {
      router.push("bakindex");
    };
    const cmckindex = () => {
      router.push("cmckindex");
    };
    const goJournalLibrary = () => {
      router.push("JournalLibrary");
    };
    const zdmanage = () => {
      router.push("zdmanage");
    };
    const journalsindex = () => {
      router.push("journalsindex");
    };
    const administrator = () => {

      router.push("administratorConfiguration");
    };
    const goJournalList = () => {
      router.push("journalList");
    }

    const toTest = ()=>{
      router.push('EchartsTest')
    }

    const toWarning = ()=>{
      router.push('WarningReview')
    }
    const journalLibrary = ref(0); //期刊库
    const approvalJournal = ref(0); //审批
    const globalAdmin = ref(0); //管理员配置
    const loading = ref(false);
    const getUserPermission = () => {
      loading.value = true;
      queryUserPermission().then((res) => {
        journalLibrary.value = res.data.journalLibrary || 0;
        approvalJournal.value = res.data.approvalJournal || 0;
        globalAdmin.value = res.data.globalAdmin || 0;
        // globalAdmin.value  = 1
        if (journalLibrary.value == 0 && globalAdmin.value == 0 && approvalJournal.value === 0) {
          ElMessage({
            message: "您非管理员身份，可联系期刊业务主添加管理员身份后进入",
            type: "error",
          });
        }
        loading.value = false;
      });
    };
    setTimeout(() => {
      getUserPermission();
    }, 1000);
    return {
      bakindex,
      zdspIndex,
      cmckindex,
      zdmanage,
      journalsindex,
      journalLibrary,
      goJournalLibrary,
      administrator,
      getUserPermission,
      goJournalList,
      approvalJournal,
      globalAdmin,
      loading,
      toTest,
      toWarning
    };
  },
  methods: {
    // 鼠标"悬停"触发此方法
    Mouseover(num) {
      this.active = num; //
      this.background = "cursor:pointer;background-color:#F6F7FA"; // 进入 悬停区域,更改颜色
    },
    // 鼠标"离开"触发此方法
    Mouseleave() {
      this.active = ""; // 离开 悬停区域,文字颜色复原
    },
  },
};
</script>

<style lang="scss" scoped>
.mouseover {
  background-color: #f6f7fa;
}

@media screen and (min-width: 960px) {
  .main {
    width: 928px;
  }
}

@media screen and (min-width: 1200px) {
  .main {
    width: 928px;
  }
}

.main {
  margin: 0 auto;
}

.guanli {
  height: 100vh;
  background: #ffffff;
  border-radius: 10px;
  margin: 16px;
  width: 100%;

  .title {
    height: 22px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-top: 24px;
    padding-bottom: 8px;

    .guanli-icon {
      width: 4px;
      height: 14px;
      background: #286cfb;
      border-radius: 3px;
      margin-left: 3px;
      margin-right: 9px;
    }

    .guanli-ziti {
      width: 80px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2f3238;
      line-height: 22px;
    }
  }

  .label {
    width: 100%;

    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-right: 20px;

    .label-item {
      cursor: pointer;
      margin-left: 0px;
      margin-top: 8px;
      width: 210px;
      height: 82.5px;
      line-height: 82.5px;
      border-radius: 8px;
      display: flex;

      .item-img {
        margin-left: 16px;
        margin-top: 12px;
        width: 44px;
        height: 44px;
      }

      .spans {
        margin-left: 14px;
        margin-top: 12px;
        width: 120px;
        height: 44px;

        .span-t1 {
          margin-top: 3px;
          width: 120px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2f3238;
          line-height: 20px;
          display: block;
        }

        .span-t2 {
          margin-top: 3px;
          width: 120px;
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
          display: block;
        }
      }
    }

    .label-item:hover {
      background: #f6f7fa;
      border-radius: 8px;
    }
  }
}
</style>
