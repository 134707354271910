import {createStore} from 'vuex'

const store = createStore({
    state() {
        return {
            isAdmin: 0,
            login:false,
            tag:0,
            tagcate:0,
        }
    },
    getters() {
    },
    mutations: {

        isAdmin(state,payload){
            state.isAdmin = payload
        },
        setlogin(state,payload){
            state.login = payload
        },
        tag(state,payload){
            state.tag = payload
        },
        tagcate(state,payload){
            state.tagcate = payload
        },
    }
})
export default store