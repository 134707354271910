<template>
<div class="warning-com-wrap">
    <div class="top">
        <div class="title">错敏词预警</div>
    </div>
    <div class="com-content">
        <div class="com-block left">
            <div class="com-title">预警次数</div>
            <div class="data-show">
                <div class="item">
                    <div class="text">今日预警次数</div>
                    <div class="num num-red">{{yjcs.todayAlertTimes}}</div>
                </div>
                <div class="item">
                    <div class="text">累计预警次数</div>
                    <div class="num">{{yjcs.totalAlertTimes}}</div>
                </div>
            </div>
            <div class="com-list scroll-bar-cus">
                <div class="list-item" v-for="(item, index) in yjcsData" :key="`yjcs-${index}`">
                    <div class="label">
                        <!-- {{item.platformName}} -->
                        <el-tooltip
                            v-if="item.platformName.length>6"
                            class="box-item"
                            effect="dark"
                            :content="item.platformName"
                            placement="bottom"
                        >
                            <div class="text-overflow">{{item.platformName}} </div>
                        </el-tooltip>
                        <div v-else class="text-overflow">{{item.platformName}} </div>
                    </div>
                    <div class="bar" :style="{width: item.width+'px'}"></div>
                    <div class="num">{{item.alertTimes}}</div>
                </div>
            </div>
        </div>
        <div class="com-block right">
            <div class="com-title"><span>预警类别</span><div class="more" @click="goWarning">查看更多</div></div>
            <div class="data-show">
                <div class="item route-click" @click="goWarning">
                    <div class="text">
                        <span>触发错词</span></div>
                    <div class="num">
                        <span>{{yjlb.errorWordCount}}</span></div>
                </div>
                <div class="item route-click" @click="goWarning">
                    <div class="text">
                        <span>触发禁用词</span></div>
                    <div class="num">
                        <span>{{yjlb.sensitiveWordCount}}</span>
                    </div>
                </div>
            </div>
            <div class="com-list">
                <div class="header">
                    <div class="yj-index">序号</div>
                    <div class="yj-account">账号名称</div>
                    <div class="yj-type">预警类别</div>
                    <div class="yj-word">预警词</div>
                    <div class="yj-zhendi">阵地类型</div>
                    <div class="yj-time">预警时间</div>
                </div>
                <!--  -->
                <div class="move-wrap">
                <div class="scroll-wrapper" ref="moveWrapRef" @mouseenter="commentEnter" @mouseleave="commentLeave">
                        <div class="yj-item" v-for="(item, index) in yjlbData" :key="`yj-item-${item.id}`">
                            <div class="yj-index">{{item.rank}}</div>
                            <div class="yj-account">                         
                                <el-tooltip
                                    v-if="item.platformName.length>11"
                                    class="box-item"
                                    effect="dark"
                                    :content="item.platformName"
                                    placement="bottom"
                                >
                                    <div class="text-overflow">{{item.platformName}} </div>
                                </el-tooltip>
                                <div v-else class="text-overflow">{{item.platformName}} </div>
                            </div>
                            <div class="yj-type">{{WARNINGTYPES.filter(ele=>ele.value==item.alertType)[0].label}}</div>
                            <div class="yj-word">
                                <!-- {{jtem.errorWord}} -->
                                <el-tooltip
                                    v-if="item.errorWord.length>9"
                                    class="box-item"
                                    effect="dark"
                                    :content="item.errorWord"
                                    placement="bottom"
                                >
                                    <div class="text-overflow">{{item.errorWord}} </div>
                                </el-tooltip>
                                <div v-else class="text-overflow">{{item.errorWord}} </div>
                            </div>
                            <div class="yj-zhendi">{{POSITIONTYPES.filter(ele=>ele.value==item.platformType)[0].label}}</div>
                            <div class="yj-time">{{item.createdAt}}</div>
                        </div>
                    </div>
               
                </div>
                </div>
        </div>
    </div>
</div>
</template>

<script scope setup>
import { ref, reactive, watch, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { warningWordsList } from '@/api/home.js'
import { warningList } from '@/api/warning.js'
import { POSITIONTYPES, WARNINGTYPES } from '../.././warningReview/options.js'
import { ElMessage } from 'element-plus'

const props = defineProps({
    reload: {
        type: Boolean
    }
})

const router = useRouter()
//预警次数数据类别
const yjcsData = ref([])
const yjcs = reactive({
    todayAlertTimes: 0,
    totalAlertTimes: 0
})
//预警类别数据类别
const yjlbData = ref([])
const yjlb = reactive({
    errorWordCount: 0,
    sensitiveWordCount: 0
})
const getWordsList = ()=>{
    warningWordsList()
        .then(res=>{
            const { alertRankingList, todayAlertTimes, totalAlertTimes } = res.data
            yjcsData.value = []
            yjcs.totalAlertTimes = totalAlertTimes
            yjcs.todayAlertTimes = todayAlertTimes
            // console.log('max:', max)
            console.log(9999, alertRankingList)
            if(alertRankingList.length){
                alertRankingList.sort((f, s)=>-f.alertTimes + s.alertTimes)
                // 找到最大值作为宽度100的基准
                const widthBase = alertRankingList[0].alertTimes
                alertRankingList.forEach(element => {
                    element.width = (element.alertTimes / widthBase * 220).toFixed(0)
                });
                yjcsData.value = alertRankingList
            }
        }).catch(err=>{
            console.log('获取预警次数列表出错：', err)
        })
}

getWordsList()

const getWarningList = ()=>{
    warningList({
        current: 1,
        size: 20,
        sort: 1
    }).then(res=>{
        const { errorWordCount, sensitiveWordCount, page } = res.data
        yjlb.sensitiveWordCount = sensitiveWordCount
        yjlb.errorWordCount = errorWordCount
        page.records.forEach((item, index)=>{
            item.rank = index+1
        })
        yjlbData.value = page.records

        nextTick(() => {
            let height = moveWrapRef.value.offsetHeight
            if (height > 200) {
                yjlbData.value = [...yjlbData.value,...yjlbData.value]
            }
            cancelAnimationFrame(requestAction);
            movePx = 0
            moveFn(movePx)
        })
        
    }).catch(err=>{
        console.log('获取预警类别列表出错：', err)
    })
}

getWarningList()

const goWarning = ()=>{
    const alertPermission = sessionStorage.getItem('alertPermission')
    console.log('alertPermission：', alertPermission)
    if(alertPermission == 1) {
        router.push('/WarningReview')
    }else{
        ElMessage.error('暂无预警审核页面权限，请联系管理员')
    }
}

watch(()=>props.reload, val=>{
    if(val) {
        getWordsList()
        getWarningList()
    }
})

const moveWrapRef = ref(null)

let requestAction = null
let movePx = 0
const moveFn=(dis)=>{
      let height = moveWrapRef.value.offsetHeight
      if (height <= 200) return 
      if(height && dis >= (height/2)){
        movePx = 0
      }
      requestAction = requestAnimationFrame(() => {
        moveWrapRef.value.style=`transform: translateY(${-dis}px)`
        movePx += 0.5
        moveFn(movePx);
      }); 
    }

const commentEnter = ()=> {
    cancelAnimationFrame(requestAction);
}
const commentLeave = ()=> {
    moveFn(movePx)
}
</script>

<style lang="less">
.warning-com-wrap{
    width: 1248px;
    height: 460px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    .top {
        padding-left: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 56px;
        border-bottom: 1px solid #EBEBEB;
    }
    .title{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
    }
    .com-content{
        display: flex;
        align-items: center;
        background: #fff;
        .com-block.left{
            width: 406px;
        }
        .com-block.right{
            width: 842px;
            border: none;
        }
        .com-block{
            padding: 0 16px 16px;
            box-sizing:border-box;
            border-right: 1px solid #EBEBEB;
        }
        .com-title{
            height: 54px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: rgba(0,0,0,0.85);
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .more{
            width: 76px;
            height: 20px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #0073E5;
            background: #fff url(../../../assets/icons/icon-arrow-right.png) right center/16px 16px no-repeat;
            cursor: pointer;
        }
        .data-show{
            width: 100%;
            height: 81px;
            background: #F7F7F7;
            display: flex;
            align-items: center;
        }
        .data-show .item{
            width: 50%;
            height: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .data-show .item:first-child{
            border-right: 1px solid #EBEBEB;
        }
        .data-show .item .text{
            height: 12px;
            color: #8F8F8F;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
        }
        .data-show .item .num{
            margin-top: 12px;
            color: #252525;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
        }
        .data-show .item .num.num-red{
            color: #EC5D56;
        }
        .route-click span{
            // display: flex;
            cursor: pointer;
        }
    }
    .com-list{
        position: relative;
        margin-top: 10px;
        padding-top: 7px;
        height: 240px;
        overflow: hidden;
        background: #fff;
    }
    .com-list.scroll-bar-cus:hover{
        overflow-y: auto;
    }
    .move-wrap{
        height: 200px;
        overflow: hidden;
    }
    .list-item{
        padding: 7px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .label{
            width: 91px;
            height: 20px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: rgba(0,0,0,0.85);
            line-height: 16px;
            text-align: right;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
        }
        .bar{
            margin-left: 8px;
            margin-right: 12px;
            height: 8px;
            background: linear-gradient( 90deg, #0073E5 0%, #83C1FF 100%);
            border-radius: 4px 4px 4px 4px;
        }
        .num{
            height: 20px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: rgba(0,0,0,0.85);
            line-height: 16px;
        }
    }
    .com-list .header, .com-list .yj-item{
        width: 100%;
        padding-left: 8px;
        box-sizing: border-box;
        display: flex;
        .yj-index{
            margin-right: 16px;
            width: 60px;
            box-sizing: border-box;
            // background: blue;
            text-align: center;
        }
        .yj-account{
            padding-right: 16px;
            width: 207px;
            box-sizing: border-box;
        }
        .yj-type{
            width: 110px;
        }
        .yj-word{
            margin-right: 16px;
            width: 126px;
        }
        .yj-zhendi{
            width:98px;
        }
        .yj-time{
            width: 150px;
        }
    }
    .com-list .header{
        margin-bottom: 0;
        position: relative;
        z-index: 1;
        height: 32px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: rgba(0,0,0,0.45);
        line-height: 32px;
        background: #F5F5F5;
        justify-content: flex-start;
    }
    .com-list .yj-item{
        height: 40px;
        color: #262626;
        line-height: 40px;
        background: #FFFFFF;
        border-bottom: 1px solid #F2F2F2;
    }

    .text-overflow{
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: default;
    }
    }

</style>