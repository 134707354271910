<template>
  <div class="main">
    <div class="search">
      <a-input
        class="search-i"
        v-model:value="expression"
        style="margin-right :24px"
        placeholder="请输入错敏词"
      ></a-input>
      <el-button class="sbutton" @click="refreshData(search)">查询</el-button>
      <a-button class="abutton" @click="dialogFormVisible=true">新建词条</a-button>
    </div>
    <div style="border: 1px solid #EBEBEB; width: 862px">
      <el-table :data="records" style="width: 862px">
        <el-table-column type="index" label="序号" width="100px" />
        <el-table-column
          prop="correctExpression"
          label="正确表达"
          min-width="15%"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="errorExpression"
          label="错敏词"
          min-width="20%"
          :show-overflow-tooltip="true"
        />

        <el-table-column fixed="right" label="操作" width="150px">
          <template v-slot:default="scope">
            <el-button link type="primary" @click="edit(scope.row.id)" class="tableOp">编辑</el-button>
            <el-button link type="primary" @click="deleteItem(scope.row.id)" class="tableOp">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <div class="page-wrapper">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20, 50, 100]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>

    <el-dialog v-model="dialogFormVisible" title="新建词条" destroy-on-close @close="cancel">
      <a-form
        class="bhnr"
        :model="errorWord"
        name="normal_login"
        @finish="sumbit(errorWord)"
        autocomplete="off"
      >
        <a-form-item
          style="margin-left: 20px;"
          class="item-i"
          name="errorExpression"
          :rules="[{ required: true, message: '请输入错敏词!' }]"
        >
          <span class="dialog-span">输入错敏词</span>
          <a-input class="input-t" placeholder="输入错敏词" v-model:value="errorWord.errorExpression" />
        </a-form-item>
        <a-form-item
          style="margin-left: 20px;"
          class="item-i"
          name="correctExpression"
          :rules="[{ required: true, message: '请输入正确表达!' }]"
        >
          <span class="dialog-span">输入正确表达</span>
          <a-input
            class="input-t"
            style=" margin-bottom: 10px;"
            placeholder="输入正确表达"
            v-model:value="errorWord.correctExpression"
          />
        </a-form-item>

        <a-form-item class="footer">
          <a-button @click="cancel" style="width: 88px;height: 32px; margin-top:11px;  ">取消</a-button>
          <a-button
            type="primary"
            style="background: #286CFB;width: 88px;height: 32px;margin-top:11px;margin-left:12px;"
            html-type="submit"
          >保存</a-button>
        </a-form-item>
      </a-form>
    </el-dialog>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import myAxios from "@/api/request";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  props: {
    type: Number,
    list: {
      type: Object
    }
  },
  setup() {
    const router = useRouter();
    const detail = _item => {
      console.log("这是item" + _item);
      router.push("/zdDeatail/" + _item.id);
    };
    return {
      detail
    };
  },
  watch: {
    list(newVal) {
      this.records = newVal.records;
      this.currentPage = newVal.current;
      this.size = newVal.size;
      this.total = newVal.total;
    }
  },
  data() {
    return {
      dialogFormVisible: false,
      current: 1,
      size: 20,
      cate: 0,
      expression: "",
      total: 0,
      records: [],
      currentPage: 1,
      errorWord: {
        cate: 0,
        correctExpression: "",
        errorExpression: "",
        id: 0
      }
    };
  },
  methods: {
    cancel() {
      this.dialogFormVisible = false;
      this.errorWord = {
        cate: 0,
        correctExpression: "",
        errorExpression: "",
        id: 0
      };
    },
    sumbit() {
      let method = "post";
      if (this.errorWord.id != 0) {
        method = "put";
      }
      (this.errorWord.cate = this.type),
        myAxios({
          url: "/errorWord",
          data: this.errorWord,
          method: method
        }).then(res => {
          if (res.data2.code == 200) {
            message.success(res.data2.msg);
            this.dialogFormVisible = false;
            this.refreshData();
          } else {
            ElMessage.error(res.data2.msg);
          }
        });
      this.errorWord = {
        cate: 0,
        correctExpression: "",
        errorExpression: "",
        id: 0
      };
    },
    refreshData() {
      this.$emit("refreshData", {
        current: this.current,
        size: this.size,
        //status:this.status,
        expression: this.expression
        // positionCate:this.positionCate,
      });
    },
    handleSizeChange(size) {
      this.size = size;
      this.refreshData();
    },
    handleCurrentChange(current) {
      this.current = current;
      this.refreshData();
    },
    async edit(item) {
      const res = await myAxios({
        url: "/errorWord/" + item,
        method: "GET"
      });
      console.log("res", res);
      this.errorWord = res.data;
      this.dialogFormVisible = true;
    },

    deleteItem(item) {
      ElMessageBox.confirm("确认删除么?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          myAxios({
            url: "/errorWord",
            params: { id: item },
            method: "DELETE"
          }).then(res => {
            message.success("删除成功");
            this.refreshData();
          });
        })
        .catch(() => {
          message.info("已取消删除");
        });
    }
  }
};
</script>

<style  scoped>
@media screen and (min-width: 960px) {
  .main {
    width: 862px;
  }
}

@media screen and (min-width: 1200px) {
  .main {
    width: 862px;
  }
}
.main {
  margin: 0 auto;
}

.search {
  height: 32px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.search-i {
  width: 240px;
  height: 32px;
  border-radius: 3px;
}
:deep(.el-dialog) {
  height: 300px;
  --el-dialog-width: 440px;
}
:deep(.el-button.is-link:focus, .el-button.is-link:hover) {
  color: var(--el-color-primary);
}
:deep(.el-dialog__header) {
  width: 440px;
  height: 48px;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
}

.footer {
  width: 440px;
  height: 50px;
  padding: var(--el-dialog-padding-primary);
  padding-top: 0px;
  text-align: right;
  box-sizing: border-box;
  border-top: 1px solid #d9d9d9;
}
:deep(.el-dialog__body) {
  height: 0px;
  padding: 0px;
}
:deep(.el-dialog__title) {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #262626;
  line-height: 24px;
}
.bhnr {
  margin-top: 20px;
  height: 300px;
}
:deep (.ant-form-item-explain) {
  height: 0px;
  min-height: 0px;
  opacity: 1;
}
.dialog-span {
  width: 98px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #262626;
  line-height: 20px;
}
.input-t {
  width: 400px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.sbutton {
  width: 60px;
  height: 32px;
  background: #286cfb;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  line-height: 22px;
}
.abutton {
  margin-left: 450px;
  width: 88px;
  height: 32px;
  background: #286cfb;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  line-height: 22px;
}
.span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
}

.page-wrapper {
  height: 80px;
  background: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 80px;
}
.tableOp {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #286cfb;
  line-height: 22px;
}
.tableOp:focus-visible {
  outline: none;
}
</style>