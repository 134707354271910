<template>
  <div style="width: 100%;height: 100%;overflow: hidden;">
    <div class="select-box-title" v-show="showType !== 0">
      <span> 已选择 <template v-if="type.includes('1')">{{ chosenPersonArray.length }} 人</template>
        <template v-if="type.includes('2')">{{ chosenDeptArray.length }} 组织</template>
        <template v-if="type.includes('3')">{{ chosenGroupArray.length }} 用户组</template>
      </span>
      <span class="delall" @click="del(-1)">一键清除</span>
    </div>
    <div class="avator-box" v-for="(item, index) in list" :key="index">
      <img v-if="item.avator || item.headImg" class="icon" :src="item.avator || item.headImg" alt="" />
      <!-- <el-avatar v-else> {{item.username?.slice(-1)}} </el-avatar> -->
      <img v-else-if="item.type == 1" src="../assets/img/person-default.png" class="icon" alt="">
      <img v-else-if="item.type == 2" src="../assets/img/dept-default.png" class="icon" alt="">
      <img v-else-if="item.type == 3" src="../assets/img/group-default.png" class="icon" alt="">
      <p class="name" :title="item.type == 1 ? item.name : item.type == 2  ? item.orgName : item.orgName">{{
        item.type == 1 ? item.name : item.type == 2  ? item.orgName : item.orgName
      }}</p>
      <el-icon class="del" :size="16" color="#999" v-if="showType !==0 && !item.disableDelete" @click="del(index,item)">
        <CircleCloseFilled />
      </el-icon>
    </div>
    <div class="avator-box" @click="selectPer" v-show="showType !== 0">
      <img src="../assets/img/icon_image_add.png" alt="" class="icon" />
      <p class="name">添加</p>
    </div>
  </div>
</template>
<script setup>
import chooseByIdm from 'choose-contacts/dist/choose-contacts.js'
import { computed, ref, watch,defineEmits } from "vue";
import { ElMessage, ElMessageBox, FormRules } from 'element-plus'

const props = defineProps({
  title: {
    type: String,
    default: "选择人员",
  },
  type: {
    type: Array,
    default: () => [1],
  },
  chosenList: {
    type: Array,
    default: () => [],
  },
  maxPersonNum: {
    type: Number,
    default: 200,
  },
  maxOrgNum: {
    type: Number,
    default: 20,
  },
  maxGroupNum: {
    type: Number,
    default: 20,
  },
  showType: {
    type: Number,
    default: 1,
  },
});
const emit = defineEmits(['update:chosenList']);

const chosenPersonArray = ref( props.chosenList.filter((v) => v.type == 1));
const chosenDeptArray = ref( props.chosenList.filter((v) => v.type == 2));
const chosenGroupArray = ref( props.chosenList.filter((v) => v.type == 3));

const list = computed(() => {
  return chosenPersonArray.value.concat(chosenDeptArray.value).concat(chosenGroupArray.value);
});

watch(
  () => props.chosenList,
  () => {
    chosenPersonArray.value = props.chosenList.filter((v) => v.type == 1)
    chosenDeptArray.value = props.chosenList.filter((v) => v.type == 2)
    chosenGroupArray.value = props.chosenList.filter((v) => v.type == 3)
  }
);

/*
 * 删除选中人 index删除的下标
 */
const del = (index, item) => {
  if (index == -1) {
    ElMessageBox.alert("确定要清除所有已选用户吗？", "提示", {
      confirmButtonText: "确定",
      callback: (action) => {
        if (action === "confirm") {
          chosenPersonArray.value = [];
          chosenDeptArray.value = [];
          chosenGroupArray.value = [];
          emit('update:chosenList', []);
        }
      }
    })

  } else {
    if(item.type == 1) {
      chosenPersonArray.value = chosenPersonArray.value.filter((v, i) => v.username != item.username);
    } else if(item.type == 2) {
      chosenDeptArray.value = chosenDeptArray.value.filter((v, i) => v.ihaierId != item.ihaierId);
    } else if(item.type == 3) {
      chosenGroupArray.value = chosenGroupArray.value.filter((v, i) => v.id != item.id);
    }
    // console.log("🚀 ~ del ~ list.value:", list.value.length)

    emit('update:chosenList', list.value);
  }

};
 
const selectPer = () => {
console.log(1111221)
  chooseByIdm({
    "title": props.title,
    "token" : window.sessionStorage.getItem('haibianToken'),
    "chosenPersonArray": chosenPersonArray.value.map( v => ({
      jobNumber: v.username,
      username: v.name,
      avator: v.headImg
    })),
    "chosenDeptArray": chosenDeptArray.value.map( v => ({
      orgId: v.ihaierId,
      orgName: v.orgName
    })),
    "chosenGroupArray": chosenGroupArray.value.map( v => ({
      groupId: v.id,
      groupName: v.orgName
    })),
    "type": props.type,
    "maxPersonNum": props.maxPersonNum,
    "maxOrgNum": props.maxOrgNum,
    "maxGroupNum": props.maxGroupNum,
    "jobNumber": window.localStorage.getItem('userInfoForChoose') ? JSON.parse(window.localStorage.getItem('userInfoForChoose')).userName : '',
  }).then( (res) => {
    console.log('getSelected', res);
    chosenPersonArray.value = res.chosenPersonList.map( v => ({
      username: v.jobNumber,
      name: v.username,
      headImg: v.avator,
      type: 1
    })
    )
    chosenDeptArray.value = res.chosenDeptList.map( v => ({
      ihaierId: v.orgCode,
      orgName: v.orgName,
      avator: v.avator,
      type: 2
    })
    )
    chosenGroupArray.value = res.chosenGroupList.map( v => ({
      id: v.groupId,
      orgName: v.groupName,
      headImg: v.headImg,
      type: 3
    })
    )
    emit('update:chosenList', list.value);
  })
};
</script>
<style lang="scss" scoped>
.select-box-title {
  font-size: 14px;
  color: #60626c;
  line-height: 20px;
  padding-bottom: 24px;
}

.delall {
  width: 48px;
  height: 17px;
  font-size: 12px;
  color: #286cfb;
  line-height: 17px;
  margin-left: 16px;
  cursor: pointer;
}

.avator-box {
  float: left;
  width: 50px;
  position: relative;
  margin-bottom: 24px;
  margin-right: 20px;
  cursor: pointer;

  ::v-deep(.el-avatar) {
    margin: 0 auto;
    display: block;
  }

  .icon {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin: 0 auto;
    display: block;
  }

  .del {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.name {
  // width: 36px;
  // height: 17px;
  font-size: 12px;
  color: #1f2227;
  line-height: 17px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  margin-top: 5px;
}
</style>
