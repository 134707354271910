<template>
  <el-config-provider>

    <router-view v-if="islogin"></router-view>
  </el-config-provider>
</template>

<script>
import { isPc, isIHaier, isHb, isInWeb } from './utils/platform';
import { useStore } from "vuex";
import { computed } from "vue";
import { getToken } from './utils/Ihaier.js';

import 'choose-contacts/dist/style.css'
const dcm = window.dcm;

export default {

  mounted() {
    document.body.style.setProperty("--el-color-primary", "#286cfb");
    if (isIHaier) {
      getToken()
    } else {
      dcm.init({
        appId: window.origin.includes("-test")
          ? "lji-XcK1hOqXfosZMbe_3"
          : "d-n7cnJQVFkWse5iR1dn0",
        // appId: "N6-PAiMZCxb48imOGahCW", // 我的应用
      });
    }


  },

  data() {
    const store = useStore();
    const islogin = computed(() => store.state.login);
    return {
      islogin: islogin
    };
  },
};
</script>

<style>
/* @import url("./assets/css/common.css"); */
@import "./assets/css/common.css";
</style>
