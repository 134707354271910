<!-- 折线图 -->
<template>
<div id="main_line" ref="target"></div>
</template>

<script scope setup>
import { onMounted, watch, ref } from 'vue'
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useElementVisibility } from '@vueuse/core'

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  DataZoomComponent
]);

const props = defineProps({
    // 图例
    legend: {
        required: false,
        type: Array,
        default: ()=>([])
    },
    // 横轴
    xAxisData: {
        type: Array,
        default: ()=>([])
    },
    // 折线的seriesData
    seriesData: {
        type: Array,
        default: ()=>([])
    },
    title: String
})

var chartDom = null
var myChart = null

let needRender = false

const render = ()=>{
    chartDom = document.getElementById('main_line');
    if(!myChart){
        myChart = echarts.init(chartDom);
    }

    // console.log('props.seriesdata:', props.seriesData, props.xAxisData)
    var option;

    option = {
        title: {
            text: props.title
        },
        tooltip: {
            trigger: 'axis',
            formatter: function (params){
                // console.log(params[0])
                // return `${params[0].data.label} <br />${params[0].marker}  ${params[0].data.value}`
                return `${params[0].marker} ${params[0].data.label}  ${params[0].data.value}`
            }
        },
        legend: {
            data: props.legend,
            bottom: '0%'
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '8%',
            containLabel: true
        },
        toolbox: {
            // feature: {
            // saveAsImage: {}
            // }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: props.xAxisData
        },
        yAxis: {
            type: 'value',
            minInterval: 1
        },
        series: props.seriesData,
    };

    // console.log('before render')
    option && myChart.setOption(option);
}

//查看更多按钮
const target = ref(null)
const targetIsVisible = useElementVisibility(target)
watch(targetIsVisible , newVal =>{
    // console.log(888,targetIsVisible,newVal, needRender)
	if(newVal && needRender){
		render()
	}
})
// 窗口改变
window.addEventListener('resize', function(){
    // console.log('resize')
    myChart&&myChart.resize()
})
// 数据改变
watch(()=>props.seriesData, val=>{
    // console.log('折线数据改变:', val)
    if(val&&val.length){
        if(target.value) {
            render()
        }else{
            needRender = true
        }
    }
}, {
    immediate: true,
    deep:  true
})

onMounted(()=>{
    if(target.value && needRender) {
        render()
    }
})
</script>

<style lang="less" scoped>
#main_line{
    width: 100%;
    height: 400px;
}
</style>