<template>
  <div>
    <el-dialog
      title="提示"
      v-model="visible.dialogVisible"
      width="40%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <span>{{ content }}</span>
      <template #footer>
        <div class="dialog-footer" style="text-align: center">
          <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
          <el-button type="primary" @click="dialogClose"> 确 定 </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["visible", "content"],
  data() {
    return {};
  },
  created() {
    // this.visible = this.dialogVisible;
  },
  methods: {
    dialogClose() {
      this.$emit("dialogClose");
    },
  },
};
</script>

<style></style>
