<template>
<div class="panel-wrap">
    <div class="top">
        <div class="title">爆款文章</div>
        <div class="switch">
            <div class="switch-item" :class="{active: current==0}" @click="check(0)">内部</div>
            <div class="switch-item" :class="{active: current==1}" @click="check(1)">外部</div>
        </div>
    </div>
    <div class="list-wrap">
        <div class="range-wrap">
            <div class="range-item" :class="{active1: current1==0}" @click="check1(0)">{{current==0?'今日':'昨日'}}</div>
            <div class="range-item" :class="{active1: current1==1}" @click="check1(1)">本周</div>
            <div class="range-item" :class="{active1: current1==2}" @click="check1(2)">本月</div>
        </div>
        <div class="list-title">
            <div class="item-rank">排行</div>
            <div class="item-name">标题</div>
            <div class="item-num">浏览量</div>
        </div>
        <div class="list-content">

            <div class="scroll-wrapper" ref="moveWrapRef" @mouseenter="commentEnter" @mouseleave="commentLeave">
                    <div class="item" v-for="(item, index) in hotData" :key="`hot-article-${item.id}-${index}`">
                        <div class="item-rank">
                            <img v-if="item.rank==1" src="../../../assets/icons/article-rank-1.png"/>
                            <img v-else-if="item.rank==2" src="../../../assets/icons/article-rank-2.png"/>
                            <img v-else-if="item.rank==3" src="../../../assets/icons/article-rank-3.png"/>
                            <span v-else>{{item.rank}}</span>
                        </div>
                        <div class="item-name">
                            <el-tooltip
                                v-if="item.title.length>12"
                                class="box-item"
                                effect="dark"
                                :content="item.title"
                                placement="bottom"
                            >
                                <div class="text-overflow">{{item.title}} </div>
                            </el-tooltip>
                            <div v-else class="text-overflow">{{item.title}} </div>
                        </div>
                        <div class="item-num">{{item.viewNum>9999? (item.viewNum/10000).toFixed(1)+'w' : item.viewNum}}</div>
                    </div>
                </div>
            
        </div>
    </div>
</div>
</template>

<script scope setup>
import { ref, watch, onMounted, onUnmounted, nextTick } from 'vue'
import { rankingArticle, rankingRecord } from '@/api/home.js'

const props = defineProps({
    reload: {
        type: Boolean
    }
})

const current = ref(0)
const current1 = ref(0)
const hotData = ref([])

const getList = ()=>{
    rankingArticle({firstDimension: current1.value, secondDimension: current.value})
        .then(res=>{
            hotData.value = []
            requestAction&&cancelAnimationFrame(requestAction);
            movePx = 0
            res.data.forEach((element, index) => {
                element.rank = index + 1
            });

            hotData.value = res.data
            nextTick(() => {
                let height = moveWrapRef.value.offsetHeight
                if (height > 160) {
                    hotData.value = [...hotData.value,...hotData.value]
                }
                cancelAnimationFrame(requestAction);
                movePx = 0
                moveFn(movePx)
            })
            console.log('hotData:', hotData.value)
        }).catch(err=>{
            console.log('get hotData err；', err)
        })
}
getList()

const check = type =>{
    if(type !== current.value){
        current.value = type
        getList()
    }
}
const check1 = type =>{
    if(type !== current1.value){
        current1.value = type
        getList()
    }
}

watch(()=>props.reload, val=>{
    if(val) {
        getList()
    }
})

const moveWrapRef = ref(null)

let requestAction = null
let movePx = 0
const moveFn=(dis)=>{
      let height = moveWrapRef.value.offsetHeight
      if (height <= 160) return 
      if(height && dis >= (height/2)){
        movePx = 0
      }
      requestAction = requestAnimationFrame(() => {
        moveWrapRef.value.style=`transform: translateY(${-dis}px)`
        movePx += 0.5
        moveFn(movePx);
      }); 
    }

const commentEnter = ()=> {
    cancelAnimationFrame(requestAction);
}
const commentLeave = ()=> {
    moveFn(movePx)
}
</script>

<style lang="less" scoped>
.panel-wrap{
    padding: 0 16px 16px;
    width: 406px;
    height: 309px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    box-sizing: border-box;
    .top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 56px;
        border-bottom: 1px solid #EBEBEB;
        .title{
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 16px;
            color: rgba(0,0,0,0.85);
        }
        .switch{
            padding: 3px;
            background: #F5F5F5;
            border-radius: 2px 2px 2px 2px;
            display: flex;
            flex-wrap: nowrap;
        }
        .switch-item{
            padding: 0 12px;
            line-height: 32px;
            cursor: pointer;
        }
        .switch-item.active{
            height: 32px;
            font-size: 14px;
            color: #0073E5;
            background: #fff;
        }
    }
}
.range-wrap{
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    .range-item{
        width: 48px;
        height: 18px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: rgba(0,0,0,0.45);
        line-height: 20px;
        text-align: center;
        cursor: pointer;
        border-right: 1px solid #F2F2F5;
    }
    .range-item:last-child{
        border-right: none;
    }
    .range-item.active1{
        color: #0073E5;
    }
}
.list-wrap{
    margin-top: 8px;
    width: 100%;
    height: 211px;
    .list-title, .item{
        padding-left: 8px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item-rank{
            width: 24px;
            flex-shrink: 0;
            text-align: center;
            img{
                width: 20px;
                height: 20px;
            }
        }
        .item-name{
            margin-left: 32px;
            flex:1;
        }
        .text-overflow{
            width: 225px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: default;
        }
        .item-num{
            // margin-left: 32px;
            width: 60px;
            flex-shrink: 0;
            text-align: right;
        }
    }
    .list-title{
        width: 373px;
        height: 32px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: rgba(0,0,0,0.45);
        background: #F5F5F5;
        border-radius: 0px 0px 0px 0px;
    }
    .item{
        width: 373px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 0px 0px 0px 0px;
    }
    .list-content{
        padding-top: 10px;
        height: 170px;
        overflow: hidden;
    }
}
.marquee-container {
    width: 100%;
    height: 100%;
  overflow: hidden;
  white-space: nowrap;
}
 
.marquee-content {
  display: inline-block;
  animation: marquee 20s linear infinite;
}
 
@keyframes marquee {
  0% {
    transform: translateY(0);
  }
  5% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
</style>