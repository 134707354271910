const ua = window.navigator.userAgent;

const iosReg = /iOS-(\d.){3}\d/;
const androidReg = /Android-(\d.){3}\d/;

// 海尔人windows端
const HbReg = /seaside\/(\d.){2}\d/;

export const isIOS = iosReg.test(ua); // ios
export const isAndroid = androidReg.test(ua); // android
export const isMobile = isIOS || isAndroid;
export const isPc = /Electron/.test(ua); // 桌面端
export const isInApp = isIOS || isPc || isAndroid;
export const isInWeb = !isInApp;
export const isHb = HbReg.test(ua);
export const isIHaier = /Lark/.test(ua);
export const isIHaierMobile = /android|iphone/.test(ua);