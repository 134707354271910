<template>
  <div class="watchDaily-wrapper">
    <div class="main">
      <van-empty description="该备案已删除" v-if="code == 420" />
      <div v-if="code == 200 && !loading">
        <van-nav-bar v-if="env == 'PC' || env == 'other'" class="h5title" title="查看备案" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="watchDaily-wrapper-body">
          <van-cell style="margin-top: 10px">
            <template #title>
              <div class="mainname">
                <span class="custom-title h5name">{{ journalDetail.name }}</span>
                <span class="h5tag">
                  <van-tag type="danger" v-if="journalDetail.status == 2">审批驳回</van-tag>
                  <van-tag type="success" v-if="journalDetail.status == 1">审批通过</van-tag>
                  <van-tag type="warning" v-if="journalDetail.status == 0">审批中</van-tag>
                </span>
                <div class="content_icon" v-if="nameShow && historicalInfo && historicalInfo.name" @click="nameShowFn">查看上版内容</div>
                <div class="content_icon_back" v-if="!nameShow" @click="nameShowFn">收起</div>
                <div v-if="!nameShow">
                  <div class="basic_cell">
                    <div class="cell_left"><span class="title">上版内容</span></div>
                    <div class="content">{{ historicalInfo.name }}</div>
                  </div>
                </div>
              </div>
            </template>
          </van-cell>
          <van-cell-group class="basic">
            <van-cell>
              <template #value>
                <div>
                  <span class="title" style="margin-right: 20px">备案人</span>
                  <span class="content">{{ journalDetail.recorderName }}</span>
                </div>
              </template>
            </van-cell>
            <van-cell>
              <template #value>
                <div>
                  <span class="title" style="margin-right: 6px">备案时间</span>
                  <span class="content">{{ journalDetail.recordTime }}</span>
                </div>
              </template>
            </van-cell>
            <!-- <van-cell>
              <template #value>
                <div>
                  <span class="title">备案类型</span>
                  <span class="content">{{journalDetail.positionType}}</span>
                </div>
              </template>
            </van-cell>
            <van-cell>
              <template #value>
                <div>
                  <span class="title">所属平台</span>
                  <span class="span-i">*</span>
                  <span class="content">{{journalDetail.mainBody}}</span>
                </div>
              </template>
            </van-cell> -->
          </van-cell-group>
          <van-cell-group class="basic" style="margin-top: 10px">
            <van-cell>
              <template #value>
                <div class="basic_cell">
                  <div class="cell_left">
                    <span class="title">期刊定位</span>
                    <span class="span-i">*</span>
                  </div>
                  <div class="content">
                    {{ journalDetail.position }}
                  </div>
                </div>
                <div class="content_icon" v-if="positionShow && historicalInfo && historicalInfo.position"><span @click="positionShowFn">查看上版内容</span></div>
                <div class="content_icon_back" v-if="!positionShow" @click="positionShowFn">收起</div>
                <div v-if="!positionShow">
                  <div class="basic_cell">
                    <div class="cell_left"><span class="title">上版内容</span></div>
                    <div class="content">{{ historicalInfo.position }}</div>
                  </div>
                </div>
              </template>
            </van-cell>
            <van-cell>
              <template #value>
                <div class="basic_cell">
                  <div class="cell_left">
                    <span class="title">期刊介绍</span>
                    <span class="span-i">*</span>
                  </div>
                  <div class="content">
                    {{ journalDetail.description }}
                  </div>
                </div>
                <div class="content_icon" v-if="descriptionShow && historicalInfo && historicalInfo.description" @click="descriptionShowFn">查看上版内容</div>
                <div class="content_icon_back" v-if="!descriptionShow" @click="descriptionShowFn">收起</div>
                <div v-if="!descriptionShow">
                  <div class="basic_cell">
                    <div class="cell_left"><span class="title">上版内容</span></div>
                    <div class="content">{{ historicalInfo.description }}</div>
                  </div>
                </div>
              </template>
            </van-cell>
            <van-cell>
              <template #value>
                <div class="cover-style">
                  <div>
                    <span class="title">期刊封面</span>
                    <span class="span-i">*</span>
                  </div>
                  <div class="upload">
                    <img class="img" :src="journalDetail.cover" />
                  </div>
                </div>
                <div class="content_icon" v-if="coverShow && historicalInfo && historicalInfo.cover" @click="coverShowFn">查看上版内容</div>
                <div class="content_icon_back" v-if="!coverShow" @click="coverShowFn">收起</div>
                <div class="cover-style" v-if="!coverShow">
                  <div>
                    <span class="title">上版内容</span>
                  </div>
                  <div class="upload">
                    <img class="img" :src="historicalInfo.cover" />
                  </div>
                </div>
              </template>
            </van-cell>
            <van-cell>
              <template #value>
                <div>
                  <span class="title">主办单位</span>
                  <span class="span-i">*</span>
                  <span class="content">
                    {{ findManagement(journalDetail.management) }}
                  </span>
                </div>
                <div class="content_icon" v-if="managementShow && historicalInfo && historicalInfo.management" @click="managementShowFn">查看上版内容</div>
                <div class="content_icon_back" v-if="!managementShow" @click="managementShowFn">收起</div>
                <div v-if="!managementShow">
                  <span class="title">上版内容</span>
                  <span class="content">
                    {{ findManagement(historicalInfo.management) }}
                  </span>
                </div>
              </template>
            </van-cell>
            <van-cell>
              <template #value>
                <div>
                  <span class="title">主管部门</span>
                  <span class="span-i">*</span>
                  <span class="content">
                    <span v-if="journalDetail.department">{{ journalDetail.department.label }}</span>
                  </span>
                </div>
                <div class="content_icon" v-if="departmentShow && historicalInfo && historicalInfo.department" @click="departmentShowFn">查看上版内容</div>
                <div class="content_icon_back" v-if="!departmentShow" @click="departmentShowFn">收起</div>
                <div v-if="!departmentShow">
                  <span class="title">上版内容</span>
                  <span class="content">
                    <span v-if="historicalInfo.department">{{ historicalInfo.department.label }}</span>
                  </span>
                </div>
              </template>
            </van-cell>
            <van-cell>
              <template #value>
                <div>
                  <span class="title">面向对象</span>
                  <span class="span-i">*</span>
                  <span class="content">
                    {{ journalDetail.target.selectList.label }}
                  </span>
                </div>
                <div class="content_icon" v-if="targetShow && historicalInfo && historicalInfo.target" @click="targetShowFn">查看上版内容</div>
                <div class="content_icon_back" v-if="!targetShow" @click="targetShowFn">收起</div>
                <div v-if="!targetShow">
                  <span class="title">上版内容</span>
                  <span class="content">
                    {{ historicalInfo.target.selectList.label }}
                  </span>
                </div>
              </template>
            </van-cell>
            <van-cell>
              <template #value>
                <div>
                  <span class="title">面向对象描述</span>
                  <span class="span-i">*</span>
                  <span class="content">{{ journalDetail.target.input }}</span>
                </div>
                <div class="content_icon" v-if="targetInputShow && historicalInfo && historicalInfo.target" @click="targetInputShowFn">查看上版内容</div>
                <div class="content_icon_back" v-if="!targetInputShow" @click="targetInputShowFn">收起</div>
                <div v-if="!targetInputShow">
                  <span class="title">上版内容</span>
                  <span class="content">{{ historicalInfo.target.input }}</span>
                </div>
              </template>
            </van-cell>
            <van-cell>
              <template #value>
                <div>
                  <span class="title">可见范围</span>
                  <span class="span-i">*</span>
                  <span class="content">{{ findText(visibleRange, journalDetail.range.value).text }}</span>
                </div>
                <!-- <div class="content_icon" v-if="visibleRangeShow && historicalInfo && historicalInfo.range" @click="visibleRangeShowFn">查看上版内容</div>
                <div class="content_icon_back" v-if="!visibleRangeShow" @click="visibleRangeShowFn">收起</div>
                <div v-if="!visibleRangeShow">
                  <span class="title">上版内容</span>
                  <span class="content">{{ findText(visibleRange, historicalInfo.range.value).text }}</span>
                </div> -->
              </template>
            </van-cell>

            <div v-if="journalDetail.range.value == 2" style="padding-left: 15px">
              <span class="title">可见人员</span>
              <span class="span-i">*</span>
            </div>
            <div v-if="journalDetail.range.value == 2">
              <avatar-show :list="journalDetail.range.input" />
            </div>
            <div style="padding: 0 16px">
              <div class="content_icon" v-if="rangeShow && historicalInfo && historicalInfo.range" @click="rangeShowFn">查看上版内容</div>
              <div class="content_icon_back" v-if="!rangeShow" @click="rangeShowFn">收起</div>
            </div>
            <div v-if="!rangeShow">
              <div style="padding-left: 15px">
                <span class="title">上版内容</span>
                <span class="content">{{ findText(visibleRange, historicalInfo.range.value).text }}</span>
              </div>
              <div v-if="historicalInfo.range.value == 2">
                <avatar-show :list="historicalInfo.range.input" />
              </div>
            </div>
            <van-cell>
              <template #value>
                <div>
                  <span class="title">创刊日期</span>
                  <span class="span-i">*</span>
                  <span class="content">{{ journalDetail.startDate }}</span>
                </div>
                <div class="content_icon" v-if="startDateShow && historicalInfo && historicalInfo.startDate" @click="startDateShowFn">查看上版内容</div>
                <div class="content_icon_back" v-if="!startDateShow" @click="startDateShowFn">收起</div>
                <div v-if="!startDateShow">
                  <span class="title">上版内容</span>
                  <span class="content">{{ historicalInfo.startDate }}</span>
                </div>
              </template>
            </van-cell>
            <van-cell>
              <template #value>
                <div>
                  <span class="title">周期频次</span>
                  <span class="span-i">*</span>
                  <span class="content" v-if="journalDetail.publishTime.isRegular == 1">
                    {{ journalDetail.publishTime.time + findText(cycleList, journalDetail.publishTime.value).text }}
                  </span>
                  <span class="content" v-else>不定期 </span>
                </div>
                <div class="content_icon" v-if="publishTimeShow && historicalInfo && historicalInfo.publishTime" @click="publishTimeShowFn">查看上版内容</div>
                <div class="content_icon_back" v-if="!publishTimeShow" @click="publishTimeShowFn">收起</div>
                <div v-if="!publishTimeShow">
                  <span class="title">上版内容</span>
                  <span class="content" v-if="historicalInfo.publishTime.isRegular == 1">
                    {{ historicalInfo.publishTime.time + findText(cycleList, historicalInfo.publishTime.value).text }}
                  </span>
                  <span class="content" v-else>不定期 </span>
                </div>
              </template>
            </van-cell>
            <van-cell>
              <template #value>
                <div class="basic_cell">
                  <div class="cell_left">
                    <span class="title">备注</span>
                    <span class="span-i">*</span>
                  </div>
                  <div class="content note" style="margin-left: 37px">
                    {{ journalDetail.note }}
                  </div>
                </div>
                <div class="content_icon" v-if="noteShow && historicalInfo && historicalInfo.note" @click="noteShowFn">查看上版内容</div>
                <div class="content_icon_back" v-if="!noteShow" @click="noteShowFn">收起</div>
                <div class="basic_cell" v-if="!noteShow">
                  <div class="cell_left">
                    <span class="title">上版内容</span>
                  </div>
                  <div class="content">
                    {{ historicalInfo.note }}
                  </div>
                </div>
              </template>
            </van-cell>
          </van-cell-group>
          <van-cell-group class="basic" style="margin-top: 10px">
            <div class="manage-title">管理人</div>
            <avatar-show :list="journalDetail.manager" />
            <div style="padding-right: 16px">
              <div class="content_icon" v-if="managerShow && historicalInfo && historicalInfo.manager" @click="managerShowFn">查看上版内容</div>
              <div class="content_icon_back" v-if="!managerShow" @click="managerShowFn">收起</div>
            </div>
            <div v-if="!managerShow">
              <div class="manage-title">上版内容</div>
              <avatar-show :list="historicalInfo.manager" />
            </div>
          </van-cell-group>
          <van-cell-group class="basic" style="margin-top: 10px">
            <van-cell>
              <template #value>
                <div :key="index" style="display: flex; margin-bottom: 10px; height: 60px" v-for="(i, index) in journalDetail.reviewResultVos">
                  <div>
                    <!-- 非最后节点且状态不是驳回的节点样式-->
                    <div style="width: 8px; height: 8px; border: 2px solid #286cfb; border-radius: 50%; margin-top: 9px" v-if="i.operation != 2 && index != journalDetail.reviewResultVos.length - 1">
                      <div style="width: 2px; height: 54px; background: #286cfb; margin-top: 10px; margin-left: 1px"></div>
                    </div>
                    <!-- 非最后节点且驳回的节点样式-->
                    <div
                      style="width: 8px; height: 8px; border: 2px solid #f5454e; border-radius: 50%; margin-top: 9px"
                      v-if="i.operation == 2 && index != journalDetail.reviewResultVos.length - 1"
                    ></div>
                    <div style="width: 2px; height: 54px; background: #286cfb; margin-top: 3px; margin-left: 3px" v-if="i.operation == 2 && index != journalDetail.reviewResultVos.length - 1"></div>
                    <!-- 最后节点且驳回的节点样式-->
                    <div style="width: 8px; height: 8px; border: 4px solid #f5454e; border-radius: 50%; margin-top: 9px" v-if="i.operation == 2 && index == journalDetail.reviewResultVos.length - 1">
                      <div style="width: 2px; height: 54px; background: #c5c5c5; margin-top: 10px; margin-left: -1px"></div>
                    </div>
                    <!-- 最后节点且不是驳回的节点样式-->
                    <div style="width: 8px; height: 8px; border: 4px solid #286cfb; border-radius: 50%; margin-top: 9px" v-if="i.operation != 2 && index == journalDetail.reviewResultVos.length - 1">
                      <!-- <div style="width: 2px; height: 52px; background: #c5c5c5; margin-top: 10px; margin-left: -1px"></div> -->
                    </div>
                  </div>
                  <div style="margin-left: 10px" v-if="i.operation != 2 && index != journalDetail.reviewResultVos.length - 1">
                    <div class="spenpizhuangt">
                      {{ i.status }}
                      <div v-if="i.operation == 1 && i.logId && i.logId !== id" class="back" @click="viewHistorical(i.logId)">
                        查看历史版本<el-icon><ArrowRightBold /></el-icon>
                      </div>
                    </div>
                    <p class="spenpitime">{{ i.updatedAt }}</p>
                  </div>
                  <div style="margin-left: 10px" v-if="i.operation == 2">
                    <div class="spenpizhuangt" style="color: #ff4224">
                      {{ i.status }}
                    </div>
                    <p class="spenpitime">{{ i.updatedAt }}</p>
                  </div>
                  <div style="margin-left: 10px" v-if="i.operation != 2 && index == journalDetail.reviewResultVos.length - 1">
                    <div class="spenpizhuangt" style="color: #286cfb">
                      {{ i.status }}
                    </div>
                    <p class="spenpitime">{{ i.updatedAt }}</p>
                  </div>
                </div>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute, useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { queryOldRecords } from "@/api/createJournal";
import { lastVersionJournal } from "@/api/JournalLibrary";
import avatarShow from "./avatarShow.vue";
import { ref, computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "h5zhendi",
  components: {
    avatarShow,
  },

  setup(props, { emit }) {
    // const store = useStore();
    // const route = useRoute();
    // const router = useRouter();
    const isAdmin = computed(() => store.state.isAdmin);
    const cycleList = [
      { value: 1, text: "年/期" },
      { value: 2, text: "月/期" },
      { value: 3, text: "周/期" },
      { value: 4, text: "天/期" },
    ];
    const visibleRange = [
      { value: 1, text: "全部可见" },
      { value: 2, text: "部分可见" },
    ];
    const onClickLeft = () => history.back();
    return {
      cycleList,
      visibleRange,
      isAdmin,
      onClickLeft,
    };
  },
  methods: {
    findText(obj, val) {
      const showObj = obj.find((ele) => {
        return ele.value == val;
      });
      return showObj;
    },
    findOptionsText(obj, val) {
      let parent, children;
      obj.forEach((item) => {
        item.children.forEach((ele) => {
          if (ele.value == val) {
            parent = item;
            children = ele;
          }
        });
      });
      return `${parent.text}/${children.text}`;
    },
    findManagement(val) {
      if (val.children && val.children.length > 0) {
        let str = val.label;
        val.children.forEach((item, index) => {
          val.label == item.label ? (str = item.label) : (str += `/${item.label}`);
          if (item.children && item.children.length > 0) {
            this.findManagement(item.children);
          }
        });
        return str;
      }
    },
    viewHistorical(logId) {
      this.id = logId;
      this.getDetail();
      // this.loading = true;
      // const res = await queryOldRecords(logId);
      // this.code = res.data2.code;
      // if (this.code == 200) {
      //   const fieldContent = res.data.fieldContent?.map((ele) => {
      //     if (ele.type === 2) {
      //       ele.value = JSON.parse(ele.value);
      //     }
      //     return ele;
      //   });
      //   this.journalDetail = { ...res.data, fieldContent };
      //   this.reviewResultVos = res.data.reviewResultVos;
      //   this.active = this.reviewResultVos.length - 1;
      //   this.status = this.journalDetail.status;
      // }
      // this.loading = false;
    },
    async getDetail() {
      this.loading = true;
      const res = await queryOldRecords(this.id);
      this.code = res.data2.code;
      if (this.code == 200) {
        const fieldContent = res.data.fieldContent?.map((ele) => {
          if (ele.type === 2) {
            ele.value = JSON.parse(ele.value);
          }
          return ele;
        });
        this.journalDetail = { ...res.data, fieldContent };
        this.reviewResultVos = res.data.reviewResultVos;
        this.active = this.reviewResultVos.length - 1;
        this.status = this.journalDetail.status;
        this.lastVersionJournal();
        this.getEvn();
      }
      this.loading = false;
    },
    positionShowFn() {
      this.positionShow = !this.positionShow;
    },
    descriptionShowFn() {
      this.descriptionShow = !this.descriptionShow;
    },
    coverShowFn() {
      this.coverShow = !this.coverShow;
    },
    managementShowFn() {
      this.managementShow = !this.managementShow;
    },
    departmentShowFn() {
      this.departmentShow = !this.departmentShow;
    },
    targetShowFn() {
      this.targetShow = !this.targetShow;
    },
    targetInputShowFn() {
      this.targetInputShow = !this.targetInputShow;
    },
    visibleRangeShowFn() {
      this.visibleRangeShow = !this.visibleRangeShow;
    },
    rangeShowFn() {
      this.rangeShow = !this.rangeShow;
    },
    startDateShowFn() {
      this.startDateShow = !this.startDateShow;
    },
    publishTimeShowFn() {
      this.publishTimeShow = !this.publishTimeShow;
    },
    nameShowFn() {
      this.nameShow = !this.nameShow;
    },
    noteShowFn() {
      this.noteShow = !this.noteShow;
    },
    managerShowFn() {
      this.managerShow = !this.managerShow;
    },
    // 获取上个版本的期刊信息
    lastVersionJournal() {
      let params = {
        id: this.id,
        type: 2,
        version: 1,
      };
      lastVersionJournal(params).then((res) => {
        this.historicalInfo = res.data || {};
      });
    },
    getEvn() {
      const ua = window.navigator.userAgent,
        ios_reg = /iOS-(\d.){3}\d/,
        andriod_reg = /Android-(\d.){3}\d/,
        pc_reg = /seaside\/(\d.){2}\d/;

      if (ios_reg.test(ua)) {
        this.env = "IOS";
        dcm.setWebView({
          title: "查看备案",
        });
      } else if (andriod_reg.test(ua)) {
        this.env = "Android";
        dcm.setWebView({
          title: "查看备案",
        });
      } else if (pc_reg.test(ua)) {
        this.env = "PC";
      } else {
        this.env = "other";
      }
    },
  },
  data() {
    return {
      code: 200,
      active: 0,
      status: 0,
      zdDetail: {},
      loading: true,
      env: "",
      reviewResultVos: [],
      reviewResultReq: {
        operation: 0,
        reason: "",
        recordId: 0,
        journalLastTime: "",
        lastTime: "",
      },
      positionShow: true,
      descriptionShow: true,
      coverShow: true,
      managementShow: true,
      departmentShow: true,
      targetShow: true,
      targetInputShow: true,
      visibleRangeShow: true,
      rangeShow: true,
      startDateShow: true,
      publishTimeShow: true,
      noteShow: true,
      managerShow: true,
      nameShow: true,
      historicalInfo: {},
      id: null,
    };
  },
  mounted() {
    this.id = Number(this.$route.query.id);
    this.getDetail();
    // this.getEvn();
  },
};
</script>
<style lang="scss" scoped>
//右侧
.main {
  overflow: auto;

  &-operate {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    height: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 16px;
    margin-bottom: 5px;
    background-color: #ffffff;
  }
  .h5title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 25px;
  }
  .mainname {
    // display: flex;
    .h5name {
      width: 80%;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 22px;
      margin-right: 10px;
    }
    .h5tag {
      width: 20%;
      line-height: 22px;
    }
  }
  .basic {
    .upload {
      cursor: pointer;
      margin-left: 10px;
      .img {
        width: 60px;
        height: 60px;
      }
    }
    .span-i {
      font-size: 14px;
      font-family: Helvetica;
      color: #f5222d;
      line-height: 22px;
    }
    .title {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      width: 20%;
    }
    .content {
      margin-left: 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      word-wrap: break-word;
      word-break: break-all;
      flex: 1;
    }
    .note {
      flex: 1;
    }
    .title {
      width: 25%;
    }
  }
  .basic_cell {
    display: flex;
    .title {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      width: 20%;
    }
    .content {
      margin-left: 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      word-wrap: break-word;
      word-break: break-all;
      flex: 1;
    }
  }
  .watchDaily-wrapper-body {
    overflow-y: auto;
    height: calc(100vh - 46px);
  }
}
.content_icon {
  position: relative;
  width: 100%;
  text-align: right;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  padding-right: 15px;
  cursor: pointer;
  &:before {
    position: absolute;
    width: 11px;
    height: 11px;
    content: "";
    top: 5px;
    right: 0;
    background: url("~@/assets/icons/right.png") no-repeat;
    background-size: contain;
  }
}
.content_icon_back {
  cursor: pointer;
  position: relative;
  width: 100%;
  text-align: right;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  padding-right: 15px;
  &:before {
    position: absolute;
    width: 11px;
    height: 11px;
    content: "";
    top: 4px;
    right: 0;
    background: url("~@/assets/icons/bottom.png") no-repeat;
    background-size: contain;
  }
}
.spenpizhuangt {
  display: inline-block;
  width: 100%;
  word-wrap: break-word;
  white-space: normal;
  font-size: 14px;
  font-family: PingFangSC-Regular, sans-serif;
  font-weight: 400;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
  .back {
    cursor: pointer;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #21c05e;
    .el-icon {
      font-size: 12px;
      top: 1px;
    }
  }
}
.spenpitime {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 22px;
}
.cover-style {
  display: flex;
  // align-items: center;
}
.manage-title {
  padding: 16px 16px 0;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1f2227;
  line-height: 20px;
}
::v-deep .van-nav-bar__text {
  color: #333333 !important;
  line-height: 25px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
}
::v-deep.van-nav-bar .van-icon {
  color: #333333 !important;
  line-height: 25px;
  font-size: 14px;
}
.van-tag {
  top: -1px;
}
</style>
