<template>
  <div class="container-wrap">
    <!-- <pcIndex /> -->
    <!-- <HelpCenter /> -->
    <el-container>
      <el-aside width="160px">
        <div class="menu-wrap">
          <div class="title">阵地管理系统</div>
          <div v-for="(item, index) in menuData" @click="go(item, index)">
            <div :class="['menu-item', item.clsName, index == current?'active':'']">
              {{item.label}}
            </div>
          </div>
        </div>
      </el-aside>
      <el-main>
        <div class="route-panel">
          <RouterView />
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import pcIndex  from './pcIndex.vue'
import {computed} from 'vue'
import {useStore} from 'vuex'
import { onBeforeRouteUpdate } from 'vue-router'
import { queryUserPermission } from "@/api/administratorConfiguration";
// import HelpCenter from '@/components/HelpCenter.vue';
import { ElMessage } from 'element-plus'

export default {
  components:{
      pcIndex,
      // HelpCenter
  },
  data(){
    return {
      menuDataInfo: [
        { label: '数据中心', clsName: 'menu-1', route: '/datacenter'},
        { label: '内刊审核', clsName: 'menu-2', route: '/journalsindex'},
        { label: '内刊库', clsName: 'menu-3', route: '/JournalLibrary'},
        { label: '权限管理', clsName: 'menu-4', route: '/administratorConfiguration'},
        { label: '预警审核', clsName: 'menu-5', route: '/WarningReview'}
      ],
      menuData: [],
      current: null
    }
  },
  methods: {
    go(item, index){
      this.$router.push(item.route)
      this.current = index
    },
    updateRoute(path, init=false) {
      console.log('===========path:', path)
      if(path=='/') {
        if(init&&this.menuData.length) {
          this.current = 0
          this.$router.replace(this.menuData[0].route)
        }
      }else{
        // 校验是否为无权限页面路由,是的话，找不到findIndex,否则完成赋值
        let findIndex = null
        for(let i=0;i<this.menuData.length;i++) {
          const item = this.menuData[i]
          if(path === item.route){
            this.current = i
            findIndex = i
            break
          }
        }    
        
        if(findIndex==null){
          const d = this.menuDataInfo.filter(item=>item.route == path)
          console.log('ddd:', d, path)
          if(d.length){
            const label = this.menuDataInfo.filter(item=>item.route == path)[0].label
            ElMessage.error('暂无'+label+'页面权限，请联系管理员')
            this.current = 0
            this.$router.replace(this.menuData[0].route)
          }else{
            // 二级页面无需处理
           
          }
        }
      }
    },
    getUserPermission () {
      return new Promise((resolve, reject)=>{
        queryUserPermission().then((res) => {
          if(res.data.datacenterPermission) {
            this.menuData.push({ label: '数据中心', clsName: 'menu-1', route: '/datacenter'})
          }
          if(res.data.approvalJournal) {
            this.menuData.push({ label: '内刊审核', clsName: 'menu-2', route: '/journalsindex'})
          }
          if(res.data.journalLibrary) {
            this.menuData.push({ label: '内刊库', clsName: 'menu-3', route: '/JournalLibrary'})
          }
          if(res.data.globalAdmin) {
            this.menuData.push({ label: '权限管理', clsName: 'menu-4', route: '/administratorConfiguration'})
          }
          sessionStorage.setItem('alertPermission', res.data.alertPermission)
          if(res.data.alertPermission){
            this.menuData.push({ label: '预警审核', clsName: 'menu-5', route: '/WarningReview'})
          }
          resolve()
        });
      })
      
    }
  },
  async mounted(){
    await this.getUserPermission()
    const path = location.pathname
    console.log('path:',path)

    this.updateRoute(path, true)
  },
  beforeRouteUpdate(to, from) {
    // console.log('to:',to, '-from:', from)
    console.log('fullpath:', to.path)
    this.updateRoute(to.path)
  }
}
</script>

<style>
 .global-btn {
   cursor: pointer;
   position:fixed;
   right: 0;
   bottom: 100px;
   color: #1f1f1f;
   z-index: 9999;
   background-color: aqua;
 }
 .container-wrap{
  width: 100%;
  height: 100%;
  background: #F5F5F5;
 }
 .el-container{
  width: 100%;
  height: 100%;
 }
 .menu-wrap{
  width: 100%;
  height: 100%;
  background: #fff;
 }
 .menu-wrap .title{
    padding-left: 16px;
    height: 48px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: rgba(0,0,0,0.85);
    line-height: 48px;
    text-align: left;
    background: #FFFFFF;
    border-radius: 0px 0px 0px 0px;
 }
 .menu-wrap .menu-item{
  padding-left: 42px;
  height: 44px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0,0,0,0.65);
  line-height: 16px;
  text-align: left;
  line-height: 44px;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer;
 }
 .menu-wrap .menu-item.active{
  color: #0073E5;
 }
.menu-wrap .menu-1{
  background: #fff url('.././assets/icons/menu-1.png') 16px center/18px 18px no-repeat;
}
.menu-wrap .menu-2{
  background: #fff url('.././assets/icons/menu-2.png') 16px center/18px 18px no-repeat;
}
.menu-wrap .menu-3{
  background: #fff url('.././assets/icons/menu-3.png') 16px center/18px 18px no-repeat;
}
.menu-wrap .menu-4{
  background: #fff url('.././assets/icons/menu-4.png') 16px center/18px 18px no-repeat;
}
.menu-wrap .menu-5{
  background: #fff url('.././assets/icons/menu-5.png') 16px center/18px 18px no-repeat;
}
.menu-wrap .menu-1.active{
  background: rgba(0,115,229,0.05) url('.././assets/icons/menu-1-a.png') 16px center/18px 18px no-repeat;
}
.menu-wrap .menu-2.active{
  background: rgba(0,115,229,0.05) url('.././assets/icons/menu-2-a.png') 16px center/18px 18px no-repeat;
}
.menu-wrap .menu-3.active{
  background: rgba(0,115,229,0.05) url('.././assets/icons/menu-3-a.png') 16px center/18px 18px no-repeat;
}
.menu-wrap .menu-4.active{
  background: rgba(0,115,229,0.05) url('.././assets/icons/menu-4-a.png') 16px center/18px 18px no-repeat;
}
.menu-wrap .menu-5.active{
  background: rgba(0,115,229,0.05) url('.././assets/icons/menu-5-a.png') 16px center/18px 18px no-repeat;
}
.el-main .route-panel{
  position: relative;
  /* width: 1248px; */
  margin: 0 auto;
}
</style>