<template>
  <div class="main" v-loading="loading">
    <div class="content">
      <div class="title">
        <img class="fanhuiimg" @click="fanhui" src="../../assets/icons/pcindex/b3b4.png" />
        <span class="span-t1">{{ title }}</span>
      </div>
      <el-descriptions :column="num">
        <template v-slot:title>
          <span :class="{ activeName: historicalInfo.name, name: true }">{{ journnalInfo.name }}</span>
          <span v-if="journnalInfo.status == 1" class="pass">审核通过</span>
          <span v-if="journnalInfo.status == 0" class="under_review">审核中</span>
          <span v-if="journnalInfo.status == 2" class="rebut">审核驳回</span>
          <el-tooltip placement="right" effect="customized" v-if="historicalInfo && historicalInfo.name">
            <template #content>
              <span class="name">{{ historicalInfo.name }}</span>
            </template>
            <img src="@/assets/icons/warning-circle.png" alt="" class="history_img" />
          </el-tooltip>
        </template>
        <el-descriptions-item label="期刊名称:" v-if="journnalInfo.i18n">
          <p v-for="(item, index) in journnalInfo.i18n" :key="index"><span v-if="journnalInfo.i18n.length>1">{{ item.languageText }}标题：</span>{{ item.name }}</p>
        </el-descriptions-item>
        <el-descriptions-item label="阵地(期刊号):" :className="{ activeClass: historicalInfo.journalCode }"
          >{{ journnalInfo.journalCode }}
          <el-tooltip placement="right" effect="customized" v-if="historicalInfo && historicalInfo.journalCode">
            <template #content>
              {{ historicalInfo.journalCode }}
            </template>
            <img src="@/assets/icons/warning-circle.png" alt="" class="history_img" />
          </el-tooltip>
        </el-descriptions-item>
        <el-descriptions-item label="阵地类型:">{{ "海尔舆论阵地 / 出版物 / 期刊" }}</el-descriptions-item>
        <el-descriptions-item label="主办单位:" :className="{ activeClass: historicalInfo.management }">
          <span v-if="journnalInfo.management">{{ findManagement(journnalInfo.management) }}</span>
          <el-tooltip placement="right" effect="customized" v-if="historicalInfo && historicalInfo.management">
            <template #content>
              <span>{{ findManagement(historicalInfo.management) }}</span></template
            >
            <img src="@/assets/icons/warning-circle.png" alt="" class="history_img" />
          </el-tooltip>
        </el-descriptions-item>
        <el-descriptions-item label="主管部门:" :className="{ activeClass: historicalInfo.department }">
          <span v-if="journnalInfo.department">{{ journnalInfo.department.label }}</span>
          <el-tooltip placement="right" effect="customized" v-if="historicalInfo && historicalInfo.department">
            <template #content>
              <span>{{ historicalInfo.department.label }}</span>
            </template>
            <img src="@/assets/icons/warning-circle.png" alt="" class="history_img" />
          </el-tooltip>
        </el-descriptions-item>
        <el-descriptions-item label="管理人:" :className="{ activeClass: historicalInfo.manager }">
          <div>
            <select-per :list="journnalInfo.manager" :type="2" :maxCount="20" @addPer="selectAdd" @del-event="selectDelete"> </select-per>
          </div>
          <el-tooltip placement="right" effect="customized" v-if="historicalInfo && historicalInfo.manager">
            <template #content>
              <select-per :list="historicalInfo.manager" :type="2" :maxCount="20" @addPer="selectAdd" @del-event="selectDelete"> </select-per>
            </template>
            <img src="@/assets/icons/warning-circle.png" alt="" class="history_img" />
          </el-tooltip>
        </el-descriptions-item>
        <el-descriptions-item label="定位:" :className="{ activeClass: historicalInfo.position }"
          >{{ journnalInfo.position }}
          <el-tooltip placement="right" effect="customized" v-if="historicalInfo && historicalInfo.position">
            <template #content>
              {{ historicalInfo.position }}
            </template>
            <img src="@/assets/icons/warning-circle.png" alt="" class="history_img" />
          </el-tooltip>
        </el-descriptions-item>
        <el-descriptions-item label="期刊介绍:" :className="{ activeClass: historicalInfo.description }"
          >
          <template v-if="journnalInfo && journnalInfo.i18n" >
            <p v-for="(item, index) in journnalInfo.i18n" :key="index"><span v-if="journnalInfo.i18n.length>1">{{ item.languageText }}介绍：</span>{{ item.description }}</p>
          </template>
          <template v-else>
            {{ journnalInfo.description }}
            <el-tooltip placement="right" effect="customized" v-if="historicalInfo && historicalInfo.description">
              <template #content>{{ historicalInfo.description }} </template>
              <img src="@/assets/icons/warning-circle.png" alt="" class="history_img" />
            </el-tooltip>
          </template>
          
        </el-descriptions-item>
        <el-descriptions-item label="封面图:" v-if="journnalInfo.cover" :className="{ activeClass: historicalInfo.cover }">
          <el-image
            :src="journnalInfo.cover"
            :preview-src-list="[journnalInfo.cover]"
            :initial-index="0"
            fit="cover"
            style="width: 64px; height: 88px; vertical-align: top; margin-top: 6px"
          ></el-image>
          <el-tooltip placement="right" effect="customized" v-if="historicalInfo && historicalInfo.cover">
            <template #content>
              <img :src="historicalInfo.cover" alt="" style="width: 64px; height: 88px; vertical-align: top; object-fit: cover; margin-top: 6px" />
            </template>
            <img src="@/assets/icons/warning-circle.png" alt="" class="history_img" />
          </el-tooltip>
        </el-descriptions-item>
        <el-descriptions-item label="面向对象:" :className="{ activeClass: historicalInfo.target }">
          <span v-if="journnalInfo && journnalInfo.target && journnalInfo.target.selectList" style="margin-right: 10px">
            {{ journnalInfo.target.selectList.label }}
          </span>
          <span v-if="journnalInfo && journnalInfo.target && journnalInfo.target.input"> {{ journnalInfo.target.input }} </span>
          <el-tooltip placement="right" effect="customized" v-if="historicalInfo && historicalInfo.target">
            <template #content>
              <span v-if="historicalInfo && historicalInfo.target && historicalInfo.target.selectList" style="margin-right: 10px">
                {{ historicalInfo.target.selectList.label }}
              </span>
              <span v-if="historicalInfo && historicalInfo.target && historicalInfo.target.input"> {{ historicalInfo.target.input }} </span>
            </template>
            <img src="@/assets/icons/warning-circle.png" alt="" class="history_img" />
          </el-tooltip>
        </el-descriptions-item>
        <el-descriptions-item label="可选范围(学海):" :className="{ activeClass: historicalInfo.range }">
          <span v-if="journnalInfo && journnalInfo.range && journnalInfo.range.value && journnalInfo.range.value == 1">全部可见 </span>
          <span v-if="journnalInfo && journnalInfo.range && journnalInfo.range.value && journnalInfo.range.value == 2">部分可见 </span>
          <SelectPerDepart
            style="margin-top: 20px"
            v-if="journnalInfo && journnalInfo.range && journnalInfo.range.value && journnalInfo.range.value == 2"
            :maxCount="500"
            :list="journnalInfo.range.input"
            :type="2"
            @addPer="selectAdd"
            @del-event="selectDelete"
          >
          </SelectPerDepart>
          <el-tooltip placement="right" effect="customized" v-if="historicalInfo && historicalInfo.range">
            <template #content>
              <span v-if="historicalInfo && historicalInfo.range && historicalInfo.range.value && historicalInfo.range.value == 1">全部可见 </span>
              <span v-if="historicalInfo && historicalInfo.range && historicalInfo.range.value && historicalInfo.range.value == 2">部分可见 </span>
              <SelectPerDepart
                style="margin-top: 20px"
                v-if="historicalInfo && historicalInfo.range && historicalInfo.range.value && historicalInfo.range.value == 2"
                :maxCount="500"
                :list="historicalInfo.range.input"
                :type="2"
                @addPer="selectAdd"
                @del-event="selectDelete"
              >
              </SelectPerDepart>
            </template>
            <img src="@/assets/icons/warning-circle.png" alt="" class="history_img" />
          </el-tooltip>
        </el-descriptions-item>
        <el-descriptions-item label="发刊时间:" :className="{ activeClass: historicalInfo.startDate }">
          <span>{{ journnalInfo.startDate }}</span>
          <el-tooltip placement="right" effect="customized" v-if="historicalInfo && historicalInfo.startDate">
            <template #content
              ><span>{{ historicalInfo.startDate }}</span>
            </template>
            <img src="@/assets/icons/warning-circle.png" alt="" class="history_img" />
          </el-tooltip>
        </el-descriptions-item>
        <el-descriptions-item label="出刊周期:" :className="{ activeClass: historicalInfo.publishTime }">
          <span v-if="journnalInfo && journnalInfo.publishTime && journnalInfo.publishTime.time && journnalInfo.publishTime.isRegular == 1"> {{ journnalInfo.publishTime.time }}</span>
          <span v-if="journnalInfo && journnalInfo.publishTime && journnalInfo.publishTime.isRegular == 1">
            <span v-if="journnalInfo.publishTime.value == 1">年/期</span>
            <span v-if="journnalInfo.publishTime.value == 2">月/期</span>
            <span v-if="journnalInfo.publishTime.value == 3">周/期</span>
            <span v-if="journnalInfo.publishTime.value == 4">天/期</span>
          </span>
          <span v-if="journnalInfo && journnalInfo.publishTime && journnalInfo.publishTime.isRegular == 0">不定期</span>
          <el-tooltip placement="right" effect="customized" v-if="historicalInfo && historicalInfo.publishTime">
            <template #content>
              <span v-if="historicalInfo && historicalInfo.publishTime && historicalInfo.publishTime.time && historicalInfo.publishTime.isRegular == 1"> {{ historicalInfo.publishTime.time }}</span>
              <span v-if="historicalInfo && historicalInfo.publishTime && historicalInfo.publishTime.isRegular == 1">
                <span v-if="historicalInfo.publishTime.value == 1">年/期</span>
                <span v-if="historicalInfo.publishTime.value == 2">月/期</span>
                <span v-if="historicalInfo.publishTime.value == 3">周/期</span>
                <span v-if="historicalInfo.publishTime.value == 4">天/期</span>
              </span>
              <span v-if="historicalInfo && historicalInfo.publishTime && historicalInfo.publishTime.isRegular == 0">不定期</span>
            </template>
            <img src="@/assets/icons/warning-circle.png" alt="" class="history_img" />
          </el-tooltip>
        </el-descriptions-item>
        <el-descriptions-item label="印数:" :className="{ activeClass: historicalInfo.print }">
          {{ journnalInfo.print
          }}<el-tooltip placement="right" effect="customized" v-if="historicalInfo && historicalInfo.print">
            <template #content> {{ historicalInfo.print }}</template>
            <img src="@/assets/icons/warning-circle.png" alt="" class="history_img" /> </el-tooltip
        ></el-descriptions-item>
        <el-descriptions-item label="所获荣誉:" :className="{ activeClass: historicalInfo.honor }">
          {{ journnalInfo.honor }}
          <el-tooltip placement="right" effect="customized" v-if="historicalInfo && historicalInfo.honor">
            <template #content> {{ historicalInfo.honor }}</template>
            <img src="@/assets/icons/warning-circle.png" alt="" class="history_img" /> </el-tooltip
        ></el-descriptions-item>
        <el-descriptions-item label="备注:" :className="{ activeClass: historicalInfo.note }"
          >{{ journnalInfo.note
          }}<el-tooltip placement="right" effect="customized" v-if="historicalInfo && historicalInfo.note">
            <template #content>{{ historicalInfo.note }}</template>
            <img src="@/assets/icons/warning-circle.png" alt="" class="history_img" /> </el-tooltip
        ></el-descriptions-item>
        <el-descriptions-item label="创建人:" :className="{ activeClass: historicalInfo.recorderName }"> {{ journnalInfo.recorderName }}</el-descriptions-item>
        <el-descriptions-item label="创建时间:" :className="{ activeClass: historicalInfo.recordTime }"> {{ journnalInfo.recordTime }} </el-descriptions-item>
      </el-descriptions>
      <el-divider />
      <div style="margin-left: 26px">
        <p style="font-size: 16px; margin-bottom: 16px" class="examine">审批流程</p>
        <arrroval :list="journnalInfo.reviewResultVos" @viewHistorical="viewHistorical" :historicalLogId="logId" />
      </div>

      <div class="btn_top">
        <el-button type="primary" class="btn" @click="fanhui">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import arrroval from "./components/arrroval.vue";
import { query, lastVersionJournal } from "@/api/JournalLibrary";
import { queryRecords, processJournal, details } from "@/api/createJournal";
import { GetDateTime } from "@/assets/common";
import SelectPer from "@/components/SelectPer.vue";
import SelectPerDepart from "@/components/SelectPerDepart.vue";
import { convertObject } from "../approveJournal/components/api";
export default {
  components: { arrroval, SelectPer, SelectPerDepart },
  data() {
    return {
      auditstatus: 3,
      id: "",
      journnalInfo: {},
      list: [],
      num: 1,
      loading: false,
      journalLastTime: "",
      type: null,
      title: "",
      historicalInfo: {},
    };
  },
  created() {
    this.logId = Number(this.$route.query.logId);
    this.type = Number(this.$route.query.type);
    // this.getQueryRecords();
  },
  watch: {
    type(newVal) {
      if (newVal === 1) {
        this.title = "期刊审核/查看详情";
        this.getDetails();
      } else if (newVal === 2) {
        this.title = "期刊库/查看详情";
        // this.getQueryRecords();
        this.getDetails();
      }
    },
  },
  methods: {
    GetDateTime,
    fanhui() {
      this.$router.back();
    },
    // 获取期刊审批详情
    getDetails() {
      this.loading = true;
      details(this.logId).then((res) => {
        this.journnalInfo = res.data;
        this.journnalInfo.lastTime = res.data.lastTime || "";
        this.journnalInfo.journalLogId = res.data.journalLogId;
        this.journalLastTime = res.data.journalLastTime;
        if(res.data.i18n){
          const lang = convertObject(res.data.i18n).filter(item => item.status !== 0)
          console.log("🚀 ~ queryRecords ~ lang:", lang)
          this.journnalInfo.i18n = lang
        }
        this.loading = false;
        this.lastVersionJournal();
      });
    },
    // 获取上个版本的期刊信息
    lastVersionJournal() {
      let params = {
        id: this.logId,
        type: this.type == 1 ? 2 : 0,
        version: 1,
      };
      lastVersionJournal(params).then((res) => {
        this.historicalInfo = res.data || {};
      });
    },
    // 获取期刊库详情
    // getQueryRecords() {
    //   this.loading = true;
    //   query(this.id).then((res) => {
    //     this.journnalInfo = res.data;
    //     // this.journnalInfo.management = res.data.management;
    //     // this.journnalInfo.lastTime = res.data.lastTime || "";
    //     this.loading = false;
    //   });
    // },
    viewHistorical(logId) {
      this.logId = logId;
      this.getDetails();
    },
    findManagement(val) {
      if (val.children && val.children.length > 0) {
        let str = val.label;
        val.children.forEach((item, index) => {
          val.label == item.label ? (str = item.label) : (str += `/${item.label}`);
          if (item.children && item.children.length > 0) {
            this.findManagement(item.children);
          }
        });
        return str;
      }
    },
    selectAdd() {},
    selectDelete() {},
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 960px) {
  .main {
    // width: 960px;
  }
}
@media screen and (min-width: 1200px) {
  .main {
    // width: 960px;
  }
}
::v-deep .el-descriptions__body .el-descriptions__table:not(.is-bordered) .el-descriptions__cell {
  display: flex;
  // width: 75%;
  .el-descriptions__content {
    position: relative;
    max-width: 322px;
    word-wrap: break-word;
    word-break: break-all;
    .history_img {
      position: absolute;
      top: 50%;
      right: -40px;
      transform: translate(0, -50%);
    }
  }
  .activeClass {
    background-color: #f4f5f7;
    padding: 5px;
  }
}
::v-deep.content .el-descriptions .el-descriptions__cell .el-descriptions__label {
  display: block;
  width: 90px;
  flex-shrink: 0;
}
.main {
  margin: 0 auto;
  overflow: hidden;
  // width: 960px;
  background: #f8f8fa;
  padding: 16px 116px;
}
.content {
  position: relative;
  width: 728px;
  background: #ffffff;
  border-radius: 10px;
  padding: 0 47px 24px 20px;
  overflow: hidden;
  ::v-deep .avator-box[data-v-52483bfe] {
    margin-bottom: 0;
  }
  .title {
    height: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 24px;

    .fanhuiimg {
      height: 16px;
      width: 16px;
      // border: 1px dashed rgba(0, 0, 0, 0.3);
      cursor: pointer;
    }

    .span-t1 {
      margin-left: 10px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2f3238;
      line-height: 22px;
    }
  }
  ::v-deep .el-descriptions {
    margin-left: 30px;
    .el-descriptions__cell .el-descriptions__label {
      display: inline-block;
      width: 90px;
    }
  }
  ::v-deep .remark .el-textarea__inner {
    height: 120px;
  }
  .img {
    position: absolute;
    top: 6px;
    right: 47px;
    width: 224px;
    height: 218px;
    z-index: 99;
  }
  .examine {
    margin-bottom: 16px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
  }
  ::v-deep .el-step__icon {
    width: 8px;
    height: 8px;
    border: 2px solid #286cfb;
    border-radius: 50%;
    top: -13px;
    left: 8px;
    .el-step__icon-inner {
      display: none;
    }
  }
  ::v-deep.el-step.is-vertical .el-step__line {
    width: 2px;
    top: 39px;
    bottom: 0;
    left: 11px;
    height: calc(100%-60px);
  }
  ::v-deep .el-step.is-vertical .el-step__title {
    line-height: 2px;
    padding-bottom: 12px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #286cfb;
    .el-step__description {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 22px;
    }
  }
  .btn {
    height: 32px;
    background: #286cfb;
    border-radius: 4px;
    // margin: 40px auto 24px;
    border: none;
  }
  .name {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
    margin-right: 10px;
  }
  .activeName {
    display: inline-block;
    background-color: #f4f5f7;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
    margin-right: 24px;
    padding: 5px;
  }
  .pass,
  .rebut,
  .under_review {
    display: inline-block;
    height: 20px;
    border-radius: 3px;
    line-height: 20px;
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    margin-right: 20px;
  }
  .under_review {
    width: 45px;
    background: rgba(251, 156, 0, 0.15);
    color: #fb9c00;
  }
  .pass {
    width: 56px;
    background: #e8f8ee;
    color: #3ec772;
  }
  .rebut {
    width: 56px;
    background: rgba(255, 66, 36, 0.15);
    color: #ff4224;
  }
  .nameInfo {
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff4224;
    line-height: 17px;
  }
  .bhnr {
    margin-top: 20px;
    margin-left: 20px;
    .dialog-span {
      width: 98px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 20px;
    }
    .input-t {
      width: 400px;
      height: 120px;
      background: #ffffff;
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }
  }
  .btn_top {
    position: absolute;
    top: 24px;
    right: 20px;
    text-align: center;
    .btn {
      width: 88px;
    }
  }
}
</style>
<style>
.el-image-viewer__img {
  max-height: 65% !important;
}
</style>
