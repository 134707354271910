<template>
  <div class="main">
    <div class="content" v-loading="loading">
      <div class="title">
        <div>
          <!-- <img class="fanhuiimg" @click="fanhui" src="../../assets/icons/pcindex/b3b4.png" /> -->
          <span class="span-t1">内刊库</span>
        </div>
        <el-input class="span-search" v-model="keyWord" placeholder="请输入期刊标题、主办单位、期刊号" @keyup.enter.native="enterClick">
          <template #append>
            <el-button :icon="Search" @click="getList()" />
          </template>
        </el-input>
      </div>
      <div class="line" v-if="list.length">
        <el-card class="box-card" v-for="item in list" :key="item">
          <div class="img">
            <img src="../../assets/img/bottom.png" alt="" class="bottom_png" />
            <el-image :src="item.cover" :preview-src-list="[item.cover]" :initial-index="0" fit="cover"
              class="img_png"></el-image>
          </div>
          <div class="info" @click="viewLibraryPermission == 1 && createInformation(item)"
            :style="{ cursor: viewLibraryPermission == 1 ? 'pointer' : 'defalut' }">
            <h3 style="line-height: 20px; margin-bottom: 6px">
              <span
                style="font-size: 16px; font-family: PingFangSC-Medium, PingFang SC; font-weight: 500; color: rgba(0, 0, 0, 0.9); line-height: 20px">
                {{ item.name }}
              </span>
              <span class="dot rebut" v-if="item.status == 2"> 审核驳回</span>
              <span class="dot review" v-if="item.status == 0">审核中</span>
            </h3>
            <p class="text">主办单位：{{ findManagement(item.management) }}</p>
            <p class="text" v-if="item.status == 1">期刊号：{{ item.journalCode }}</p>
            <p class="text manager">
              <span>管理人：</span>
              <span v-for="(k, index) in item.manager" :key="index" style="margin-right: 5px"> {{ k.name }}</span>
            </p>
            <p class="introduce" style="-webkit-box-orient: vertical">
              {{ item.description }}
            </p>
            <h3 class="info_bottom">
              <span style="margin-right: 14px; cursor: pointer" @click.stop="manage(item)"
                v-if="item.status == 1 && item.xueHaiId && viewXuehaiPermission == 1"> 创作管理</span>
              <span style="margin-right: 14px; cursor: pointer" @click.stop="details(item)"
                v-if="item.status == 1 && item.xueHaiId && viewXuehaiPermission == 1">查看期刊 </span>
              <span style="margin-right: 14px; cursor: pointer" @click="createInformation(item)"
                v-if="viewLibraryPermission == 1">期刊信息</span>
            </h3>
          </div>

          <el-dropdown trigger="click" placement="bottom-end"
            v-if="(modifyPermission == 1 && item.hasPassed == 1) || deletePermission == 1">
            <span class="el-dropdown-link">
              <el-icon style="transform: rotate(90deg); color: #8c8c8c">
                <MoreFilled />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click.native="revise(item.id)" v-if="modifyPermission == 1 && item.hasPassed == 1">
                  <el-icon style="font-size: 14px">
                    <Edit />
                  </el-icon>修改信息
                </el-dropdown-item>
                <el-dropdown-item @click.native="del(item.id)" v-if="deletePermission == 1">
                  <el-icon style="font-size: 14px">
                    <Delete />
                  </el-icon>删除期刊
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-card>
      </div>
      <div v-if="list.length" class="pagination">
        <el-pagination :current-page="pagination.current" :page-size="pagination.size"
          layout="total, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange"
          :background="isBackground" />
      </div>
      <div v-else class="empty_img">
        <img src="@/assets/img/11.png" alt="" />
        <span class="empty_text">未搜索到期刊～</span>
      </div>
    </div>
  </div>
</template>
<script>
import { list, deleteJourna } from "@/api/JournalLibrary";
import { department } from "@/assets/common";
import { MoreFilled, Edit, Search, Delete } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { isIHaier } from "@/utils/platform";
import { dcmOpenNewContainer } from "@/utils/Ihaier";
export default {
  components: {
    MoreFilled,
    Edit,
    Search,
    Delete,
  },
  data() {
    return {
      list: [],
      coverList: [],
      loading: false,
      keyWord: "",
      pagination: {
        current: 1,
        size: 10,
      },
      total: 0,
      isBackground: true,
      viewLibraryPermission: 0,
      viewXuehaiPermission: 0,
      modifyPermission: 0,
      deletePermission: 0,
      Search: Search,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    department,
    fanhui() {
      this.$router.back(0);
    },
    // 列表
    getList() {
      this.loading = true;
      let params = {
        ...this.pagination,
        keyWord: this.keyWord,
      };
      list(params).then((res) => {
        this.list = res.data.journalListVos;
        this.total = res.data.total;
        this.viewLibraryPermission = res.data.viewLibraryPermission;
        this.viewXuehaiPermission = res.data.viewXuehaiPermission;
        this.modifyPermission = res.data.modifyPermission;
        this.deletePermission = res.data.deletePermission;
        this.loading = false;
      });
    },
    enterClick() {
      this.pagination.current = 1;
      this.getList();
    },
    // 创建信息
    createInformation(item) {
      this.$router.push({
        path: "/approveJournal",
        query: { id: item.id, type: 2 },
      });
    },
    // 期刊管理
    manage(item) {
      console.log(item, 'xueHaiId')
      if (isIHaier) {
        dcmOpenNewContainer({
          // url: `https://xuehai-pc-test.lanbenzi.cn:6443/#/journals/manage/${item.xueHaiId}`,
          url: window.origin.includes("-test") ? `https://xuehai-pc-test.lanbenzi.cn:6443/#/journals/manage/${item.xueHaiId}` : `https://xuechuang.hilook.com.cn/#/journals/manage/${item.xueHaiId}`,
        });
      } else {
        dcm.jumpToPlatform({
          appId: window.origin.includes("-test") ? "2JOJWvYhSyFQ2DrwNc5qK" : "ZXwz_lm6xROWatzvEDYme",
          url: window.origin.includes("-test") ? `http://xuehai-pc-test.lanbenzi.cn/#/journals/manage/${item.xueHaiId}` : `https://xuechuang.hilook.com.cn/#/journals/manage/${item.xueHaiId}`,
        });
      }

    },
    // 期刊详情
    details(item) {
      if (isIHaier) {
        dcmOpenNewContainer({
          url: window.origin.includes('-test')
            ? `https://haixueh5-test.lanbenzi.cn:8443/#/pages/journal/journalDetail?periodical_id=${item.xueHaiId}&allowShare=false`
            : `https://xue.hilook.com.cn/#/pages/journal/journalDetail?periodical_id=${item.xueHaiId}&allowShare=false`
        });
      } else {
        dcm.openNewContainer({
          title: "",
          url: window.origin.includes("-test")
            ? `https://haixueh5-test.lanbenzi.cn/#/pages/journal/journalDetail?periodical_id=${item.xueHaiId}&allowShare=false`
            : `https://xue.hilook.com.cn/#/pages/journal/journalDetail?periodical_id=${item.xueHaiId}&allowShare=false`,
          hideTitleBar: false, // 是否隐藏 titlebar ，默认展示
        });
      }
    },
    // 修改期刊
    revise(id) {
      this.$router.push({
        path: "/createJournal",
        query: { id: id, type: "edit" },
      });
    },
    // 删除期刊
    del(id) {
      ElMessageBox.confirm("学海将同步删除该期刊，请问是否确认删除", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteJourna({ id: id }).then(() => {
          ElMessage.success("删除成功");
          this.getList();
        });
      });
    },
    handleCurrentChange(val) {
      this.pagination.current = val;
      this.getList();
    },
    findManagement(val) {
      if (val.children && val.children.length > 0) {
        let str = val.label;
        val.children.forEach((item, index) => {
          val.label == item.label ? (str = item.label) : (str += `/${item.label}`);
          if (item.children && item.children.length > 0) {
            this.findManagement(item.children);
          }
        });
        return str;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
p {
  margin-bottom: 0;
}

.main {

  margin: 0 auto;
  padding: 0;
  height: 100%;
  // width: 960px;
}

// @media screen and (min-width: 960px) {
//   .main {
//     width: 100%;
//   }
// }

// @media screen and (min-width: 1200px) {
//   .main {
//     width: 100%;
//   }
// }

.content {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 10px;
  padding: 24px 46px;
  box-sizing: border-box;
}

.title {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .fanhuiimg {
    height: 16px;
    width: 16px;
    margin-top: -4px;
    margin-right: 8px;
    cursor: pointer;
  }

  .span-t1 {
    width: 64px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2f3238;
    line-height: 22px;
  }

  .span-search {
    float: right;
    width: 320px;
    // margin-right: 20px;
  }
}

.line {

  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  overflow: hidden scroll;
  padding: 3px 3px; // 盒子阴影
  // justify-content: center;
  height: calc(100% - 92px);
  ::v-deep .box-card {
    width: 100%;
    // max-width: 1440px;
    height: 210px;
    // margin-right: 20px;
    margin-bottom: 16px;
    text-align: center;
    line-height: 170px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.04) !important;
    border-radius: 6px;
    border: 1px solid !important;
    border-image: linear-gradient(135deg, rgba(234, 235, 238, 0.6), rgba(208, 211, 216, 0.2)) 1 1 !important;
    // cursor: auto;
  }

  .box-card {
    position: relative;
    padding: 23px 20px 14px;

    .el-card__body {
      .img {
        flex-shrink: 0;
        position: relative;
        width: 129px;
        height: 173px;
        margin-right: 12px;
        border-radius: 3px;
        border: 2px solid rgba(0, 0, 0, 0.5);

        .img_png {
          width: 100%;
          height: 100%;
          vertical-align: top;
          // object-fit: scale-down; //scale-down：不变形，可能填充不满
        }

        .bottom_png {
          position: absolute;
          bottom: -11px;
          left: 0;
          width: 100%;
          height: 27px;
          z-index: 10;
        }
      }

      .info {
        flex: 1;
        text-align: left;

        ::v-deep .text {
          line-height: 17px;
          font-size: 14px;
          margin-bottom: 4px;
          color: rgba(0, 0, 0, 0.5);
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
        }

        .dot {
          // width: 4px;
          // height: 4px;
          // background: #ff4224;
          display: inline-block;
          // border-radius: 50%;
          // margin-bottom: 2px;
          font-size: 11px;
          margin-left: 10px;
        }

        .review {
          width: 45px;
          height: 20px;
          background: rgba(251, 156, 0, 0.15);
          border-radius: 3px;
          color: #fb9c00;
          line-height: 20px;
          text-align: center;
        }

        .rebut {
          width: 56px;
          height: 20px;
          background: rgba(255, 66, 36, 0.15);
          border-radius: 3px;
          color: #ff4224;
          line-height: 20px;
          text-align: center;
        }

        .introduce {
          width: 632px;
          display: -webkit-box;
          word-wrap: break-word;
          word-break: break-all;
          -webkit-line-clamp: 3;
          overflow: hidden;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.4);
          line-height: 18px;
          margin-bottom: 6px;
        }

        .manager {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          margin-bottom: 6px;
        }
      }

      .info_bottom {
        width: 100%;
        position: absolute;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        line-height: 16px;
        font-weight: 400;
        color: #286cfb;
        bottom: 10px;
        left: 160px;
      }
    }
  }

  ::v-deep .el-dropdown {
    height: 13px;
    flex-shrink: 0;
  }

  //   ::v-deep .box-card:nth-of-type(odd) {
  //     margin-right: 23px;
  //   }
  ::v-deep .el-card__body {
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  .examine {
    font-size: 11px;
    color: #ff4224;
    line-height: 16px;
    margin-bottom: 4px;
  }
}

.empty_img {
  position: relative;
  width: 100%;
  height: 482px;
  line-height: 482px;
  text-align: center;

  img {
    width: 144px;
    height: 144px;
  }

  .empty_text {
    position: absolute;
    bottom: -67%;
    left: 50;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
  }
}

.pagination {
  // position: absolute;
  // bottom: -34px;
  // right: 0;
  height: 60px;
  width: 100%;
  background: #ffffff;
  // box-shadow: 0px -2px 5px -1px rgba(0, 0, 0, 0.09);
  border-radius: 0px 0px 10px 10px;
  z-index: 10;

  .el-pagination {
    float: right;
    // margin-bottom: 24px;
    margin-top: 12px;
    margin-right: 10px;
  }
}
</style>
<style>
.el-dropdown-menu__item {
  font-size: 14px !important;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5) !important;
  line-height: 20px;
  padding: 5px 14px !important;
}

.el-dropdown-menu__item:not(.is-disabled):focus {
  background-color: #f7f7f7 !important;
}

.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #f7f7f7 !important;
}
</style>
