import myAxios from "./request";
// 查询所有的期刊
export function list(params) {
  return myAxios({
    url: "/journalLibrary",
    method: "get",
    params,
  });
}
// 修改期刊
export function edit(data) {
  return myAxios({
    url: "/journalLibrary",
    method: "put",
    data,
  });
}

//  通过主键查询单条期刊数据
export function query(id) {
  return myAxios({
    url: `/journalLibrary/${id}`,
    method: "get",
  });
}

//  删除期刊
export function deleteJourna(params) {
  return myAxios({
    url: "/journalLibrary",
    method: "DELETE",
    params,
  });
}

// 获取上个版本期刊
export function lastVersionJournal(params) {
  return myAxios({
    url: "/journalLibrary/queryLastVersionJournal",
    method: "get",
    params,
  });
}
