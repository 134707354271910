import { createApp } from "vue";
import ant from "./ant/index";
import vant from "vant";
import "vant/lib/index.css";
import "assets/font/font.scss";
import "assets/css/public.scss";
import { Toast } from "vant";
import { ConfigProvider } from "vant";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";
import infiniteScroll from "vue3-infinite-scroll-good";
import element from "./element/index";

import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import "assets/css/element-cover.scss";
import vConsole from "vconsole";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

// const isDevelopment = process.env.NODE_ENV != "production";
const origin =
  window.location.origin.includes("test") ||
  window.location.href.indexOf("debugger=true") > -1; // 目前higroup-ihaier.hilook.com.cn为正式环境
if (origin) {
  // 如果为测试环境
  new vConsole();
}
// new vConsole();
const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app
  .use(ElementPlus, {
    locale: zhCn,
  })
  .use(router)
  .use(ant)
  .use(element)
  .use(vant)
  .use(store)
  .use(infiniteScroll)
  .use(ConfigProvider)
  .mount("#app");
