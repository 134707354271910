<template>
<div class="today-panel-wrap">
    <div class="top">
        <div class="title">今日内容</div>
        <div class="time">
            <!-- 2024年12月12日 -->
            {{today}}
        </div>
    </div>
    <!-- 表 -->
    <div class="table-wrap">
        <el-table :data="tableData" :header-cell-style="{background: '#F5F5F5','color':'#8E8E8E', fontSize: '12px' }" :cell-style="{height: '44px', color: '#5C5C5C'}" style="width: 100%">
        <el-table-column label="内容" show-overflow-tooltip>
            <template #default="{row}">
            <div class="article-content" @click="goArticleDetail(row)">{{row.title}}</div>
            </template>
        </el-table-column>
        <el-table-column label="检测状态" width="150">
            <template #default="{row}">
            <div>
                <StatusTag v-if="row.alertStatus==1" type="danger">预警确认</StatusTag>
                <StatusTag v-else-if="row.alertStatus==2"  type="warning">待审核 <el-icon @click="goCheck(row.id)"><ArrowRight /></el-icon></StatusTag>
                <StatusTag v-else-if="row.alertStatus==-1" type="success">检测通过</StatusTag>
                <StatusTag v-else-if="row.alertStatus==0" type="normal">复核通过</StatusTag>
            </div>
            </template>
        </el-table-column>
        <el-table-column label="阵地类型" width="150">
            <template #default="{row}">
            <div>{{POSITIONTYPES.filter(item=>item.value==row.type)[0].label}}</div>
            </template>
        </el-table-column>
        <el-table-column label="阵地名称" width="180">
            <template #default="{row}">
            <div>{{row.positionName}}</div>
            </template>
        </el-table-column>
        <el-table-column label="发布时间" width="180">
            <template #default="{row}">
            <div>{{row.createTime}}</div>
            </template>
        </el-table-column>
        </el-table>
        <!-- 分页组件 -->
        <div class="pagination-wrap">
        <el-pagination
            v-if="search.total"
            v-model:current-page="search.current"
            v-model:page-size="search.size"
            :page-sizes="[10, 20, 50]"
            small
            layout="total, sizes, prev, pager, next, jumper"
            :total="search.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
        </div>
    </div>

</div>
</template>

<script scope setup>
import { ref, reactive, watch } from 'vue'
import StatusTag from './StatusTag.vue'
import { todayContent } from '@/api/home.js'
import { WARNINGTYPES, CHECKSTATUS, POSITIONTYPES } from '../../warningReview/options.js'
import { dcmOpenNewContainer } from "@/utils/Ihaier";
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
const props = defineProps({
    reload: {
        type: Boolean
    }
})
const router = useRouter()
const search = reactive({
  current: 1,
  size: 10,
  total: 0
})
const n = new Date()
const today = `${n.getFullYear()}年${n.getMonth()+1}月${n.getDate()}日`
const tableData = ref([])
tableData.value = []

const getList = ()=>{
  const params = {
    current: search.current,
    size: search.size
  }
  todayContent(params)
    .then(res=>{
        tableData.value = res.data.records
        search.total = res.data.total
    }).catch(err=>{
        console.log('获取今日内容接口出错：', err)
    })
}

getList()

const handleSizeChange = (val) => {
  // console.log(`${val} items per page`)
  search.size = val
  search.current = 1
  getList()
}
const handleCurrentChange = (val) => {
  // console.log(`current page: ${val}`)
  search.current = val
  getList()
}
const goCheck = id => {
    const alertPermission = sessionStorage.getItem('alertPermission')
    if(alertPermission == 1) {
        router.push('/WarningReview')
    }else{
        ElMessage.error('暂无预警审核页面权限，请联系管理员')
    }
}

const goArticleDetail = row => {
    if(row.type==1&&row.url) {
        window.open(row.url)
    }
    else if(row.type!=1&&row.id){
        let url = `${location.href.indexOf('test') > -1 ? 'https://haixueh5-test.lanbenzi.cn:8443' : 'https://xue.hilook.com.cn'}/?op_platform_service=hide_navigator#/pages/news/newsDetail?id=${row.id}&reload=true`
        dcmOpenNewContainer({ url: url })
    }
}

watch(()=>props.reload, val=>{
    search.current = 1
    if(val) {
        getList()
    }
})
</script>

<style lang="less">
.today-panel-wrap{
    margin-top: 16px;
    // padding: 0 16px 16px;
    // width: 100%;
    // height: 309px;
    width: 1248px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    box-sizing: border-box;
    .top{
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 56px;
        border-bottom: 1px solid #EBEBEB;
        box-sizing: border-box;
        .title{
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 16px;
            color: rgba(0,0,0,0.85);
        }
        .time{
            height: 20px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: rgba(0,0,0,0.45);
            line-height: 16px;
            text-align: right;
        }
    }
    .pagination-wrap{
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.table-wrap{
    padding: 16px;
    box-sizing: border-box;
}
.article-content{
    width: 95%;
    color: #262626;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}
</style>