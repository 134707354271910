<template>
<div class="panel-wrap">
    <div class="top">
        <div class="title">阵地影响力排行</div>
        <div class="switch">
            <div class="switch-item" :class="{active: current==0}" @click="check(0)">内宣指数</div>
            <div class="switch-item" :class="{active: current==1}" @click="check(1)">外宣指数</div>
        </div>
    </div>
    <div class="list-wrap">
        <div class="list-title">
            <div class="item-rank">排行</div>
            <div class="item-name">阵地名称</div>
            <div class="item-num">影响力</div>
        </div>
        <div class="list-content">
            <div class="scroll-wrapper" ref="moveWrapRef" @mouseenter="commentEnter" @mouseleave="commentLeave">
                <div class="item" v-for="(item, index) in listData" :key="`influence-item-${item.id}`">
                    <div class="item-rank">
                        <img v-if="item.rank==1" src="../../../assets/icons/influence-1.png"/>
                        <img v-else-if="item.rank==2" src="../../../assets/icons/influence-2.png"/>
                        <img v-else-if="item.rank==3" src="../../../assets/icons/influence-3.png"/>
                        <span v-else>{{item.rank}}</span>
                    </div>
                    <div class="item-name">
                        <el-tooltip
                            v-if="item.name.length > 12"
                            class="box-item"
                            effect="dark"
                            :content="item.name"
                            placement="bottom"
                        >
                            <div class="text-overflow">{{item.name}} </div>
                        </el-tooltip>
                        <div v-else class="text-overflow">{{item.name}} </div>
                        <div v-if="item.type==0" class="type-item rmh">融媒号</div>
                        <div v-if="item.type==2" class="type-item nk">内刊</div>
                        <div v-if="item.type==1" class="type-item gzh">公众号</div>
                    </div>
                    <div class="item-num">{{item.influence>9999? (item.influence/10000).toFixed(1)+'w' : item.influence}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script scope setup>
import { ref, watch, onMounted, onUnmounted, nextTick } from 'vue'
import { rankingRecord } from '@/api/home.js'

const props = defineProps({
    reload: {
        type: Boolean
    }
})
const listData = ref([])
const influenceData = ref([])
const current = ref(0)



const getList = ()=>{
    rankingRecord({dimension: current.value})
        .then(res=>{
            // influenceData.value = []
            res.data.forEach((element, index) => {
                element.rank = index + 1
            });
            listData.value = res.data
            // const len = Math.ceil(res.data.length/5)
            // for(let i=0;i<len;i++){
            //     influenceData.value[i] = res.data.slice(i*5, (i+1)*5)
            // }
            nextTick(() => {
              let height = moveWrapRef.value.offsetHeight
              if (height > 200) {
                listData.value = [...listData.value,...listData.value]
              }
              movePx = 0
              cancelAnimationFrame(requestAction);
              moveFn(movePx)
            })
        }).catch(err=>{
            console.log('get influencedata err；', err)
        })
}
getList()

const check = type =>{
    if(type !== current.value){
        current.value = type
        getList()
    }
}

watch(()=>props.reload, val=>{
    if(val) {
        getList()
    }
})

const moveWrapRef = ref(null)

let requestAction = null
let movePx = 0
const moveFn=(dis)=>{
      let height = moveWrapRef.value.offsetHeight
      if (height <= 200) return 
      if(height && dis >= (height/2)){
        movePx = 0
      }
      requestAction = requestAnimationFrame(() => {
        moveWrapRef.value.style=`transform: translateY(${-dis}px)`
        movePx += 0.5
        moveFn(movePx);
      }); 
    }

const commentEnter = ()=> {
    cancelAnimationFrame(requestAction);
}
const commentLeave = ()=> {
    moveFn(movePx)
}
</script>
<style>
    .el-carousel__item{
        height: 40px !important;
    }
</style>
<style lang="less" scoped>
.panel-wrap{
    padding: 0 16px 16px;
    width: 406px;
    height: 309px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    box-sizing: border-box;
    .top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 56px;
        border-bottom: 1px solid #EBEBEB;
        .title{
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 16px;
            color: rgba(0,0,0,0.85);
        }
        .switch{
            padding: 3px;
            background: #F5F5F5;
            border-radius: 2px 2px 2px 2px;
            display: flex;
            flex-wrap: nowrap;
        }
        .switch-item{
            padding: 0 12px;
            line-height: 32px;
            cursor: pointer;
        }
        .switch-item.active{
            height: 32px;
            font-size: 14px;
            color: #0073E5;
            background: #fff;
        }
    }
}
.list-wrap{
    margin-top: 8px;
    width: 100%;
    height: 232px;
    .list-title, .item{
        padding-left: 8px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item-rank{
            width: 24px;
            height: 20px;
            flex-shrink: 0;
            text-align: center;
            line-height: 20px;
            img{
                width: 20px;
                height: 20px;
            }
        }
        .text-overflow{
            display: inline-block;
            max-width: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: default;
        }
        .type-item{
            margin-left: 4px;
            display: inline-block;
            padding: 0 9px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            background: #E7F3FF;
            border-radius: 12px 12px 12px 12px;
            vertical-align: super;
        }
        .type-item.rmh{
            color: #0073E5;
            background: #E7F3FF;
        }
        .type-item.nk{
            color: #EFB041;
            background: rgba(239,176,65,0.1);
        }
        .type-item.gzh{
            color: #74C140;
            background: rgba(116,193,64,0.1);
        }
        .item-name{
            margin-left: 32px;
            flex:1;
            height: 20px;
        }
        .item-num{
            // margin-left: 12px;
            width: 40px;
            flex-shrink: 0;
            text-align: right;
        }
    }
    
    .list-title{
        width: 373px;
        height: 32px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: rgba(0,0,0,0.45);
        background: #F5F5F5;
        border-radius: 0px 0px 0px 0px;
        .item-num{
            // text-align: left;
        }
    }
    .item{
        width: 373px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 0px 0px 0px 0px;
    }
    .list-content{
        padding-top: 6px;
        height: 200px;
        background: #fff;
        overflow: hidden;
    }
}

.marquee-container {
    width: 100%;
    height: 100%;
  overflow: hidden;
  white-space: nowrap;
}
 
.marquee-content {
  display: inline-block;
  animation: marquee 20s linear infinite;
}
 
@keyframes marquee {
  0% {
    transform: translateY(0);
  }
  5% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
</style>