<template>
  <div>
    <commonIndex />
    <manageIndex />
  </div>
</template>
<script>
import commonIndex from '../components/pcComponent/commonIndex'
import manageIndex from '../components/pcComponent/manageIndex'
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
  setup() {
    const store = useStore()
    const isAdmin = computed(() => store.state.isAdmin)
    return {
      isAdmin,
    }
  },

  components: {
    commonIndex,
    manageIndex,
  },
}
</script>

<style lang="scss" scoped></style>
