<template>
    <div class="JournalList">
        <div class="title" @click="fanhui">
            <img class="fanhuiimg" src="../../assets/icons/pcindex/b3b4.png" />
            <span class="span-t1">返回</span>
        </div>
        <TableSearch :searchList="searchList" :searchvalue="state" :rightOperation="rightOperation" />

        <RankModal :visible="visible" :title="title" @cancel="cancel" />
        <div class="dataTable">
            <el-table :data="tableData" style="width: 100%">
                <el-table-column type="index" label="序号" width="60" :align="'center'" />
                <el-table-column prop="name" width="180" label="榜单名称" />


                <el-table-column prop="time" min-width="180" label="发布时间" />

                <el-table-column prop="admin" min-width="160" label="发布人" />
                <el-table-column prop="status" min-width="120" label="状态" />
                <el-table-column prop="" min-width="180" label="操作">
                    <template #default="scope">
                        <el-button size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button size="small" type="danger"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                        <el-button size="small" @click="handleEdit(scope.$index, scope.row)">上架</el-button>
                        <el-button size="small" type="danger"
                            @click="handleDetail(scope.$index, scope.row)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import TableSearch from '../../components/tableSearch/index.vue'
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';
import { getRankList, addRank } from '@/api/JournalList';
import RankModal from './RankModal.vue';
export default {
    components: {
        TableSearch,
        RankModal
    },
    setup() {
        const visible = ref(false)
        const store = useStore()
        const router = useRouter();
        const title = ref('新建榜单')
        const state = ref({
            name: ''
        })
        const fanhui = () => {
            router.back();
        }
        const inputchange = (value) => {

            state.value.name = value
            console.log(state.value, 'value')
        }
        const search = () => {
            console.log(state.value, 'value')
        }
        const add = () => {
            visible.value = true;
            console.log(visible.value, 'value')
        }
        const cancel = () => {
            visible.value = false;

        }
        const searchList = [{
            label: '关键词',
            key: 'name',
            type: 'input',
            placeholder: '请输入要搜索的关键字词',
            changevalue: inputchange

        },
        {
            label: '查询',
            key: '',
            type: 'button',
            placeholder: '',
            click: search

        }

        ]
        const rightOperation = [{

            label: '新增',
            key: '',
            type: 'button',
            placeholder: '',
            click: add


        }]

        const handleEdit = (index, row) => {
            console.log(index, row)
        }
        const handleDelete = (index, row) => {
            console.log(index, row)
        }
        const handleDetail = (index, row) => {
            console.log(index, row)
        }

        const tableData = [
            {
                date: '2016-05-03',
                name: 'Tom',
                address: 'No. 189, Grove St, Los Angeles',
            },
            {
                date: '2016-05-02',
                name: 'Tom',
                address: 'No. 189, Grove St, Los Angeles',
            },
            {
                date: '2016-05-04',
                name: 'Tom',
                address: 'No. 189, Grove St, Los Angeles',
            },
            {
                date: '2016-05-01',
                name: 'Tom',
                address: 'No. 189, Grove St, Los Angeles',
            },
        ]
        const getDataList = (param) => {
            const params = {
                current: 1,
                size: 10,
                name: '',
                ...param
            }
            getRankList(params).then((res) => {
                console.log(res, 'res')
            })
        }
        onMounted(() => {
            getDataList()
        })
        return {
            title,
            visible,
            state,
            searchList,
            tableData,
            rightOperation,
            handleDetail,
            cancel,
            fanhui,
            handleEdit,
            handleDelete
        }
    }
}
</script>

<style lang="scss" scoped>
.JournalList {
    background: #fff;
    height: 100%;
    padding: 16px;

    .title {
        display: inline-block;
        margin-bottom: 16px;
        cursor: pointer;
    }

    .fanhuiimg {
        height: 16px;
        width: 16px;
        margin-top: -4px;
        margin-right: 8px;

    }

    .span-t1 {
        width: 64px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2f3238;
        line-height: 22px;
    }

    .dataTable {
        border-radius: 6px;
        border: 1px solid #EBEBEB;
        border-bottom: 0px;
    }
}
</style>