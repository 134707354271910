import myAxios from "./request";
// 查询所有的管理员
export function list(params) {
  return myAxios({
    url: "/journalAdmin",
    method: "get",
    params,
  });
}
// 新增管理员
export function addAdmin(data) {
  return myAxios({
    url: "/journalAdmin",
    method: "post",
    data,
  });
}
// 修改管理员
export function editAdmin(data) {
  return myAxios({
    url: "/journalAdmin",
    method: "put",
    data,
  });
}
// 删除管理员
export function deleteAdmin(params) {
  return myAxios({
    url: "/journalAdmin",
    method: "DELETE",
    params,
  });
}

// 获取权限
export function queryUserPermission() {
  return myAxios({
    url: "/journalAdmin/queryUserPermission",
    method: "get",
  });
}
