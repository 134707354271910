// 预警类别
export const WARNINGTYPES = [
  { label: "触发错词", value: 0 },
  { label: "触发禁用词", value: 1 },
];

// 审核状态
export const CHECKSTATUS = [
  { label: "待审核", value: 2 },
  { label: "已确认", value: 1 },
  { label: "已驳回", value: 0 },
];

// 阵地类型
export const POSITIONTYPES = [
  { label: "融媒号", value: 0 },
  { label: "公众号", value: 1 },
  { label: "内刊", value: 2 },
];
