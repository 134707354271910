<template>
    <el-dialog v-model="dialogFormVisible" :title="title" :close-on-click-modal="false" @close="onCancel">
        <el-form :model="ruleForm" label-position="top" ref="ruleFormRef" :rules="rules">
            <el-form-item label="榜单名称" class="form-item" prop="name">
                <el-input v-model.trim="ruleForm.name" autocomplete="off" placeholder="请输入榜单名称" maxlength="20"
                    show-word-limit />
            </el-form-item>
            <el-form-item label="选择时间" class="form-item" prop="time">
                <el-date-picker v-model="ruleForm.time" type="daterange" start-placeholder="开始时间"
                    end-placeholder="结束时间" />

            </el-form-item>

        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="onCancel">取消</el-button>
                <el-button type="primary" @click="submitForm(ruleFormRef)">
                    确定
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script  setup>
import dayjs from 'dayjs'
import { editRank, addRank } from '@/api/JournalList';
import { reactive, ref, defineProps, watch, defineEmits } from 'vue'
import { message } from 'ant-design-vue';

const props = defineProps({
    title: {
        type: String,
        default: ''
    },
    // 页面可以请求
    visible: {
        type: Boolean,
        default: false
    },

    record: {
        type: Object,
        default: null
    },
    rightOperation: {
        type: Array,
        default: () => []
    }
})
const emit = defineEmits(['cancel', 'confirm'])
const dialogFormVisible = ref(false)
const ruleFormRef = ref()



const ruleForm = reactive({
    name: '',
    time: []
})
const validateTime = (rule, value, callback) => {
    console.log("ziigngy")
    if (ruleForm.time[0] && ruleForm.time[1]) {
        callback()
    }
    callback(new Error('请输入时间'))

}
const rules = reactive({
    name: [{ required: true, message: '请输入榜单名称', trigger: 'blur' }],

    time: [{ required: true, validator: validateTime, trigger: 'blur' }],
})


const onCancel = () => {
    if (!ruleFormRef.value) return
    ruleFormRef.value.resetFields()
    emit('cancel')
}
const submitForm = async (formEl) => {

    if (!formEl) return
    await formEl.validate((valid, fields) => {
        if (valid) {
            console.log('submit!')
            if (props.title === "新增榜单") {
                addRank({
                    name: ruleForm.name,
                    startAt: dayjs(ruleForm.time[0]).format('YYYY-MM-DD'),
                    endAt: dayjs(ruleForm.time[1]).format('YYYY-MM-DD')
                }).then((res) => {
                    if (res && res.data2.code === 0) {
                        message.success("新增成功")
                        emit('confirm')
                    }
                })
            } else {
                editRank({
                    id: props.record.id,
                    name: ruleForm.name,
                    startAt: dayjs(ruleForm.time[0]).format('YYYY-MM-DD'),
                    endAt: dayjs(ruleForm.time[1]).format('YYYY-MM-DD')
                }).then((res) => {
                    if (res && res.data2.code === 0) {
                        message.success("修改成功")
                        emit('confirm')
                    }
                })
            }


        } else {
            console.log('error submit!', fields)
        }
    })
}

watch(() =>
    props.visible

    , (newValue) => {
        dialogFormVisible.value = newValue ? newValue : false
    })
watch(() =>
    props.record
    , (newValue) => {
        console.log(newValue, 'newValue')
        if (newValue) {
            ruleForm.name = newValue.name
            ruleForm.time[0] = newValue.startAt
            ruleForm.time[1] = newValue.endAt

        }
    })
</script>
<style lang="scss" scoped>
.el-button--text {
    margin-right: 15px;
}

.el-date-editor {
    width: 100%;
}

.form-item {}

.dialog-footer button:first-child {
    margin-right: 10px;
}
</style>
<style lang="scss">
.el-button--text {
    margin-right: 15px;
}

.el-form-item__label {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #262626;
}

.el-form-item__content {
    .el-date-editor {
        width: 100%;
    }
}



.dialog-footer button:first-child {
    margin-right: 10px;
}
</style>