<template>
<div class="panel-wrap">
    <div class="top">
        <div class="title">备案情况</div>
        <div class="switch">
            <div class="switch-item" :class="{active: current==0}" @click="check(0)">按阵地类型</div>
            <div class="switch-item" :class="{active: current==1}" @click="check(1)">按领域</div>
        </div>
    </div>
    <div class="chart-wrap">
        <PieDiagram :data="pieData" :type="current" :show-check="current===0" :legend-style="{width:current==1? 150: 80, right: current==1 ? 1: 60}" @check="e=>onCheck(e, 'num')"></PieDiagram>
        <div class="c-legend" v-if="current==0">
            <div class="cl-item" v-for="(item, index) in pieData" :key="`pie-chart-custom-legend-${index}`">
                <div class="num" @click="onCheck(item, 'num')">{{item.value}}</div>
                <img src="@/assets/icons/icon_top_right_normal(1).png" @click="onCheck(item, 'num')">
            </div>
        </div>
    </div>
    <el-dialog
        v-model="dialogVisible"
        title="备案明细"
        width="800"
    >
        <div>
            <div class="tab-wrap">
                <div class="tab-item" 
                     :class="{'tab-active': tabCurrent==item.value}" 
                     v-for="item in tabs" 
                     :key="`tab-item-${item.value}`"
                     @click="tabItemClick(item)"
                     >{{item.label}}</div>
            </div>
            <el-table :data="tableData" border :header-cell-style="{background: '#f1f1f1'}" style="width: 100%">
                <el-table-column prop="name" label="阵地名称" width="250" />
                <el-table-column prop="domainName" label="领域" width="180" />
                <el-table-column prop="createTime" label="备案时间" />
                <el-table-column prop="address" label="链接" width="100">
                    <template #default="{row}">
                        <el-button type="primary" v-if="tabCurrent==0&&row.isAuth==1 || tabCurrent==2" link @click="toDetail(row)">访问 >></el-button>
                        <el-popover
                            v-if="tabCurrent==1 && row.url"
                            placement="bottom-end"
                            title=""
                            :width="330"
                            trigger="hover"
                        >
                            <template #reference>
                            <el-button type="primary" link>查看二维码</el-button>
                            </template>
                            <div class="img-wrap">
                                <img :src="row.url" />
                                <div>
                                    请使用微信扫一扫 <br>
                                    查看该公众号
                                </div>
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-wrap">
            <el-pagination
                v-model:current-page="search.current"
                v-model:page-size="search.size"
                :default-page-size="5"
                :page-sizes="[5, 10, 20]"
                small
                layout="total, sizes, prev, pager, next, jumper"
                :total="search.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
            </div>
        </div>
    </el-dialog>
</div>
</template>

<script scope setup>
import { ref, reactive, watch, nextTick } from 'vue'
import PieDiagram from '@/components/charts/PieDiagram.vue'
import { beianTotal, beianDetail } from '@/api/home.js'
import { dcmOpenNewContainer } from "@/utils/Ihaier";

const props = defineProps({
    reload: {
        type: Boolean
    }
})

const pieData = ref([])
// 数据获取类型
const current = ref(0)
const dialogVisible = ref(false)
const tableData = ref([])
const tabs = [{
    label: '融媒号',
    value: 0
},{
    label: '公众号',
    value: 1
},{
    label: '内刊',
    value: 2
}]
const tabCurrent = ref(0)
const search = reactive({
    size: 5,
    current: 1,
    total: 0
})


const onCheck = (data, type) => {
    if(type=='num'&&current.value==0){
        switch(data.name){
            case '融媒号':
                tabCurrent.value = 0
                break;
            case '公众号':
                tabCurrent.value = 1
                break;
            case '内刊':
                tabCurrent.value = 2
                break
        }
        dialogVisible.value = true
        getDetail()
    }

}

// 获取饼图数据
const getTotal = () => {
    beianTotal({dimension: current.value})
      .then(res=>{
        const {accountCountDetail, totalAccount} = res.data
        pieData.value = []
        nextTick(()=>{
            pieData.value = accountCountDetail.map(item=>({value: item.accountCount, name: item.itemName.length>6?item.itemName.slice(0,6)+'...':item.itemName}))
            if(current.value == 0) {
                pieData.value = pieData.value.filter(item=>['融媒号','公众号','内刊'].includes(item.name))
            }
            
            pieData.value.sort((f,s)=>s.value-f.value)
        })
      })
      .catch(err=>{
        console.log('beian.vue line40 err:', err)
      })
}

const getDetail = () => {
    beianDetail({
        current: search.current,
        size: search.size,
        type: tabCurrent.value
    }).then(res=>{
        const {records, total} = res.data
        tableData.value = records
        search.total = total
    }).catch(err=>{
        tableData.value = []
        console.log('beian get detail err:', err)
    })
}

getTotal()


const check = type =>{
    current.value = type
    getTotal()
}

const tabItemClick = (item) => {
    if(item.value == tabCurrent.value) {
        return
    }
    tableData.value = []
    search.current = 1
    tabCurrent.value = item.value
    getDetail()
}

const handleSizeChange = (val) => {
    search.size = val
    search.current = 1
    getDetail()
}
const handleCurrentChange = (val) => {
//   console.log(`current page: ${val}`)
    search.current = val
    getDetail()
}

const toDetail = row => {
    if(!row.id) {
    return
  }
  if(tabCurrent.value === 0){
      
    let url = `${location.href.indexOf('test') > -1 ? 'https://haixueh5-test.lanbenzi.cn:8443' : 'https://xue.hilook.com.cn'}/?op_platform_service=hide_navigator#/pages/mediaHome/mediaHome?media_id=${row.id}&reload=true`
    dcmOpenNewContainer({ url: url })      
  }
   else if(tabCurrent.value === 1){

   }else if(tabCurrent.value === 2){
        let url = `${location.href.indexOf('test') > -1 ?'https://haixueh5-test.lanbenzi.cn:8443' : 'https://xue.hilook.com.cn'}/?op_platform_service=hide_navigator#/pages/journal/journalDetail?periodical_id=${row.id}&reload=true&hideMeNavBar=true`
        dcmOpenNewContainer({ url: url })
    }
}

watch(()=>props.reload, val=>{
    if(val) {
        getTotal()
    }
})
</script>

<style lang="less" scoped>
.panel-wrap{
    padding: 0 16px 16px;
    width: 406px;
    height: 309px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    box-sizing: border-box;
    .top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 56px;
        border-bottom: 1px solid #EBEBEB;
        .title{
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 16px;
            color: rgba(0,0,0,0.85);
        }
        .switch{
            padding: 3px;
            background: #F5F5F5;
            border-radius: 2px 2px 2px 2px;
            display: flex;
            flex-wrap: nowrap;
        }
        .switch-item{
            padding: 0 12px;
            line-height: 32px;
            cursor: pointer;
        }
        .switch-item.active{
            height: 32px;
            font-size: 14px;
            color: #0073E5;
            background: #fff;
        }
    }
}
.chart-wrap{
    position: relative;
    width: 100%;
    height: 252px;
    .c-legend{
        position: absolute;
        right: 0;
        top: 50%;
        z-index: 99;
        transform: translate(0, -50%);
        width: 80px;
        // height: 64px;
        // background: aliceblue;
        .cl-item{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
            height: 26px;
            .num{
                width: 55px;
                text-align: right;
                flex-shrink: 0;
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 16px;
                color: rgba(0,0,0,0.85);
                // line-height: 18px;
                cursor: pointer;
            }
            img{
                margin-left: 8px;
                width: 12px;
                height: 12px;
                cursor: pointer;
            }
        }
    }
}
.tab-wrap{
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    .tab-item{
        width: 80px;
        height: 40px;
        text-align: center;
        line-height: 38px;
        cursor: pointer;
    }
    .tab-active{
        color: #0073E5;
        border-bottom: 2px solid #0073E5;
    }
}
::v-deep .el-dialog__body{
    padding: 0 20px 20px;
}
.pagination-wrap{
    padding-top: 20px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.img-wrap{
    display: flex;
    align-items: center;
    color: #333;
    line-height: 28px;
    font-size: 13px;
    img{
        margin-right: 20px;
        width: 150px;
    }
}
</style>