<template>
  <div class="header">
    <div class="filterList">
      <div v-for="(item, index) in searchList" :key="index" class="filterItem">
        <div v-if="item.type === 'input'" class="item">
          <div v-if="item.label" class="label">{{ item.label }}:</div>
          <el-input :placeholder="item.placeholder" @input="(e) => item.changevalue(e)"
            :model-value="searchvalue[item.key]" class="myInput" />
        </div>
        <div v-if="item.type === 'button'">
          <el-button type="primary" @click="() => item.click()" :autofocus="true">{{ item.label }}</el-button>

        </div>
      </div>
    </div>
    <div class="rightOperate">
      <div v-for="(item, index) in rightOperation" :key="index" class="optItem">

        <div v-if="item.type === 'button'">
          <el-button :icon="CirclePlus" color="#286CFB" @click="() => item.click()" :autofocus="true"> {{
            item.label
          }}</el-button>

        </div>
      </div>
    </div>
  </div>
</template>  
<script setup>
import { defineProps, defineEmits, ref } from 'vue';
import { Plus, CirclePlus } from '@element-plus/icons-vue'
const props = defineProps({
  articleId: {
    type: [Number, String],
  },
  // 页面可以请求
  searchvalue: {
    type: Object
  },
  // 是否显示筛选
  searchList: {
    type: Array,
    default: () => []
  },
  rightOperation: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(['update:select', 'commentPos'])
const value = ref('')
const changevalue = (e) => {
  value.value = e
}



</script>
<style lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.filterList,
.rightOperate {
  display: flex;
  align-items: center;

  .filterItem,
  .optItem {
    margin-right: 16px;


    .item {
      display: flex;
      align-items: center;

      .label {
        margin-right: 5px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
      }

      .myInput {
        width: 240px;
      }

    }

    .el-button:focus {
      background: #286CFB
    }
  }
}
</style>