import { languages } from "./data";
/*
  [{language: 'zh',
  name: '',
  description:'',}]
  转换为 
  {zh: {name: '', description: ''}}
  */
export const convertArray = (data) => {
  var result = {};
  data.forEach(function(item) {
    result[item.language] = item;
  });
  return result;
}

/* 反向转化
  {zh: {name: '', description: ''}}
  转换为 
  [{language: 'zh',
  name: '',
  description:'',}]
  */
 export const convertObject = (data) => {
  var result = [];
  for(var key in data) {
    result.push({language: key, ...data[key]});
  }
  //按照 languages 中 simple 的顺序排序
  const resultSortList = []
  languages.map(item => {
    if(result.find(it => it.language == item.simple)){
      resultSortList.push({
        ...result.find(it => it.language == item.simple),
        languageText: item.label
      })
    }
  })

  return resultSortList;
}
