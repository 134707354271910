<!-- 预警审核页面 -->
<template>
<div class="warning-review-wrap">
    <div class="data-stat">
        <div class="item">
            <div class="text">待审核</div>
            <div class="num">{{total.waitCheckRecordCount}}</div>
        </div>
        <div class="item">
            <div class="text">预警确认</div>
            <div class="num">{{total.passRecordCount}}</div>
        </div>
        <div class="item">
            <div class="text">预警驳回</div>
            <div class="num">{{total.rejectRecordCount}}</div>
        </div>
    </div>
    <!-- 筛选 -->
    <div class="search-block">
      <el-input v-model="search.title" clearable placeholder="文章标题，模糊搜索" style="width: 180px"/>
      <el-select v-model="search.alertType" clearable placeholder="预警类别" style="width: 120px">
        <el-option
          v-for="item in WARNINGTYPES"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-input v-model="search.word" clearable placeholder="预警词，模糊搜索" style="width: 180px"/>
      <el-select v-model="search.status" clearable placeholder="审核状态" style="width: 120px">
        <el-option
          v-for="item in CHECKSTATUS"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-select v-model="search.positionType" clearable placeholder="阵地类型" style="width: 140px">
        <el-option
          v-for="item in POSITIONTYPES"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-input v-model="search.position" clearable placeholder="阵地名称，模糊搜索" style="width: 180px"/>
      <el-button type="primary" @click="goSearch">查询</el-button>
      <el-button @click="reset">重置</el-button>
    </div>
    <!-- 表 -->
    <el-table :data="tableData" border sortable="custom" :default-sort="{ prop: 'date', order: 'descending' }" @sort-change="onSortChange">
      <el-table-column label="序号" type="index" width="60" align="center"/>
      <el-table-column width="180" label="文章标题" show-overflow-tooltip>
        <template #default="{row}">
          <div class="article-title text-overflow" @click="goArticleDetail(row.xuehaiId, row.errorWord)">{{row.title}}</div>
        </template>
      </el-table-column>
      <el-table-column label="预警类别" width="110" align="center">
        <template #default="{row}">
          <div>{{WARNINGTYPES.filter(item=>item.value==row.alertType)[0].label}}</div>
        </template>
      </el-table-column>
      <el-table-column label="预警词" width="150">
        <template #default="{row}">
          <div class="article-title text-overflow" @click="goArticleDetail(row.xuehaiId, row.errorWord)">{{row.errorWord}}</div>
        </template>
      </el-table-column>
      <el-table-column label="审核状态" width="110" align="center">
        <template #default="{row}">
          <div>{{CHECKSTATUS.filter(item=>item.value==row.status)[0].label}}</div>
        </template>
      </el-table-column>
      <el-table-column label="阵地类型" width="110" align="center">
        <template #default="{row}">
          <div>{{POSITIONTYPES.filter(item=>item.value==row.platformType)[0].label}}</div>
        </template>
      </el-table-column>
      <el-table-column label="阵地名称" width="180" show-overflow-tooltip>
        <template #default="{row}">
          <div class="text-overflow">{{row.platformName}}</div>
        </template>
      </el-table-column>
      <el-table-column label="触发时间" prop="date" sortable width="190">
        <template #default="{row}">
          <div>{{row.createdAt}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="180" align="center">
          <template #default="{row}">
              <el-button v-if="row.status!==2" size="small" type="primary" link @click="openDetail(row)">
              查看
              </el-button>
              <el-button
                v-if="row.status==2"
                size="small"
                type="danger"
                link
                @click="handleOp(row.id, 'confirm')"
                >
                预警确认
              </el-button>
              <el-button
                v-if="row.status==2"
                size="small"
                type="success"
                link
                @click="handleOp(row.id, 'reject')"
                >
                预警驳回
              </el-button>
          </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <div class="pagination-wrap">
      <el-pagination
        v-if="search.total"
        v-model:current-page="search.current"
        v-model:page-size="search.size"
        :page-sizes="[10, 20, 50]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="search.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- 查看详情弹框 -->
    <el-drawer v-model="showDrawer" :size="378" @close="onDrawerClose">
      <template #header>
        <h4>审核详情</h4>
      </template>
      <template #default>
        <div class="content">
          <div class="item">
            <div class="item-label">预警类别</div>
            <div class="item-content">{{WARNINGTYPES.filter(item=>item.value == log.alertType)[0].label}}</div>
          </div>
          <div class="item">
            <div class="item-label">预警词</div>
            <div class="item-content">{{log.errorWord}}</div>
          </div>
          <div class="item">
            <div class="item-label">文章标题</div>
            <div class="item-content can-click" @click="goArticleDetail(log.xuehaiId, log.errorWord)">查看原文 ></div>
          </div>
          <div class="title-click" @click="goArticleDetail(log.xuehaiId, log.errorWord)">{{log.title}}</div>
          <el-drawer
            v-model="innerDrawer"
            title=""
            :append-to-body="true"
            direction="btt"
            style="width: 378px;height: 150px !important;right: 0 !important;left: auto;"
          >
          <div class="check-group">
            <div v-if="log.status == 0">
              <el-button type="warning" @click="handleOp(log.id, 'confirm')">审核通过</el-button>
              <div class="tip">审核通过将上报预警<br />并计入预警名单</div>
            </div>
            <div v-if="log.status == 1">
              <el-button type="success" @click="handleOp(log.id, 'reject')">审核驳回</el-button>
              <div class="tip">审核驳回即系统预警错误<br />将不计入预警名单</div>
            </div>
          </div>
          </el-drawer>
        </div>
      </template>
      <template #footer>
        <div style="flex: auto">
          <el-button @click="confirmClick">修改审核</el-button>
          <el-button type="primary" @click="onDrawerClose">返回</el-button>
        </div>
      </template>
    </el-drawer>
</div>
</template>

<script scope setup>
import { computed, ref, reactive } from 'vue'
import { WARNINGTYPES, CHECKSTATUS, POSITIONTYPES } from './options.js'
import { warningList, warningAudit, warningDetail } from '@/api/warning.js'
import { dcmOpenNewContainer } from "@/utils/Ihaier";
import { ElMessage, ElMessageBox } from 'element-plus'

const search = reactive({
  position: '',
  // 预警类型
  alertType: '',
  // 预警词
  word: '',
  position: '',
  // 阵地类型
  positionType: '',
  // 审核状态
  status: '',
  // 0-正序，1-倒序
  sort: 1,
  current: 1,
  size: 10,
  total: 0
})

const total = reactive({
  rejectRecordCount: 0,
  waitCheckRecordCount: 0,
  passRecordCount: 0
})
const tableData = ref([])
const log = reactive({
  id: 0,
  alertType: 0,
  alertType: '',
  title: '',
  status: 2,
  xuehaiId: 0
})
// 弹框控制
const showDrawer = ref(false)
const innerDrawer = ref(false)
const openDetail = (row) => {
  showDrawer.value = true
  // log.value = row
  log.id = row.id
  warningDetail({id: row.id})
    .then(res=>{
      const data = res.data
      log.alertType = data.alertType
      log.errorWord = data.errorWord
      log.title = data.title
      log.status = data.status
      log.xuehaiId = data.xuehaiId
    }).catch(err=>{
      console.log('获取审核详情出错：', err)
    })
}

const resetLogData = ()=>{
  log.id = 0
  log.alertType = 0
  log.errorWord = ''
  log.title = ''
  log.status = 2
  log.xuehaiId = 0
}

const handleOp = (id, type) => {
  ElMessageBox.confirm(
    `您确定要 <b>${type=='confirm'?'确认预警':'驳回预警'}</b> 吗？<br />${type=='confirm'?'确认预警后将上报预警，并计入预警名单':'驳回预警即系统预警错误，将不计入预警名单'}`,
    '请确定',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: '',
      center: true,
      dangerouslyUseHTMLString: true
    }
  )
    .then(() => {
      warningAudit({
        id: id,
        status: type=='confirm'?1:0
      }).then(res=>{
        if(log.id){
          ElMessage({
            type: 'success',
            message: `${type=='confirm'?'已更新为审核通过':'已更新为审核驳回'}`,
          })
        }
        
        showDrawer.value = false
        innerDrawer.value = false
        resetLogData()
        goSearch()
      }).catch(err=>{
        console.log(`${type=='confirm'?'确认预警':'驳回预警'}出错：`,err)
      })
    })
    .catch(() => {
     console.log('取消操作')
    })
}

const onDrawerClose = ()=>{
  showDrawer.value = false
  innerDrawer.value = false
  resetLogData()
}
const goSearch = async ()=>{
  let params = {
   ...search 
  }
  delete params.total
  const {data} = await warningList(params)
  tableData.value = []
  total.waitCheckRecordCount = 0
  total.passRecordCount = 0
  total.rejectRecordCount = 0
  console.log('data:', data)
  const { waitCheckRecordCount, passRecordCount, rejectRecordCount, page } = data
  total.waitCheckRecordCount = waitCheckRecordCount
  total.passRecordCount = passRecordCount
  total.rejectRecordCount = rejectRecordCount
  tableData.value = page.records
  search.total = page.total
}

goSearch()

const reset = ()=>{
  search.position = ''
  search.alertType = ''
  search.word = ''
  search.position = ''
  search.positionType = ''
  search.status = ''
  search.sort = 0
  search.current = 1
  search.title = ''
  goSearch()
}

const onSortChange = (prop)=>{
  console.log('props-', prop)
  search.sort = prop.order=="descending"?1:0
  search.current = 1
  goSearch()

}

const handleSizeChange = (val) => {
  // console.log(`${val} items per page`)
  search.size = val
  search.current = 1
  goSearch()
}
const handleCurrentChange = (val) => {
  // console.log(`current page: ${val}`)
  search.current = val
  goSearch()
}

// 文章详情页
const goArticleDetail = (id, word)=>{
  if(!id) {
    return
  }
  let url = `${location.href.indexOf('test') > -1 ? 'https://haixueh5-test.lanbenzi.cn:8443' : 'https://xue.hilook.com.cn'}/?op_platform_service=hide_navigator#/pages/news/newsDetail?id=${id}&reload=true&keyword=${word}`
  dcmOpenNewContainer({ url: url })
}


const confirmClick = () =>{
  innerDrawer.value = true
}
</script>

<style lang="less" scoped>
.data-stat{
  flex-shrink: 0;
  width: 280px;
  height: 80px;
  display: flex;
  .item{
    width: 90px;
    height: 80px;
    text-align: center;
    .text{
      font-size: 18px;
      color: #666;
    }
    .num{
      font-size: 28px;
      color: #333;
      line-height: 40px;
    }
  }
}
.search-block{
  padding: 10px 10px 20px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  >*{
    flex-shrink: 0;
    margin-right: 12px;
    margin-bottom: 12px;
  }
}
.warning-review-wrap{
  padding: 24px 46px;
  margin: 0 auto;
  background: #fff;
  height: 100%;
  // width: 960px;
  display: flex;
  flex-direction: column;
}
.warning-review-wrap .el-table{
  max-height: calc(100% - 100px);
}
.pagination-wrap{
  padding: 20px 20px 0;
  height: 72px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}
.article-title{
  cursor: pointer;
  color: #286cfb;
}
.tip{
  margin-top: 12px;
}
.content{
  width: 100%;
  .item{
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item-label{
      flex-shrink: 0;
    }
    .can-click{
      cursor: pointer;
    }
  }
  .title-click{
    width: 100%;
    color: #286cfb;
    cursor: pointer;
  }
}
.check-group{
  display: flex;
  align-items: center;
  text-align: center;
  >div{
    width: 100%;
  }
}
.text-overflow{
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media screen and (min-width: 960px) {
  .warning-review-wrap .el-table{
    max-height: calc(100% - 140px);
  }
}

@media screen and (min-width: 1420px) {
  .warning-review-wrap .el-table{
    max-height: calc(100% - 100px);
  }
}
</style>