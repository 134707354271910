<template>
    <div class="JournalList">
        <div class="title">
            <img class="fanhuiimg" @click="fanhui" src="../../assets/icons/pcindex/b3b4.png" />
            <span class="span-t1">期刊排行榜</span>
        </div>
        <TableSearch :searchList="searchList" :searchvalue="state" :rightOperation="rightOperation" />

        <RankModal :visible="visible" :title="title" @cancel="cancel" @confirm="confirm" :record="record" />
        <div class="dataTable">
            <el-table :data="tableData" style="width: 100%">
                <el-table-column type="index" label="序号" width="60" :align="'center'" />
                <el-table-column prop="name" width="180" label="榜单名称" />


                <el-table-column prop="createdAt" min-width="180" label="发布时间" />

                <el-table-column prop="journalAdminName" min-width="160" label="发布人" />
                <el-table-column prop="status" min-width="120" label="状态">
                    <template #default="scope">
                        <span>{{ scope.row.status ? '上架中' : '已下架' }}</span>

                    </template>
                </el-table-column>
                <el-table-column prop="" min-width="200" label="操作">
                    <template #default="scope">
                        <span class="action" @click="handleDetail(scope.row)">评分</span>
                        <span class="action" @click="handleEdit(scope.row)">编辑</span>
                        <span class="action" @click="handleDelete(scope.row)">删除</span>
                        <span :class="['action', scope.row.status ? 'cancel' : '']" @click="changeStatus(scope.row)">{{
                            scope.row.status ? '下架' : '上架'
                        }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination class="pagination" @current-change="handleCurrentChange" :current-page="pagination.current"
            layout="total, prev, pager, next, jumper" :total="pagination.total"></el-pagination>
    </div>
</template>

<script>
import TableSearch from '../../components/tableSearch/index.vue'
import { onMounted, ref } from 'vue'
import { ElMessageBox } from 'element-plus';
import { message } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { getRankList, deleteRank, changeRankStatus } from '@/api/JournalList';
import RankModal from './RankModal.vue';
export default {
    components: {
        TableSearch,
        RankModal
    },
    setup() {
        const visible = ref(false)
        const tableData = ref([])
        const router = useRouter();
        const record = ref(null);
        const title = ref('新建榜单')
        const state = ref({
            name: ''
        })
        const pagination = ref({
            current: 1,
            size: 10,
            total: 0
        })
        const getDataList = (param) => {
            const params = {
                current: pagination.value.current,
                size: 10,
                name: state.value.name,
                ...param
            }
            getRankList(params).then((res) => {
                if (res && res.data) {
                    tableData.value = res.data.records
                    pagination.value.total = res.data.total
                }
            })
        }
        const fanhui = () => {
            router.back();
        }
        const inputchange = (value) => {

            state.value.name = value
            console.log(state.value, 'value')
        }
        const search = () => {
            getDataList({
                current: 1
            });
            pagination.value.current = 1
        }
        const add = () => {
            title.value = '新建榜单';
            record.value = null;
            visible.value = true;
            console.log(visible.value, 'value')
        }
        const cancel = () => {
            visible.value = false;

        }
        const confirm = () => {
            visible.value = false;
            getDataList()
        }
        const handleCurrentChange = (current) => {
            getDataList({
                current: current
            })
        }
        const searchList = [{
            label: '关键词',
            key: 'name',
            type: 'input',
            placeholder: '请输入要搜索的关键字词',
            changevalue: inputchange

        },
        {
            label: '查询',
            key: '',
            type: 'button',
            placeholder: '',
            click: search

        }

        ]
        const rightOperation = [{

            label: '新增',
            key: '',
            type: 'button',
            placeholder: '',
            click: add


        }]

        const handleEdit = (row) => {
            record.value = row;
            title.value = '编辑榜单';
            visible.value = true
        }
        const handleDelete = (row) => {
            ElMessageBox.confirm(
                `确定将${row.name
                }从榜单中删除吗？`,
                '删除榜单',
                {
                    type: 'warning',
                    autofocus: false,
                    callback: (action) => {
                        if (action === 'confirm') {
                            deleteRank({
                                id: row.id
                            })
                                .then((res) => {
                                    if (res && res.data2.code === 0) {

                                        message.success('删除成功');

                                        getDataList({ current: 1 });
                                    }
                                });
                        }
                    }
                }
            );
        }
        const changeStatus = (row) => {
            changeRankStatus({
                id: row.id,
                status: row.status ? 0 : 1
            }).then((res) => {
                if (res && res.data2.code === 0) {

                    message.success(row.status ? '下架成功' : '上架成功');
                    getDataList()
                }
            })
        }
        const handleDetail = (index, row) => {
            router.push({
                path: 'rankdetail',
                query: { id: row.id }
            });
            console.log(index, row)
        }



        onMounted(() => {
            getDataList()
        })
        return {
            title,
            visible,
            state,
            searchList,
            tableData,
            rightOperation,
            record,
            pagination,
            changeStatus,
            handleCurrentChange,
            handleDetail,
            cancel,
            confirm,
            fanhui,
            handleEdit,
            handleDelete
        }
    }
}
</script>

<style lang="scss">
.JournalList {
    background: #fff;
    height: 100%;
    padding: 16px;

    .title {
        margin-bottom: 16px;
    }

    .fanhuiimg {
        height: 16px;
        width: 16px;
        margin-top: -4px;
        margin-right: 8px;
        cursor: pointer;
    }

    .span-t1 {
        width: 64px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2f3238;
        line-height: 22px;
    }

    .dataTable {
        border-radius: 6px;
        border: 1px solid #EBEBEB;
        border-bottom: 0px;
    }

    .action {
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #286CFB;
        display: inline-block;
        margin-right: 8px;
    }

    .cancel {
        color: #FF4224;
    }

    .pagination {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;
    }
}
</style>